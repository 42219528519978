const errorData = [
    {
        id: 199,
        publisherId: 1000138,
        publisherName: '555665962629259959529829827948494646494949595828928944549495454554519591515451',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/1000138/1724665097',
        videoDesc: '这是一个过期的链接',
        videoPath: 'https://v.slive.top/slive/sv/1000084/1/737/low/1730874358_854_11?expires=1732254406979&signature=c94897dc4bb35d2a294487f429b66f00e233a78d55d1fc48ec303aa5d6105c23',
        likeCounts: 2,
        createTime: '2024-08-21 15:11:27',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000138/2/200/1724224240thumbnail.0000000.jpg',
        bgPath: 'https://v.slive.top/slive/img/1000138/2/200/1724224240thumbnail.0000000.jpg',
        sourceVideoInfo: '{"h": "1280", "w": "720", "time": 55}',
        isFollow: 0,
        isLike: 1,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 1,
                tag: '测试标签2',
            },
        ],
        viewCounts: 78,
        urlHeight: 'https://v.slive.top/slive/sv/1000084/1/737/low/1730874358_854_11?expires=1732254406979&signature=c94897dc4bb35d2a294487f429b66f00e233a78d55d1fc48ec303aa5d6105c23',
        status: 1,
        completeDuration: 55,
        previewDuration: 0,
        fensCounts: 4,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=200',
    },
]
const homeMock = [
    {
        id: 746,
        publisherId: 1000976,
        publisherName: 'li4',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10118/1720602423131135300.png',
        videoDesc: '应该是一个cover',
        videoPath: 'https://v.slive.top/slive/sv/1000976/1/746/low/1731048822_854_7?expires=1732793173651&signature=9ef3a0ee04b72e68d66653ba9b9a3d06893640ce7719ffaceaa6cbd0c1ef7db5',
        likeCounts: 0,
        createTime: '2024-11-08 14:53:41',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000976/2/1731048821',
        bgPath: 'https://v.slive.top/slive/img/1000976/2/1731048821',
        sourceVideoInfo: '{"h": "720", "w": "1280", "time": 7, "angle": 90}',
        isFollow: 0,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 27,
        urlHeight: 'https://v.slive.top/slive/sv/1000976/1/746/high/1731048829_1280_7?expires=1732793173651&signature=f07d3a5c457f817fc46248a8de466d50581d27b0e095ffe1da1bbad41394c5f5',
        status: 1,
        completeDuration: 7,
        previewDuration: 0,
        fensCounts: 0,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=746',
    },
    ...errorData,
    {
        id: 274,
        publisherId: 10199,
        publisherName: '+1测试',
        publisherHead: 'https://v.slive.top/slive/img/10199/3/1730106543',
        videoDesc: '比较',
        videoPath: 'https://v.slive.top/slive/sv/10199/1/274/low/1724900908_480-854_8.m3u8?expires=1732793056678&signature=32c76015108bf4b60fc83f2504444026d05cb6f9764f4aa3578c654235a1eb76',
        likeCounts: 4,
        createTime: '2024-08-29 11:11:31',
        collectCounts: 1,
        commentCounts: 1,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/10199/2/274/1724900908thumbnail.0000000.jpg',
        bgPath: 'https://v.slive.top/slive/img/10199/2/274/1724900908thumbnail.0000000.jpg',
        sourceVideoInfo: '{"h": "1280", "w": "720", "time": 38}',
        isFollow: 0,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 2,
                tag: '户外',
            },
            {
                id: 5,
                tag: '测试新增',
            },
            {
                id: 1,
                tag: '测试标签1',
            },
            {
                id: 7,
                tag: '游戏',
            },
            {
                id: 6,
                tag: '111',
            },
        ],
        viewCounts: 118,
        urlHeight: 'https://v.slive.top/slive/sv/10199/1/274/high/1724900908_480-854_8.m3u8?expires=1732793056678&signature=d1f226052cf010955e3fa6719884a1cf65f6e88d0653a299816e000dba343179',
        status: 1,
        completeDuration: 38,
        previewDuration: 0,
        fensCounts: 21,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=274',
    },
    {
        id: 113,
        publisherId: 10200,
        publisherName: '龙卷风，又称龙卷、卷风，是一种猛烈的天气',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10200/1723185757',
        videoDesc: '下一个视频 403',
        videoPath: 'https://v.slive.top/slive/sv/10200/1/113/low/1721027918314327000.mp4_640-854_8.m3u8?expires=1732793142591&signature=b6fd73ba373c5a4a58b35ff5aabb268b020d5bc89d41fda19eecf586f5c74317',
        likeCounts: 4,
        createTime: '2024-07-15 15:20:48',
        collectCounts: 4,
        commentCounts: 12,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/10200/2/113/1721027918314327000.mp4thumbnail.0000001.jpg',
        bgPath: 'https://v.slive.top/slive/img/10200/2/113/1721027918314327000.mp4thumbnail.0000001.jpg',
        sourceVideoInfo: '{"h": "960", "w": "720", "time": 56}',
        isFollow: 2,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 47,
        urlHeight: 'https://v.slive.top/slive/sv/10200/1/113/high/1721027918314327000.mp4_640-854_8.m3u8?expires=1732793142591&signature=601b2be4a7e2729ef4031dff708e813d0cc98175c0289caa6fc22cc5e5d40b41',
        status: 1,
        completeDuration: 56,
        previewDuration: 0,
        fensCounts: 17,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=113',
    },
    {
        ...errorData[0],
        id: 188,
    },
    {
        id: 745,
        publisherId: 1000976,
        publisherName: '上一个视频是403',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10118/1720602423131135300.png',
        videoDesc: '',
        videoPath: 'https://v.slive.top/slive/sv/1000976/1/745/low/1731048722_854_7?expires=1732793171541&signature=429bf071bbc25ca62aa8ee5fde091936b443decf82862b27d179a6c5c2759a1d',
        likeCounts: 0,
        createTime: '2024-11-08 14:52:01',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000976/2/1731048721',
        bgPath: 'https://v.slive.top/slive/img/1000976/2/1731048721',
        sourceVideoInfo: '{"h": "720", "w": "1280", "time": 7, "angle": 0}',
        isFollow: 0,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 26,
        urlHeight: 'https://v.slive.top/slive/sv/1000976/1/745/high/1731048729_1280_7?expires=1732793171541&signature=454a0ee31c1f09f2cf9e7cd4ef12006c0ea1ba2240f7bbb0e00074328956963e',
        status: 1,
        completeDuration: 7,
        previewDuration: 0,
        fensCounts: 0,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=745',
    },
    {
        id: 753,
        publisherId: 1000036,
        publisherName: '86中国测试',
        publisherHead: 'http://v.slive.top/slive/img/1000036/3/1730256645',
        videoDesc: '55',
        videoPath: 'https://v.slive.top/slive/sv/1000036/1/753/low/1731054410_1280_8?expires=1732793190589&signature=dbca00b0c94435272d953aa0c58d43410129840fa1ff31ad23df50a4be235b82',
        likeCounts: 1,
        createTime: '2024-11-08 16:23:22',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000036/2/1731054409',
        bgPath: 'https://v.slive.top/slive/img/1000036/2/1731054409',
        sourceVideoInfo: '{"h": "1080", "w": "1920", "time": 7, "angle": 180}',
        isFollow: 0,
        isLike: 1,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 26,
        urlHeight: 'https://v.slive.top/slive/sv/1000036/1/753/high/1731054420_1920_8?expires=1732793190589&signature=f57205cdcc5bc50961bd3c5408005b7d4f037bd3b3cdb5cf06077dea980be16a',
        status: 1,
        completeDuration: 7,
        previewDuration: 10,
        fensCounts: 3,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=753',
    },
    {
        id: 200,
        publisherId: 1000138,
        publisherName: '555665962629259959529829827948494646494949595828928944549495454554519591515451',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/1000138/1724665097',
        videoDesc: '55',
        videoPath: 'https://v.slive.top/slive/sv/1000138/1/200/low/1724224240_480-854_8.m3u8?expires=1732793100587&signature=5b67a0bd9d647179eddd62077ad8b50ca389f0f0b88536324ce62b7ef33f7f4e',
        likeCounts: 2,
        createTime: '2024-08-21 15:11:27',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000138/2/200/1724224240thumbnail.0000000.jpg',
        bgPath: 'https://v.slive.top/slive/img/1000138/2/200/1724224240thumbnail.0000000.jpg',
        sourceVideoInfo: '{"h": "1280", "w": "720", "time": 55}',
        isFollow: 0,
        isLike: 1,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 1,
                tag: '测试标签1',
            },
        ],
        viewCounts: 83,
        urlHeight: 'https://v.slive.top/slive/sv/1000138/1/200/high/1724224240_480-854_8.m3u8?expires=1732793100587&signature=dca3416fe47478926740b6e068c6ebc2b7829dcee7e5a2b045aa034cd4f841e0',
        status: 1,
        completeDuration: 55,
        previewDuration: 0,
        fensCounts: 4,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=200',
    },
    {
        id: 751,
        publisherId: 1000036,
        publisherName: '86中国测试',
        publisherHead: 'http://v.slive.top/slive/img/1000036/3/1730256645',
        videoDesc: '180',
        videoPath: 'https://v.slive.top/slive/sv/1000036/1/751/low/1731052927_1280_8?expires=1732793186444&signature=7bc4889e674be6fd58f06d1a50b4eee45bca1a42547c7d550a2b9529ca6288a2',
        likeCounts: 0,
        createTime: '2024-11-08 16:02:05',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'http://v.slive.top/slive/img/1000036/2/1731052927',
        bgPath: 'http://v.slive.top/slive/img/1000036/2/1731052927',
        sourceVideoInfo: '{"h": "1080", "w": "1920", "time": 7, "angle": 180}',
        isFollow: 0,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 25,
        urlHeight: 'https://v.slive.top/slive/sv/1000036/1/751/high/1731052936_1920_8?expires=1732793186444&signature=ecd1500543f23e1e25bbcd5ae1749d16fb2de2ea01ea20103a58f95d50dc5439',
        status: 1,
        completeDuration: 7,
        previewDuration: 10,
        fensCounts: 3,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=751',
    },
    {
        id: 202,
        publisherId: 10200,
        publisherName: '龙卷风，又称龙卷、卷风，是一种猛烈的天气',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10200/1723185757',
        videoDesc: '1112121212',
        videoPath: 'https://v.slive.top/slive/sv/10200/1/202/low/1724224755280119500_h_8.m3u8?expires=1732793097206&signature=ee7ef91033a895d3cc21ffe3695981b80df926f354f6361c54b501126b7e55d7',
        likeCounts: 3,
        createTime: '2024-08-21 15:19:46',
        collectCounts: 0,
        commentCounts: 23,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/10200/2/202/1724224759274922700.png',
        bgPath: 'https://v.slive.top/slive/img/10200/2/202/1724224759274922700.png',
        sourceVideoInfo: '{"h": "360", "w": "640", "time": 92}',
        isFollow: 2,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 1,
                tag: '测试标签1',
            },
        ],
        viewCounts: 134,
        urlHeight: 'https://v.slive.top/slive/sv/10200/1/202/high/1724224755280119500_h_8.m3u8?expires=1732793097206&signature=600192dff58dc0e0ec8d81f8257628bee41a1fcf4fc055cd4bd9f7b25ab8ed19',
        status: 1,
        completeDuration: 92,
        previewDuration: 0,
        fensCounts: 17,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=202',
    },
    {
        id: 108,
        publisherId: 10200,
        publisherName: '龙卷风，又称龙卷、卷风，是一种猛烈的天气',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10200/1723185757',
        videoDesc: '测试视频1233',
        videoPath: 'https://v.slive.top/slive/sv/10200/1/108/low/1721026531371738800.mp4_854-640_8.m3u8?expires=1732793150055&signature=d45f1728a50af7b2fe33f9b0b6ac56f74c53f61dbaa27034d8ce330b1dd33530',
        likeCounts: 9,
        createTime: '2024-07-15 15:01:41',
        collectCounts: 4,
        commentCounts: 374,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/10200/2/108/1721026531371738800.mp4thumbnail.0000001.jpg',
        bgPath: 'https://v.slive.top/slive/img/10200/2/108/1721026531371738800.mp4thumbnail.0000001.jpg',
        sourceVideoInfo: '{"h": "720", "w": "960", "time": 22}',
        isFollow: 2,
        isLike: 1,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 10,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 1,
                tag: '测试标签1',
            },
            {
                id: 2,
                tag: '户外',
            },
        ],
        viewCounts: 360,
        urlHeight: 'https://v.slive.top/slive/sv/10200/1/108/high/1721026531371738800.mp4_854-640_8.m3u8?expires=1732793150055&signature=6ffa100c9984c94ae443c61c9771d1a6090566653fe14e466f13d8756eb1f974',
        status: 1,
        completeDuration: 22,
        previewDuration: 0,
        fensCounts: 17,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=108',
    },
    {
        id: 744,
        publisherId: 1000976,
        publisherName: 'li4',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10118/1720602423131135300.png',
        videoDesc: '',
        videoPath: 'https://v.slive.top/slive/sv/1000976/1/744/low/1731048534_854_7?expires=1732793169462&signature=534c2f8c7a2ac7e448495a5d7affb8887df81414cef28626d85ed12841c81ace',
        likeCounts: 0,
        createTime: '2024-11-08 14:48:54',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000976/2/1731048534',
        bgPath: 'https://v.slive.top/slive/img/1000976/2/1731048534',
        sourceVideoInfo: '{"h": "720", "w": "1280", "time": 7, "angle": 0}',
        isFollow: 0,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 7,
        urlHeight: 'https://v.slive.top/slive/sv/1000976/1/744/high/1731048541_1280_7?expires=1732793169462&signature=d220e9c58ca4252a63113a4240aa8a97e10309561f5c74dc9b21f85b3e6834b3',
        status: 1,
        completeDuration: 7,
        previewDuration: 0,
        fensCounts: 0,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=744',
    },
    {
        id: 326,
        publisherId: 1000128,
        publisherName: '龘龘龘龘龘龘龘龘龘龘龘龘龘龘龘龘龘龘龘龘',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/1000128/3/1726815670',
        videoDesc:
            '射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节奏射手如何把握节射手如何把节奏射把握节奏射射手节奏射手如何把握节奏射手如何把握节奏',
        videoPath:
            'https://d3juk0l4mepbxb.cloudfront.net/?Expires=1736022027&Signature=i7dm28N5LUnXnXB1-KWKzqJxoXBuw0ODBQEsyd0mXsjRVzSCQ6~ztFhuID8N7QIHBYwxFVgmCBhqOk33cy2sm18kG5VbI8Ea64mpMtATlmtSjr18zy8knFjMqYMedpLdUDu7iYM1VmO4uQsO99Mldq1jX~2kEdeCkxiYjgEnyjW-zeiAktg~N8yrf7kg4dA7OCJsn6bBZSalVtWbZXazcSgPsmTLDU8pbdr7BgYv1NW4xOPdPkgLP0PagZSPJSB4z759iNsz0mwmx4zgvdKU7Ti-ILVrsD66wzSoOQsIIBMatjT4ivdixd3QQfQo9fgbiPNJyHELv9cEgks9LxS57A__&Key-Pair-Id=KCQ03WSUHI8EY',
        likeCounts: 5,
        createTime: '2024-09-09 11:55:48',
        collectCounts: 2,
        commentCounts: 12,
        forwardCounts: 0,
        coverPath: 'https://d2oh10jm4vj0f4.cloudfront.net/1000000/2/1725853100',
        bgPath: 'https://d2oh10jm4vj0f4.cloudfront.net/1000000/2/1725853100',
        sourceVideoInfo: '{"h": "576", "w": "1024", "time": 615}',
        isFollow: 0,
        isLike: 0,
        isCollect: 1,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 7,
                tag: '游戏',
            },
            {
                id: 5,
                tag: '测试新增',
            },
            {
                id: 6,
                tag: '111',
            },
            {
                id: 8,
                tag: '美食',
            },
            {
                id: 9,
                tag: '影视频道',
            },
            {
                id: 10,
                tag: '学习类型',
            },
            {
                id: 11,
                tag: '校园生活',
            },
            {
                id: 12,
                tag: '12345678901234567890123456789',
            },
        ],
        viewCounts: 174,
        urlHeight:
            'https://d3juk0l4mepbxb.cloudfront.net/1000128/1/1725853061_h.m3u8?Expires=1736022026&Signature=RYh8EopmxPB2jkM8VG2gI7gmqvvrHnKIzIETDFOcUpzsMsnvz6w7~PEiqc50d3U02FhF0SolIRdQh0fn7eLMJnPiiVAR~HMIqbFMh96XGHCFcnbo7C7UIiTWfmhaPr61q3mZZDyV3xi~qkmb1fcM98jbSytXEo~2n6qx3ekgYJEQbvi5YaPpDyLr0Mhkwo4GxfymQXQqzcBr-ymKNzvVlZjCsgd8VS6hWb4ki0XL~lcsHfyjFil-kaEjhg8AQFF7CmZeVT2Jx0vSrdnuQg~~sA8Ajw9Oa-AsVUkLQuUoQe9ZMxfuUTDKX0AYyTkG0-7kch~Pg7wJTTnv~MSvFP34GQ__&Key-Pair-Id=KCQ03WSUHI8EY',
        status: 1,
        completeDuration: 615,
        previewDuration: 0,
        fensCounts: 10,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=326',
    },
    {
        id: 750,
        publisherId: 1000036,
        publisherName: '86中国测试',
        publisherHead: 'http://v.slive.top/slive/img/1000036/3/1730256645',
        videoDesc: '270',
        videoPath: 'https://v.slive.top/slive/sv/1000036/1/750/low/1731052903_1280_8?expires=1732793184306&signature=b57a7603918faa75a0324db3b6e985f2345ee3636a5207150325926cb628d335',
        likeCounts: 1,
        createTime: '2024-11-08 16:01:42',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'http://v.slive.top/slive/img/1000036/2/1731052902',
        bgPath: 'http://v.slive.top/slive/img/1000036/2/1731052902',
        sourceVideoInfo: '{"h": "1080", "w": "1920", "time": 6, "angle": 270}',
        isFollow: 0,
        isLike: 1,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 27,
        urlHeight: 'https://v.slive.top/slive/sv/1000036/1/750/high/1731052914_1920_8?expires=1732793184306&signature=e018b5c5ea4b797d18f1d2f97a04aa1d928fe66b69d30df32a221e1f119ef644',
        status: 1,
        completeDuration: 6,
        previewDuration: 10,
        fensCounts: 3,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=750',
    },
    {
        id: 749,
        publisherId: 1000036,
        publisherName: '86中国测试',
        publisherHead: 'http://v.slive.top/slive/img/1000036/3/1730256645',
        videoDesc: '90',
        videoPath: 'https://v.slive.top/slive/sv/1000036/1/749/low/1731052729_854_8?expires=1732793182165&signature=7bfb37532357c7a98da528412f00c2701c6b7b3c89e269607898f212f513257d',
        likeCounts: 1,
        createTime: '2024-11-08 15:58:47',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'http://v.slive.top/slive/img/1000036/2/1731052728',
        bgPath: 'http://v.slive.top/slive/img/1000036/2/1731052728',
        sourceVideoInfo: '{"h": "720", "w": "1280", "time": 16, "angle": 90}',
        isFollow: 0,
        isLike: 1,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 23,
        urlHeight: 'https://v.slive.top/slive/sv/1000036/1/749/high/1731052754_1280_8?expires=1732793182165&signature=685b4b3dedd962b591114e0ed6dd69ed695a4c3a9c5e96f97c1358600c5d6e6b',
        status: 1,
        completeDuration: 16,
        previewDuration: 10,
        fensCounts: 3,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=749',
    },
    {
        id: 214,
        publisherId: 10200,
        publisherName: '龙卷风，又称龙卷、卷风，是一种猛烈的天气',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10200/1723185757',
        videoDesc: '12121212',
        videoPath: 'https://v.slive.top/slive/sv/10200/1/214/low/1724407766995441500_854-480_8.m3u8?expires=1732793154156&signature=af8ee863eba64844a911eb04dc6ef6a262b7762b802f5f3ea8cf26879afe10d2',
        likeCounts: 2,
        createTime: '2024-08-23 18:11:03',
        collectCounts: 0,
        commentCounts: 21,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/10200/2/214/1724407766995441500thumbnail.0000000.jpg',
        bgPath: 'https://v.slive.top/slive/img/10200/2/214/1724407766995441500thumbnail.0000000.jpg',
        sourceVideoInfo: '{"h": "720", "w": "1280", "time": 1200}',
        isFollow: 2,
        isLike: 1,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 2,
                tag: '户外',
            },
        ],
        viewCounts: 83,
        urlHeight: 'https://v.slive.top/slive/sv/10200/1/214/high/1724407766995441500_854-480_8.m3u8?expires=1732793154156&signature=def9f76919c9e4b4622e4f8b6d509b035130c3e03dcfb74b826b7bf31438449f',
        status: 1,
        completeDuration: 1200,
        previewDuration: 0,
        fensCounts: 17,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=214',
    },
    {
        id: 748,
        publisherId: 1000976,
        publisherName: 'li4',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10118/1720602423131135300.png',
        videoDesc: '',
        videoPath: 'https://v.slive.top/slive/sv/1000976/1/748/low/1731050978_854_7?expires=1732793175742&signature=59cc47281a4d5260df3f4d979c9527da768069407808a2f20a52060488d9525a',
        likeCounts: 0,
        createTime: '2024-11-08 15:29:37',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000976/2/1731050977',
        bgPath: 'https://v.slive.top/slive/img/1000976/2/1731050977',
        sourceVideoInfo: '{"h": "720", "w": "1280", "time": 7, "angle": 90}',
        isFollow: 0,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 27,
        urlHeight: 'https://v.slive.top/slive/sv/1000976/1/748/high/1731050985_1280_7?expires=1732793175742&signature=4a957ee43792856465339d153be986c1db82d187f7b27bb8d176ffc9d7aa9786',
        status: 1,
        completeDuration: 7,
        previewDuration: 0,
        fensCounts: 0,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=748',
    },
    {
        id: 109,
        publisherId: 10200,
        publisherName: '龙卷风，又称龙卷、卷风，是一种猛烈的天气',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10200/1723185757',
        videoDesc: '测试视频',
        videoPath: 'https://v.slive.top/slive/sv/10200/1/109/low/1721027134874999500.mp4_480-854_8.m3u8?expires=1732793148676&signature=1017ce1dd2e226040fc5fe283f31ac1bc7dae9d9899183822328f727eea3f308',
        likeCounts: 6,
        createTime: '2024-07-15 15:08:25',
        collectCounts: 6,
        commentCounts: 22,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/10200/2/109/1724200676247416800.png',
        bgPath: 'https://v.slive.top/slive/img/10200/2/109/1724200676247416800.png',
        sourceVideoInfo: '{"h": "1280", "w": "720", "time": 16}',
        isFollow: 2,
        isLike: 0,
        isCollect: 1,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 1,
                tag: '测试标签1',
            },
            {
                id: 2,
                tag: '户外',
            },
        ],
        viewCounts: 216,
        urlHeight: 'https://v.slive.top/slive/sv/10200/1/109/high/1721027134874999500.mp4_480-854_8.m3u8?expires=1732793148676&signature=bef15c62e54fdbeeba969cbcc7b91fdbe87560ad252cbe24ed91428e49365f97',
        status: 1,
        completeDuration: 16,
        previewDuration: 0,
        fensCounts: 17,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=109',
    },
    {
        id: 110,
        publisherId: 10200,
        publisherName: '龙卷风，又称龙卷、卷风，是一种猛烈的天气',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10200/1723185757',
        videoDesc: '测试视频3',
        videoPath: 'https://v.slive.top/slive/sv/10200/1/110/low/1721027336980488400.mp4_480-854_8.m3u8?expires=1732793151413&signature=2ef73508326e76e3a3b492e80ceea9f23fba7571b6c85c2b9bf0e763d731b640',
        likeCounts: 6,
        createTime: '2024-07-15 15:12:53',
        collectCounts: 4,
        commentCounts: 13,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/10200/2/110/1721027336980488400.mp4thumbnail.0000001.jpg',
        bgPath: 'https://v.slive.top/slive/img/10200/2/110/1721027336980488400.mp4thumbnail.0000001.jpg',
        sourceVideoInfo: '{"h": "1280", "w": "720", "time": 179}',
        isFollow: 2,
        isLike: 1,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 72,
        urlHeight: 'https://v.slive.top/slive/sv/10200/1/110/high/1721027336980488400.mp4_480-854_8.m3u8?expires=1732793151413&signature=159ac2c5b4101fa301b6d7db177d6ef766352e8a69d65b689870c77ca43e24cf',
        status: 1,
        completeDuration: 179,
        previewDuration: 0,
        fensCounts: 17,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=110',
    },
    {
        id: 752,
        publisherId: 1000036,
        publisherName: '86中国测试',
        publisherHead: 'http://v.slive.top/slive/img/1000036/3/1730256645',
        videoDesc: '0度',
        videoPath: 'https://v.slive.top/slive/sv/1000036/1/752/low/1731053370_854_8?expires=1732793188528&signature=15d143a6f5de9e2032a373ec1ae9c88ed53052af0327239ff4bd3280b5c8eb35',
        likeCounts: 0,
        createTime: '2024-11-08 16:09:29',
        collectCounts: 0,
        commentCounts: 0,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000036/2/1731053369',
        bgPath: 'https://v.slive.top/slive/img/1000036/2/1731053369',
        sourceVideoInfo: '{"h": "1280", "w": "720", "time": 5, "angle": 0}',
        isFollow: 0,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [],
        viewCounts: 30,
        urlHeight: 'https://v.slive.top/slive/sv/1000036/1/752/high/1731053375_1280_8?expires=1732793188528&signature=9b8fda199fd2f2341586c55aba3235cbb5f4588ea4a6fbb37b8273656df663d5',
        status: 1,
        completeDuration: 5,
        previewDuration: 10,
        fensCounts: 3,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=752',
    },
    {
        id: 418,
        publisherId: 1000084,
        publisherName: '我是一只小小小的小小可爱的小小小的毛毛虫',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/1000084/3/1727341013',
        videoDesc: '无声音，测试',
        videoPath: 'https://v.slive.top/slive/sv/1000084/1/418/low/1727321840_480-854_8.m3u8?expires=1732792986787&signature=8541d190d31716ee7f084ededc921bf0655693e560d6bfa8eed9510457971482',
        likeCounts: 2,
        createTime: '2024-09-26 11:50:50',
        collectCounts: 1,
        commentCounts: 20,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/1000084/2/418/1727322859',
        bgPath: 'https://v.slive.top/slive/img/1000084/2/418/1727322859',
        sourceVideoInfo: '{"h": "1280", "w": "720", "time": 20}',
        isFollow: 0,
        isLike: 0,
        isCollect: 0,
        openType: 0,
        isPlay: true,
        giveScore: 0,
        costScore: 0,
        costScoreId: 0,
        videoTag: [
            {
                id: 1,
                tag: '测试标签1',
            },
        ],
        viewCounts: 77,
        urlHeight: 'https://v.slive.top/slive/sv/1000084/1/418/high/1727321840_480-854_8.m3u8?expires=1732792986787&signature=6433ce373817a762e1fa5351f5e140c03c367388f4bc12ff3cddc1f995e48011',
        status: 1,
        completeDuration: 20,
        previewDuration: 0,
        fensCounts: 5,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=418',
    },
    {
        id: 138,
        publisherId: 10200,
        publisherName: '龙卷风，又称龙卷、卷风，是一种猛烈的天气',
        publisherHead: 'https://d2oh10jm4vj0f4.cloudfront.net/10200/1723185757',
        videoDesc: '收费100',
        videoPath: 'https://v.slive.top/slive/sv/10200/1/138/preview/1721896656_30s_8.m3u8?expires=1732793131009&signature=a567649f7dc1346d4e46d9857d5efa04cfca97e10f5dfe7bfc49ebbc85d15d4c',
        likeCounts: 5,
        createTime: '2024-07-25 16:38:31',
        collectCounts: 2,
        commentCounts: 21,
        forwardCounts: 0,
        coverPath: 'https://v.slive.top/slive/img/10200/2/138/1723101013',
        bgPath: 'https://v.slive.top/slive/img/10200/2/138/1723101013',
        sourceVideoInfo: '{"h": "720", "w": "722", "time": 42}',
        isFollow: 2,
        isLike: 0,
        isCollect: 0,
        openType: 1,
        isPlay: false,
        giveScore: 0,
        costScore: 10000,
        costScoreId: 3,
        videoTag: [
            {
                id: 1,
                tag: '测试标签1',
            },
        ],
        viewCounts: 129,
        urlHeight: 'https://v.slive.top/slive/sv/10200/1/138/preview/1721896656_30s_8.m3u8?expires=1732793131009&signature=a567649f7dc1346d4e46d9857d5efa04cfca97e10f5dfe7bfc49ebbc85d15d4c',
        status: 1,
        completeDuration: 42,
        previewDuration: 10,
        fensCounts: 17,
        shareUrl: 'http://192.168.1.122:8848/work?shareVideoId=138',
    },
]

export const simulationHomeVList = () => {
    return [...homeMock]
}
