import { RouteRecordRaw } from 'vue-router'
const Home = () => import('@/pages/home/index.vue')

/**
 * 这里保存的是一级路由，即tabbar页面，后续有改动，或者新增tabbar页面，直接在这里添加即可。
 * 如果当前tabbar页面要改成一个非tabBar页面，移到routes里去即可。
 */
const TabBarPages: RouteRecordRaw[] = [
    // 一级
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/discover',
        name: 'discover',
        component: () => import('@/pages/discover/index.vue'),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/message',
        name: 'message',
        component: () => import('@/pages/message/index.vue'),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/me',
        name: 'me',
        component: () => import('@/pages/me/index.vue'),
        meta: {
            keepAlive: true,
        },
    },
]
/**
 * 这里放到的是创作页面。比如说，创作一个视频，编辑一个视频
 * 原版设计稿，tabbar中会有一个创作的入口，那么，创作页面的入口就要放到tabbar里去。新版的设计稿，h5端不要创作功能，只保留编辑功能。
 */
const CreationPages: RouteRecordRaw[] = [
    /**
     * 这里虽然定义的是index.vue, 实际上这里是一个编辑功能，即使有发布功能，发布按钮也只是拍摄一段内容，拍摄完后，跳到这里。
     * 这里有一个id，目前设计的是需要传一个视频的id过来。但是，如果是新创作的内容，是可以不带id的。
     */
    {
        path: '/creation-wrok/:videoId?',
        component: () => import('@/pages/creation/index.vue'),
        props: true,
    },
    {
        path: '/creation-preview/:videoId?',
        component: () => import('@/pages/creation/CreationPreview.vue'),
        props: true,
    },
]
const WalletPages: RouteRecordRaw[] = [
    {
        path: '/wallet',
        component: () => import('@/pages/wallet/index.vue'),
        children: [],
    },
    {
        path: '/wallet/recharge-record',
        component: () => import('@/pages/wallet/RechargeCoins.vue'),
    },
    {
        path: '/wallet/giveaway',
        component: () => import('@/pages/wallet/Giveaway.vue'),
    },
    {
        path: '/wallet/transaction-history',
        component: () => import('@/pages/wallet/TransactionHistory.vue'),
    },
    {
        path: '/wallet/help',
        component: () => import('@/pages/wallet/HelpAndFeedback.vue'),
    },
]
const MePages: RouteRecordRaw[] = [
    {
        path: '/vip',
        component: () => import('@/pages/me/Vip.vue'),
    },
]
const OtherPages: RouteRecordRaw[] = [
    {
        path: '/gold-purchase-video/:videoId/:publisherId',
        component: () => import('@/pages/other/GoldPurchaseVideo.vue'),
        props: true,
    },
]
const routes: RouteRecordRaw[] = [
    { path: '', redirect: '/home' },
    { path: '/', redirect: '/home' },
    // 一级
    ...TabBarPages,
    ...CreationPages,
    ...WalletPages,
    ...MePages,
    ...OtherPages,
    // 二级路由

    {
        path: '/new-followers',
        component: () => import('@/pages/message/new-followers.vue'),
    },
    {
        path: '/all-activity',
        component: () => import('@/pages/message/all-activity.vue'),
    },
    {
        path: '/chat-setting',
        component: () => import('@/pages/message/chat-setting.vue'),
    },
    {
        path: '/new-chat',
        component: () => import('@/pages/message/new-chat.vue'),
    },
    {
        path: '/search-chat',
        component: () => import('@/pages/message/search-chat.vue'),
    },
    {
        path: '/circle',
        component: () => import('@/pages/me/circle.vue'),
    },
    {
        path: '/popular',
        component: () => import('@/pages/discover/popular.vue'),
    },
    {
        path: '/hashtags',
        component: () => import('@/pages/discover/hashtags.vue'),
    },
    {
        path: '/shoot-games',
        component: () => import('@/pages/discover/shoot-games.vue'),
    },
    {
        path: '/edit-profile',
        component: () => import('@/pages/me/edit-profile.vue'),
    },
    {
        path: '/user-settings',
        component: () => import('@/pages/me/settings.vue'),
    },
    // 三级路由
    {
        path: '/user/:id',
        component: () => import('@/pages/me/user.vue'),
    },

    // 四级路由

    {
        path: '/chat',
        component: () => import('@/pages/message/chat.vue'),
    },

    {
        path: '/work',
        component: () => import('@/pages/me/work.vue'),
        meta: {
            keepAlive: false,
        },
    },
    {
        name: 'search',
        path: '/search',
        component: () => import('@/pages/search/index.vue'),
        props: (route) => ({
            key: route.query.key, // 使用时间戳作为 key，确保每次都是不同的 key
        }),
        meta: {
            keepAlive: false,
        },
    },
    {
        path: '/hashtag-list',
        component: () => import('@/pages/discover/hashtag-list.vue'),
    },
    {
        name: 'tag-video',
        path: '/tag-video',
        component: () => import('@/pages/discover/tag-video.vue'),
        props: (route) => ({
            key: route.query.key, // 使用时间戳作为 key，确保每次都是不同的 key
        }),
        meta: {
            keepAlive: false,
        },
    },
    {
        name: 'search-video',
        path: '/search-video',
        component: () => import('@/pages/search/search-video.vue'),
        props: (route) => ({
            key: route.query.key, // 使用时间戳作为 key，确保每次都是不同的 key
        }),
        meta: {
            keepAlive: false,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login/index.vue'),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/auth-code',
        name: 'authCode',
        component: () => import('@/pages/login/auth-code.vue'),
        meta: {
            keepAlive: false,
        },
    },
    {
        path: '/agreement',
        component: () => import('@/pages/login/agreement.vue'),
    },
    {
        path: '/privacy',
        component: () => import('@/pages/login/privacy.vue'),
    },
]
export default routes
