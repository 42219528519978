import { defineStore } from 'pinia'

import useToggle from '@/composables/toggle.ts'

const useToggleStore = defineStore('toggle', () => {
    // 控制评论框显示
    const [commitShow, setCommitShow] = useToggle(false)
    // 控制评论框全屏显示
    const [isFullScreen, setFullScreen] = useToggle(false)
    const [isMuted, setMuted] = useToggle(false)

    return { commitShow, setCommitShow, isFullScreen, setFullScreen, isMuted, setMuted }
})

export default useToggleStore
