import mitt from 'mitt'

type EventBusCallback = (...args: any[]) => void

interface EventBus {
    on(event: string, callback: EventBusCallback): void
    off(event: string, callback?: EventBusCallback): void
    emit(event: string, ...args: any[]): void
    clear(): void
}
// 创建一个具有清除功能的事件总线
const createEventBus = (): EventBus => {
    const bus = mitt()
    const eventMap = new Map<string, EventBusCallback[]>() // 用于跟踪注册的事件

    const on = (event: string, callback: EventBusCallback) => {
        bus.on(event, callback)
        if (!eventMap.has(event)) {
            eventMap.set(event, [])
        }
        eventMap.get(event)?.push(callback)
    }

    const off = (event: string, callback?: EventBusCallback) => {
        bus.off(event, callback)
        if (callback && eventMap.has(event)) {
            const callbacks = eventMap.get(event)
            if (callbacks) {
                const index = callbacks.indexOf(callback)
                if (index !== -1) {
                    callbacks.splice(index, 1)
                }
            }
        }
    }

    const clear = () => {
        // 使用 all 属性获取所有事件并移除
        for (const [event, callbacks] of eventMap.entries()) {
            callbacks.forEach((callback) => bus.off(event, callback))
        }
        eventMap.clear() // 清空事件列表
    }

    return {
        on,
        off,
        emit: bus.emit.bind(bus),
        clear,
    }
}

const bus = createEventBus()

// 添加清除所有事件的方法
export default bus
export const EventBusTypes = {
    // 视频播放相关事件
    VIDEO: {
        MOUNTED: 'VIDEO_MOUNTED', // 视频组件挂载, 用于初始化视频, 建议在 顶层路由页面上调用 ，例如： /home, 在 VideoPlayer 监听
        PAUSE: 'VIDEO_PAUSE', // 暂停视频， 在 VideoPlayer 监听。
        PLAY: 'PLAY', // 暂停播放 在 VideoPlayer 监听。
        SAVA_CURRENT_TIME: 'CHANGE_CURRENT_TIME', // 记录视频当前播放时间。在 VideoPlayer 监听。
        CHANGE_SOURCE: 'CHANGE_VIDEO_SOURCE', // 切换视频源 在 VideoPlayer 监听。建议在顶层路由或者videoList上发送
        QUALITY_CHANGE: 'QUALITY_CHANGE', // 切换画质，本质上是切换视频源，但是，这个视频源是同一个视频源，不需要videoInfo,很多CHANGE_VIDEO_SOURCE上需要的东西，在这上面不需要
        CHANGE_MUTED: 'CHANGE_VIDEO_MUTED', // 切换静音状态。在 VideoPlayer 监听，当用户点击我们自己定义的那个全局静音按钮时，发送事件，当用户点击视频内的静音时，更改全局状态。可以通过pinia获取及控制，也可以通过bus
        TOGGLE_PLAY_PAUSE: 'TOGGLE_PLAY_PAUSE', // 切换播放/暂停状态。在 VideoPlayer 监听。建议在顶层路由或者videoList上发送
        DISPOSE: 'VIDEO_DISPOSE', // 释放资源/销毁。在 VideoPlayer 监听。建议在顶层路由或者videoList上发送,
        LOADED_DATA: 'loadeddata', // 视频加载完成。在 VideoPlayer 中触发，在videoBase中监听, 需要将对应的id传过去。
        CHANGE_PLAYING_STATUES: 'CHANGE_PLAYING_STATUES', // 视频播放状态被改变时触发，例如：视频暂停了，为false,开始播放了，为true,
        REQUEST_FULLSCREEN: 'requestFullscreen', // 进入全屏模式 requestFullscreen
        EXIT_FULLSCREEN: 'exitFullscreen', // 退出全屏模式 exitFullscreen
        FULLSCREEN_CHANGE: 'fullscreenchange', // 全屏状态改变时。
        REMOVE_VIDEO: 'REMOVE_VIDEO', // 移除视频
        CHANGE_TAB: 'CHANGE_TAB', // 视频所在的tab切换。
    },
    MESSAGE: {
        RESEND_TEXT: 'resendText', // 重发文本消息
        RESEND_IMAGE: 'resendImage', // 重发图片消息
    },
}
