import { ExtendedPlayer, ILocalData } from '@/composables/types/videoTypes.ts'
import { computed, onBeforeUnmount, onMounted, ref, Ref, UnwrapRef } from 'vue'
import bus, { EventBusTypes } from '@/utils/bus.ts'
import videojs from 'video.js'
import { useVideoQualityLoading } from '@/composables/videoHooks/useVideoQualityLoading.ts'
import { QUALITY_DURATION } from '@/const.ts'
import { getMimeTypeFromVideoUrl } from '@/utils'

export const useVideoQuality = (player: Ref<UnwrapRef<ExtendedPlayer> | null>, localData: ILocalData, videoEl: any) => {
    const { setQualityLoading, closeQualityLoading } = useVideoQualityLoading()
    const videoMimeType = computed(() => {
        if (import.meta.env.MODE !== 'development' || !localData.src) {
            return 'application/x-mpegURL'
        }
        return getMimeTypeFromVideoUrl(localData.src) || 'application/x-mpegURL'
    })

    // 切换画质，切换画质，实际上就是切换视频源。不需要做全屏的动作
    const handleQualityChange = (qualityField: 'videoPath' | 'urlHeight') => {
        if (!player.value || !videoEl.value) {
            return
        }
        closeQualityLoading()
        // 如果画质选择的是同一个视频源，这里直接进入。全屏。
        if (localData.qualityField === qualityField) {
            // handleRequestFullscreen()
            return
        }

        if (localData.videoInfo && localData.videoInfo.videoPath && localData.videoInfo.urlHeight) {
            localData.qualityField = qualityField
            if (qualityField === 'videoPath') {
                localData.src = localData.videoInfo.videoPath
            } else {
                localData.src = localData.videoInfo.urlHeight
            }
            localData.isLoading = true
            videojs(videoEl.value)?.src({ src: localData.src, type: videoMimeType.value })
            player.value.ready(() => {
                setQualityLoading()
            })
            player.value.off('loadeddata', qualityLoadeddataListener)
            // 一旦做了画质切换后，后面如果滑动视频，这里同样会触发, 如果是在同一个页面，倒是可以解除
            player.value.one('loadeddata', qualityLoadeddataListener)
        }
    }

    const qualityTimer = ref<ReturnType<typeof setTimeout> | null>(null)
    const clearQualityTimer = () => {
        if (qualityTimer.value) {
            clearTimeout(qualityTimer.value)
            qualityTimer.value = null
        }
    }
    const qualityLoadeddataListener = () => {
        clearQualityTimer()
        qualityTimer.value = setTimeout(() => {
            // handleRequestFullscreen()
            closeQualityLoading()
        }, QUALITY_DURATION)
    }
    const videoBusEventHandlers = {
        [EventBusTypes.VIDEO.MOUNTED]: closeQualityLoading,
        [EventBusTypes.VIDEO.CHANGE_SOURCE]: closeQualityLoading,
        [EventBusTypes.VIDEO.QUALITY_CHANGE]: handleQualityChange, // 画质切换，实际上只是切换了视频源，并不会立即进入全屏，需要用户手动调用
    }
    onMounted(() => {
        Object.entries(videoBusEventHandlers).forEach(([eventType, handler]) => {
            bus.on(eventType, handler)
        })
    })
    onBeforeUnmount(() => {
        Object.entries(videoBusEventHandlers).forEach(([eventType, handler]) => {
            bus.off(eventType, handler)
        })
    })
    return {}
}
