import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { showLoadingToast, ToastWrapperInstance } from 'vant'

export const useVideoQualityLoading = () => {
    const { t } = useI18n()

    // 延时设置 是否禁止背景点击， true 表示禁止点击，false 表示可以点击，刚开始，不让点击，5秒后可点击关闭
    const forbidTimer = ref<ReturnType<typeof setTimeout> | null>(null)
    const clearForbidTimer = () => {
        if (forbidTimer.value) {
            clearTimeout(forbidTimer.value)
            forbidTimer.value = null
        }
    }
    // 画质加载loading状态
    const qualityLoading = ref<ToastWrapperInstance | null>(null)
    const closeQualityLoading = () => {
        clearForbidTimer()
        qualityLoading.value?.close()
    }
    const setQualityLoading = () => {
        closeQualityLoading()
        qualityLoading.value = showLoadingToast({
            message: t('common.loading_text'),
            forbidClick: false,
            closeOnClick: true,
            duration: 0,
            className: 'global-op-loading',
        })

        // forbidTimer.value = setTimeout(() => {
        //     closeQualityLoading()
        //     qualityLoading.value = showLoadingToast({
        //         message: t('common.loading_text'),
        //         forbidClick: false,
        //         closeOnClick: true,
        //         duration: 0,
        //         className: 'global-op-loading',
        //     })
        // }, 5000)
    }
    return {
        setQualityLoading,
        closeQualityLoading,
    }
}
