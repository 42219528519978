import { nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs, computed } from 'vue'
import { fetchVideoHeadData, getVideoWHL } from '@/utils/video.ts'
import { useSystemStore, useToggleStore, useVideoMountedStore } from '@/store'
import bus, { EventBusTypes } from '@/utils/bus.ts'
import videojs from 'video.js'
import { formatterVideoPath, getMimeTypeFromVideoUrl } from '@/utils'
import { ExtendedPlayer, IVideoMounted, ILocalData, IndexResponseItemData } from '@/composables/types/videoTypes.ts'
import { getVideoProperty, getVideoUseData, videoFetchHeadStatusMessages } from '@/composables/videoHooks/const.ts'
export const useVideoPlayer = () => {
    const { SAVE_VIDEO_CONTAINER_DOM_ID } = useVideoMountedStore()
    videojs.log.error = () => {}

    const toggleStore = useToggleStore()
    const { IS_IOS, IS_MIUI, IS_ANDROID, isMobile, appHeight, appWidth } = toRefs(useSystemStore())
    /**
     * realMutedIsChange 真实的静音状态是否被改变，
     * 在 多个视频上下滑动时，非静音状态下切换视频的时候，视频层级太高。
     * 解决方案，
     * 1. 通过一个变量记录用户的真实静音状态。
     * 2. 每次切换视频的时候，先静音。
     * 3. 等到视频开始播放的时候，再根据真实的静音状态，来决定是否播放声音。注意，这里需要有一定的延时。
     * 5. setVideoMuted 设置静音状态时，如果真实状态被改变，则不通知toggleStore。
     */
    const realMutedIsChange = ref(false)
    const player = ref<ExtendedPlayer | null>(null)
    const videoEl = ref<HTMLVideoElement>()
    const videoMimeType = computed(() => {
        if (import.meta.env.MODE !== 'development' || !localData.src) {
            return 'application/x-mpegURL'
        }
        return getMimeTypeFromVideoUrl(localData.src) || 'application/x-mpegURL'
    })
    const localData = reactive<ILocalData>(<ILocalData>{
        ...getVideoUseData(),
        videoDomHeight: appHeight.value,
    })
    const updateLocalData = (updates: Partial<ILocalData>) => {
        Object.assign(localData, updates)
    }
    // start 视频上的属性。
    const videoProperty = reactive(getVideoProperty())

    /**
     * 苹果上需要加 autoPlay, 小米手机上需要加，oppo 手机不需要加
     */
    const setVideoProperty = () => {
        if (IS_IOS.value || IS_MIUI.value) {
            Object.assign(videoProperty, {
                autoplay: true,
            })
        }
    }
    setVideoProperty()
    // 设置视频宽高,横屏状态。
    const setVideoWHL = (data: { videoWidth: number; videoHeight: number }) => {
        const whlInfo = getVideoWHL(data)
        localData.videoWidth = whlInfo.videoWidth
        localData.videoHeight = whlInfo.videoHeight
        localData.isLandscape = whlInfo.isLandscape
        localData.objectFitCls = (localData.videoDomHeight * whlInfo.videoWidth) / whlInfo.videoHeight > (appWidth.value * whlInfo.videoHeight) / whlInfo.videoWidth ? 'contain' : 'cover'
        // if (localData.isLandscape) {
        //     localData.objectFitCls = localData.videoDomHeight > (appWidth.value * whlInfo.videoHeight) / whlInfo.videoWidth ? 'contain' : 'cover'
        // } else {
        //     localData.objectFitCls = localData.videoDomHeight > (appWidth.value * whlInfo.videoWidth) / whlInfo.videoHeight ? 'contain' : 'cover'
        // }
    }
    /**
     * 根据videoInfo中的已知数据来设置视频宽高,横屏状态。
     * 视频挂载和视频源更新时，都要调用此函数。以便更新数据。
     * @param data
     */
    const videoWidthHeightHandler = (data: IndexResponseItemData | null | undefined) => {
        if (data) {
            localData.videoInfo = data
            const whlInfo = getVideoWHL(data)
            setVideoWHL(whlInfo)
        } else {
            localData.videoInfo = null
            localData.videoWidth = 0
            localData.videoHeight = 0
            localData.isLandscape = false
            localData.objectFitCls = 'fill'
        }
    }
    // 保存视频的播放位置 ，一般是在购买视频的时候有这这个需要。例如：
    const videoProgressInfoMap = new Map()
    const handleChangeSourceSaveCurrentTime = () => {
        if (player.value) {
            localData.currentTime = player.value.currentTime() || 0
            videoProgressInfoMap.set(localData.videoTargetId, localData.currentTime)
        }
    }

    const fetchVideoHeadDataHandler = () => {
        fetchVideoHeadData(localData.src)
            .then((res) => {
                const { status: resStatus } = res
                const errorMsg = videoFetchHeadStatusMessages[resStatus] || `未知错误：${resStatus}`
                updateLocalData({
                    isError: resStatus !== 200,
                    errorCode: resStatus === 200 ? 0 : resStatus,
                    errorMessage: resStatus === 200 ? '' : errorMsg,
                })
            })
            .catch((err) => {
                updateLocalData({
                    isError: true,
                    errorMessage: `请求失败：${err.message}`,
                })
            })
    }
    /**
     * 初始化播放器，或者改变播放源时调用。
     * 注意，这里一定是要放在nextTick中调用 。
     * @todo 已经忘记了这里为什么要延时了。是因为videoEl 没有吗？初始化或者改变源时, Teleport 的dom还没有挂载吗？
     * @todo 如果加上延时，突然发现，第一次加载的时候，不能播放，这是为什么？
     */
    const playerInitOrChangeSrc = async () => {
        await nextTick()
        updateLocalData({
            isPlaying: false,
            isBuffering: false,
            isError: false,
            errorCode: 0,
            errorMessage: '',
            isLoading: true,
        })
        // setTimeout(() => {
        if (!videoEl.value) {
            return
        }
        fetchVideoHeadDataHandler()
        if (player.value) {
            player.value.poster('')
            videojs(videoEl.value)?.src({ src: localData.src, type: videoMimeType.value })
            // player.value.load() // 确保加载新源
            handlePlay()
            return
        }
        playerListerEvent()
        // })
    }
    const playerListerEvent = () => {
        if (player.value) {
            return
        }
        if (!videoEl.value) {
            return
        }

        player.value = videojs(videoEl.value, {
            ...videoProperty,
            sources: [{ src: localData.src, type: videoMimeType.value }],
        }) as ExtendedPlayer
        setControl()
        player.value.on('ready', () => {
            console.log('视频准备好了')
        })
        player.value.on('loadedmetadata', () => {
            nextTick().then(() => {
                console.group('Loaded meta data')
                try {
                    const videoWrapElement = document.getElementById(localData.videoDomId)
                    const videoWrapWidth = videoWrapElement?.clientWidth
                    const videoWrapHeight = videoWrapElement?.clientHeight
                    let videoWidth = videoEl.value?.clientWidth
                    let videoHeight = videoEl.value?.clientHeight
                    if (!videoWidth || !videoHeight) {
                        const videoDomId = videoEl.value?.id ? `#${videoEl.value.id}` : ''
                        const videoDom = document.querySelector(videoDomId) as HTMLElement
                        videoWidth = videoDom.clientWidth
                        videoHeight = videoDom.clientHeight
                    }

                    // @todo 这里应该做个日志上报。
                    if (!videoWrapWidth || !videoWrapHeight) {
                        console.error('视频的父元素没有宽或者高,宽高信息:', `宽:${videoWrapWidth} 高:${videoWrapHeight}`)
                        return
                    }
                    console.log('视频父元素宽高', videoWrapWidth, videoWrapHeight)
                    // 视频没有宽高信息，或者其宽高和父元素对不上的时候，强制设置一下。
                    if (!videoWidth || !videoHeight || videoHeight <= videoWrapHeight - 10 || videoWidth <= videoWrapWidth - 10) {
                        console.error('视频父元素的宽高', `宽:${videoWrapWidth} 高：${videoWrapHeight}`)
                        console.error('视频的宽和高与预期不一致:', `宽:${videoWidth} 高：${videoHeight}`)
                        Object.assign(videoEl.value?.style || {}, {
                            width: videoWrapWidth + 'px',
                            height: videoWrapHeight + 'px',
                        })
                        return
                    }
                } catch (err) {
                    console.error('video 标签或者其父元素没有高度。在处理过程当中，出现异常了。', err)
                }
                console.groupEnd()
            })
        })

        // loadeddata 事件则在媒体的第一帧数据加载完成时触发。
        player.value.on('loadeddata', () => {
            console.group('loadeddata 事件')
            console.log('loadeddata 事件则在媒体的第一帧数据加载完成时触发。')
            updateLocalData({
                posterImgVisible: false,
            })

            // 在这里，还需要做一个动作。找到vjs-tech这个元素，然后设置他的 transform: translateY()
            if (localData.navActive !== localData.tabName) {
                console.log('如果用户切换了tab之后，就把视频暂停')
                handlePause()
                return
            } else {
                // 如果相等，我们来看下他的声音。是有声音的还是没有声音的。
                // const isMuted = player.value?.muted()
                // if (realMutedIsChange.value && isMuted) {
                //     setVideoMuted(!toggleStore.isMuted)
                //     realMutedIsChange.value = false
                // }
                // const isMuted2 = player.value?.muted()
                console.log('这里可能是又回到了当前tab或者在当前tab切换了视频', Date.now())
                console.log('从切换视频到视频第一帧加载完成，用时', Date.now() - firstTime.value)
                handlePlay()
            }
            // @todo 自动播放的视频，这里为什么要加个手动去触发 播放。
            // handlePlay()
            console.groupEnd()
        })
        /**
         * 这里主要是当声音改变时
         */
        player.value.on('volumechange', () => {
            if (player.value) {
                setVideoMuted(player.value.muted() || false)
            }
        })
        player.value.on('playing', () => {
            lastTime.value = lastTime.value || Date.now()
            console.group('playing')
            console.log('如果视频还在播放，则看下他的 navActive 与 tabName 是否相等', localData.navActive === localData.tabName, lastTime.value)
            console.log('localData.navActive', localData.navActive)
            console.log('localData.tabName', localData.tabName)
            console.log('从切换视频到视频播放，总时长', lastTime.value - firstTime.value)

            try {
                const videoDomId = videoEl.value?.id ? `#${videoEl.value.id}` : ''
                const videoDom = document.querySelector(videoDomId) as HTMLElement
                const videoWidth = videoDom.clientWidth
                const videoHeight = videoDom.clientHeight
                console.log(`video 元素的宽高信息, 宽:${videoWidth} 高:${videoHeight}`, `视频帧宽:${localData.videoWidth}高:${localData.videoHeight}`)
            } catch (err) {
                console.error('video 标签异常，理论上来，这里永远不会有异常。', err)
            }
            console.groupEnd()

            SAVE_VIDEO_CONTAINER_DOM_ID(localData.videoTargetId)
            updateLocalData({
                isPlaying: true,
                isBuffering: false,
                isError: false,
                errorCode: 0,
                errorMessage: '',
                isLoading: false,
                posterImgVisible: false,
            })
        })
        player.value.on('waiting', () => {
            console.log('视频正在缓冲中……')
            updateLocalData({
                isPlaying: false,
                isBuffering: true,
                isError: false,
                errorCode: 0,
                errorMessage: '',
                isLoading: true,
            })
            // localData.isPlaying = false
        })
        player.value.on('pause', () => {
            console.log('视频已经暂停')
            updateLocalData({
                isPlaying: false,
            })
        })

        player.value.on('fullscreenchange', () => {
            localData.isFullscreen = !!player.value?.isFullscreen()
            muteButtonHandler()
            setVideoMuted(!!player.value?.muted())
            clearExitFullscreenPlayTimer()
            exitFullscreenPlayTimer.value = setTimeout(() => {
                handlePlay()
            }, 500)
        })

        /**
         * 视频点击，非全屏时，基本上是点的遮罩层，不会点到这里。
         * 但是，如果全屏之后，视频的层级会超过遮罩层。需要加个点击。
         * @todo 全屏后，好像只能在电脑端控制他的播放与暂停
         * @todo 在电脑上模拟三星手机上，监听不到点击事件。就连 document 事件也监听不到。？？？，但是可以查询。怀疑用的是系统自带的播放器，只不过因为是浏览器模拟
         */
        player.value.on('click', (event: MouseEvent) => {
            const target = event.target as HTMLElement
            if (localData.isFullscreen && isMobile.value && target.tagName === 'VIDEO') {
                if (IS_ANDROID.value) {
                    if (localData.isPlaying) {
                        handlePause()
                        localData.isPlaying = false
                    } else {
                        handlePlay()
                        localData.isPlaying = true
                    }
                }
            }
        })
        player.value.on('error', () => {
            // 阻止默认的错误处理行为
            try {
                const playerError = player.value?.error()
                console.log('捕获视频异常失败333', playerError)
                if (playerError) {
                    updateLocalData({
                        isPlaying: false,
                        isBuffering: false,
                        isError: true,
                        errorCode: localData.errorCode || playerError.code,
                        errorMessage: playerError.message,
                        // isLoading: false,
                    })
                }
            } catch (err) {
                console.error('捕获视频异常失败', err)
            }
            // handleDispose()
        })
    }
    /**
     * 设置视频静音状态
     * @remarks
     * 1. realMutedIsChange 如果真实的静音状态被改变，则不通知 pinia 改变 isMuted。详情见 realMutedIsChange 上的说明。
     * @param bool
     */
    const setVideoMuted = (bool: boolean) => {
        try {
            localData.isMuted = bool
            if (player.value) {
                player.value.muted(bool)
            }
            if (realMutedIsChange.value) {
                return
            }
            if (toggleStore.setMuted && typeof toggleStore.setMuted === 'function') {
                toggleStore.setMuted(!bool)
            }
        } catch (err) {
            console.error('setVideoMuted error', err)
        }
    }

    /**
     * 处理视频播放器的静音按钮点击事件，这个控件一般是指视频全屏后的一个操作。
     * @remarks
     * 当用户点击了静音按钮后，这里希望同步更改localData.isMuted，同时更改 toggleStore
     * 这个场景，realMutedIsChange 应该会是false,
     * 视频换源 - realMutedIsChange = true, 视频开始播放，realMutedIsChange = false
     * 有没有一种可能，还没等视频播放，用户就直接全屏了。会有影响吗？
     * 全屏 realMutedIsChange = true时，静音按钮发生了点击，会导致 无法通知 到 toggleStore
     * 所以，这里要重新 设置下 realMutedIsChange
     */
    const muteButtonClickEvent = () => {
        if (!player.value) {
            return
        }
        const isMuted = player.value.muted()
        realMutedIsChange.value = false
        setVideoMuted(!isMuted)
    }
    /**
     * 全屏后，监听播放器的静音按钮点击事件，
     * 进入全屏监听点击事件，退出全屏，移除该监听
     */
    const muteButtonHandler = () => {
        if (!player.value) {
            return
        }
        try {
            const muteButton = player.value.controlBar?.volumePanel?.el()
            if (localData.isFullscreen) {
                muteButton.addEventListener('click', muteButtonClickEvent)
            } else {
                muteButton.removeEventListener('click', muteButtonClickEvent)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const setPlayerBackBtn = () => {
        if (!player.value) {
            return
        }
        player.value?.getChild('ControlBar')?.addChild('button', {
            className: 'no__rem_vjs-fanhui vjs-visible-text iconfont icon-fanhui',
            clickHandler: (event: MouseEvent[]) => {
                try {
                    const firstEvent = event[0]
                    firstEvent.preventDefault()
                    firstEvent.stopPropagation()
                    const target = firstEvent.target as HTMLElement
                    if (target) {
                        target.style.zIndex = '999'
                    }
                } catch (err) {
                    console.error(err)
                }

                player.value?.controls(false)
                player.value?.exitFullscreen()
            },
        })
    }
    /**
     * 设置视频播放器的返回功能。
     * 全屏时，很多手机，用的还是我们自己的video,所以需要添加返回功能。
     */
    const setControl = () => {
        setPlayerBackBtn()
        // setQualitySelectorBtn()
        // setQualitySelector3()
    }

    /*
     * 处理视频组件挂载的事件
     */
    const handleVideoMounted = (data: IVideoMounted) => {
        localData.src = formatterVideoPath(data.src)
        localData.qualityField = 'videoPath'
        localData.tabName = data.tabName || 0
        localData.navActive = data.navActive || 0
        localData.selfIndex = data.selfIndex || 0
        localData.wherePage = data.wherePage || 'home'
        const oldVideoTargetId = localData.videoTargetId
        localData.videoTargetId = data.videoTargetId.includes('#') ? data.videoTargetId : `#${data.videoTargetId}`
        if (oldVideoTargetId && oldVideoTargetId === localData.videoTargetId && player.value) {
            handlePlay()
            return
        }
        SAVE_VIDEO_CONTAINER_DOM_ID('')
        localData.poster = data.poster
        localData.videoDomHeight = data.videoDomHeight ? data.videoDomHeight : appHeight.value
        videoWidthHeightHandler(data.videoInfo)
        localData.showVideo = true
        localData.posterImgVisible = true
        playerInitOrChangeSrc()
    }
    /*
     * 处理视频开始播放的事件
     * @remarks 因为。浏览器限制原因，每次切换视频源之前，要先静音，等到开始播放时，再根据真实的静音状态，来决定是否播放声音。
     */
    const handlePlay = () => {
        try {
            if (player.value) {
                // if (videoProgressInfoMap.has(localData.videoTargetId)) {
                //     const currentTime = videoProgressInfoMap.get(localData.videoTargetId)
                //     player.value?.currentTime(currentTime)
                //     videoProgressInfoMap.delete(localData.videoTargetId)
                // }
                if (realMutedIsChange.value) {
                    setVideoMuted(!toggleStore.isMuted)
                    realMutedIsChange.value = false
                }
                console.log('视频播放前，localData tabName', localData.tabName)
                player.value && player.value?.play()
                // nextTick().then(() => {
                //     player.value && player.value?.play()
                // })
            } else {
                console.error('视频开始播放,发现没有player')
            }
        } catch (err) {
            // 一个很常见的场景，没有找到此媒体的兼容源，原因不明。此时在线播放器是可以播放。
            // {code: 4, message: 'No compatible source was found for this media.'}
            console.error('很有可能是视频没有加载出来，然后调用了 play 方法', err)
        }
    }
    /*
     * 处理视频暂停的事件
     */
    const handlePause = (options?: { tabName: number }) => {
        try {
            //
            console.log('视频已经被暂停了。', options)
            player.value && player.value?.pause()
        } catch (err) {
            console.error('很有可能是视频没有加载出来，然后调用了 pause 方法', err)
        }
    }

    const firstTime = ref(0)
    const lastTime = ref(0)
    /**
     * 处理视频组件换源
     * 这里要注意下，videoTargetId 可能是一个没有带#号的字符串， 如果没有带#，这里需要给他加一个#号。
     * @param data
     */
    const handleChangeSource = async (data: IVideoMounted) => {
        firstTime.value = Date.now()
        lastTime.value = 0
        clearQualityTimer()

        localData.showVideo = true
        localData.tabName = data.tabName || 0
        localData.navActive = data.navActive || 0
        localData.selfIndex = data.selfIndex || 0
        localData.wherePage = data.wherePage || 'home'

        // 换源之前，要静音，这是为了什么了，我给忘记了。
        handlePause()
        realMutedIsChange.value = true
        setVideoMuted(true)
        const videoTargetId = data.videoTargetId.includes('#') ? data.videoTargetId : `#${data.videoTargetId}`
        if (videoTargetId !== data.videoTargetId) {
            localData.posterImgVisible = true
        }
        const oldVideoTargetId = localData.videoTargetId
        localData.videoTargetId = videoTargetId

        // 如果只是切换了Tab, 并没有换源，例如： 这里不应该换源。如何判断只是切换了tab,一个关键性的指标，判断oldVideoTargetId 和 localData.videoTargetId 是否一致。
        // localData.oldNavActive !== undefined 存在，只是表示曾经切换过 tab
        // 如何判断需要继续播放，还是更换新的视频。
        if (oldVideoTargetId && oldVideoTargetId === localData.videoTargetId && player.value) {
            handlePlay()
            return
        }
        SAVE_VIDEO_CONTAINER_DOM_ID('')
        localData.src = formatterVideoPath(data.src)
        localData.qualityField = 'videoPath'
        localData.poster = data.poster
        localData.videoDomHeight = data.videoDomHeight ? data.videoDomHeight : appHeight.value
        await nextTick()
        videoWidthHeightHandler(data.videoInfo)
        if (!videoEl.value) {
            return
        }
        console.log('handleChangeSource')
        playerInitOrChangeSrc()
    }
    const qualityTimer = ref<ReturnType<typeof setTimeout> | null>(null)
    const clearQualityTimer = () => {
        if (qualityTimer.value) {
            clearTimeout(qualityTimer.value)
            qualityTimer.value = null
        }
    }

    /*
     * 处理切换静音状态的事件
     */
    const handleChangeMuted = (isMuted: boolean) => {
        setVideoMuted(isMuted)
    }
    /*
     * 处理切换播放/暂停状态的事件
     */
    const handleTogglePlayPause = () => {
        if (player.value) {
            if (player.value.paused()) {
                handlePlay()
            } else {
                handlePause()
            }
        }
    }
    /**
     * 用户申请进入全屏状态。
     */
    const handleRequestFullscreen = () => {
        localData.isFullscreen = true
        if (player.value) {
            player.value.isFullscreen(true)
            player.value.requestFullscreen()
            player.value.controls(true)
        }
    }
    // 退出全屏时，在ios机器上，视频会暂停一下。，需要延时重新让他播放。
    const exitFullscreenPlayTimer = ref<ReturnType<typeof setTimeout> | null>(null)
    const clearExitFullscreenPlayTimer = () => {
        if (exitFullscreenPlayTimer.value) {
            clearTimeout(exitFullscreenPlayTimer.value)
            exitFullscreenPlayTimer.value = null
        }
    }
    /**
     * 用户申请 退出全屏。
     */
    const handleExitFullScreen = () => {}
    /*
     * 处理释放视频资源的事件
     */
    const handleDispose = () => {
        if (player.value) {
            player.value.dispose()
            player.value = null
            videoEl.value = undefined
        }
        localData.showVideo = false
        localData.videoTargetId = '#app'
        // clearVideoReportLog()
    }
    const isChangeTab = ref(false)
    const handleChangeTab = (options: { navActive: number }) => {
        localData.oldNavActive = localData.navActive
        localData.navActive = options.navActive
        isChangeTab.value = true
    }
    const videoBusEventHandlers = {
        [EventBusTypes.VIDEO.MOUNTED]: handleVideoMounted,
        [EventBusTypes.VIDEO.PLAY]: handlePlay,
        [EventBusTypes.VIDEO.PAUSE]: handlePause,
        [EventBusTypes.VIDEO.CHANGE_TAB]: handleChangeTab,
        [EventBusTypes.VIDEO.SAVA_CURRENT_TIME]: handleChangeSourceSaveCurrentTime,
        [EventBusTypes.VIDEO.CHANGE_SOURCE]: handleChangeSource,
        [EventBusTypes.VIDEO.CHANGE_MUTED]: handleChangeMuted,
        [EventBusTypes.VIDEO.TOGGLE_PLAY_PAUSE]: handleTogglePlayPause,
        [EventBusTypes.VIDEO.DISPOSE]: handleDispose,
        [EventBusTypes.VIDEO.REQUEST_FULLSCREEN]: handleRequestFullscreen,
        [EventBusTypes.VIDEO.EXIT_FULLSCREEN]: handleExitFullScreen,
    }
    onMounted(() => {
        Object.entries(videoBusEventHandlers).forEach(([eventType, handler]) => {
            bus.on(eventType, handler)
        })
    })
    onBeforeUnmount(() => {
        Object.entries(videoBusEventHandlers).forEach(([eventType, handler]) => {
            bus.off(eventType, handler)
        })
        clearExitFullscreenPlayTimer()
    })
    return {
        videoEl,
        player,
        localData,
        updateLocalData,
        videoProperty,
    }
}
