import request from '@/utils/request'
import { LoginForm, LoginData, UserInfo, SmsFrom, UserIndexParam, myFensListItem, UploadUrlParam, UploadUrlData, UserWorkList, MyWorkListParam, DiscoverUserMoreParam, MyFensListParam, MyFollowListParam, IEditUserInfo, TUserConfigRes } from '@/api/user/type'
import { PageAndSize, Pagination, Result } from '@/api'
import { IndexResponseItemData, IUserVipBuyReq, IUserVipBuyResp, IUserVipLevelListItem } from '@/api/home/type'
import { data } from 'autoprefixer'
import { fetchOrCreateDeviceId } from '@/utils/fingerprintUtils.ts'

enum API {
    LOGIN_URL = '/api/user/login',
    USERINFO_URL = '/api/user/userInfo',
    Tourist_Login_URL = '/api/user/touristLogin',
    SMS_URL = '/api/user/sendSms',
    SET_USERINFO = '/api/user/userUpdate',
}

export const reqLogin = (data: LoginForm) => {
    return request.post<any, Result<LoginData>>(API.LOGIN_URL, data, {
        headers: {
            isShowErrorTip: true,
            // isShowLoading: true,
        },
    })
}

export const reqUserInfo = () => {
    return request.post<any, Result<UserInfo>>(API.USERINFO_URL)
}

export const reqTouristLogin = async () => {
    const deviceId = await fetchOrCreateDeviceId()
    return request.post<any, any>(API.Tourist_Login_URL, {
        deviceId: deviceId,
    })
}
export const reqSendSms = (data: SmsFrom) => {
    return request.post<any, any>(API.SMS_URL, data, {
        headers: {
            isShowErrorTip: true,
            isShowLoading: true,
            reportLogType: 'SmsSend',
        },
    })
}
export const reqSetUserInfo = (data: IEditUserInfo) => {
    return request.post<any, any>(API.SET_USERINFO, data, {
        headers: {
            isShowErrorTip: true,
            isShowLoading: true,
            reportLogType: 'UpdateInfo',
        },
    })
}
/**
 * 获取他人用户信息
 * @param data
 */
export const reqUserIndex = (data: UserIndexParam) => {
    return request.post<any, Result<UserInfo>>('/api/user/userIndex', data)
}

/**
 * 获取图片url
 * @param data
 */
export const reqUpLoadUrl = (data: UploadUrlParam) => {
    return request.post<any, Result<UploadUrlData>>('/api/upload/getUploadUrl', data)
}
/**
 * 用户作品列表
 * @param data
 */
export const reqUserWorkList = (data: UserWorkList) => {
    return request.post<any, Result<Pagination<IndexResponseItemData[]>>>('/api/videoInfo/workList', data)
}
/**
 * 用户作品列表
 * @param data
 */
export const reqMyWorkList = (data: MyWorkListParam) => {
    return request.post<any, Result<Pagination<IndexResponseItemData[]>>>('/api/user/myWorkList', data)
}
/**
 * 删除作品
 * @param data
 */
export const reqDelWork = (data: { videoId: number }) => {
    return request.post<any, Result<Pagination<IndexResponseItemData[]>>>('/api/user/userDeleteWork', data, {
        headers: {
            isShowErrorTip: true,
            isShowLoading: true,
        },
    })
}
/**
 * 发现更多用户
 */
export const reqDiscoverUserMore = (data: DiscoverUserMoreParam) => {
    return request.post<any, Result<Pagination<myFensListItem[]>>>('/api/home/discoverUserMore', data)
}

/**
 * 我的粉丝列表
 * @param data
 */
export const reqMyFensList = (data: MyFensListParam) => {
    return request.post<any, Result<Pagination<myFensListItem[]>>>('/api/user/myFensList', data)
}
/**
 * 我的关注列表
 * @param data
 */
export const reqMyFollowList = (data: MyFollowListParam) => {
    return request.post<any, Result<Pagination<myFensListItem[]>>>('/api/user/myFollowList', data)
}
/**
 * 我的喜欢列表
 * @param data
 */
export const reqLikeList = (data: PageAndSize) => {
    return request.post<any, Result<Pagination<IndexResponseItemData[]>>>('/api/user/myLikeList', data)
}
/**
 * 我的收藏列表
 * @param data
 */
export const reqCollectList = (data: PageAndSize) => {
    return request.post<any, Result<Pagination<IndexResponseItemData[]>>>('/api/user/myCollectList', data)
}

export const reqUserVipLevelList = () => {
    return request.post<any, Result<IUserVipLevelListItem[]>>('/api/user/userVipLevelList', data)
}
/**
 * 购买Vip
 */
export const reqUserVipBuy = (data: IUserVipBuyReq) => {
    return request.post<any, Result<IUserVipBuyResp>>('/api/user/userVipBuy', data)
}

/**
 * 获取相关的配置，
 * @param data
 * @param data.userId 如果用户没有登录，就是游客id, MY_USER_INFO.id
 */
export const reqUserConfig = (data: { userId: number }) => {
    return request.post<any, Result<TUserConfigRes>>('/api/user/userConfig', data)
}
