import { nextTick, onBeforeUnmount, onMounted, ref, Ref, UnwrapRef } from 'vue'
import { getDuration, setCurrentTime } from '@/utils/video.ts'
import Player from 'video.js/dist/types/player'
import bus, { EventBusTypes } from '@/utils/bus.ts'
import { myDebounce } from '@/utils'
interface ExtendedPlayer extends Player {
    controlBar: any // 根据需要定义具体类型
}
/**
 * 进度条
 * @param player
 */
export const useVideoProgress = (player: Ref<UnwrapRef<ExtendedPlayer> | null>, localData?: any) => {
    const controlRef = ref<HTMLDivElement>()

    //显示总时长
    const duration = ref(0)
    //显示播放到的当前时间
    const currentTime = ref(0)

    //滑动到位置的高度
    const progressWidth = ref(0)
    //移动的类型，用来标明是否在拖动进度条
    const moveType = ref('')
    //拖动开始时点击的位置
    const moveOffsetX = ref(0)
    //拖动开始时进度条的宽度度
    const curWidth = ref(0)
    // 进度条的宽度
    let progressTotalWidth = 0
    onMounted(() => {
        getVideoProgressTotalWidth()
    })
    /**
     * 获取进度条的总宽度，注意的是，虽然可以在onMounted中执行，但是，controlRef 或者是他的父级，可能是一个条件渲染。即使是nextTick，也可能获取不到，所以，需要手动调用
     */
    const getVideoProgressTotalWidth = () => {
        nextTick(() => {
            try {
                if (controlRef.value) {
                    // getBoundingClientRect 是一个 DOM 方法，用于获取元素的边界框信息。它返回一个包含元素的大小和相对于视口位置的 DOMRect 对象
                    progressTotalWidth = controlRef.value.getBoundingClientRect().width
                }
            } catch (err) {
                // 这里主要是为了防止 controlRef.value 不是一个dom.
                console.log('controlRef.value 有可能不是一个DOM', err)
            }
        }).then(() => {})
    }
    /**
     * 初始化视频进度条的数据
     * @todo currentTime, 我更加希望他是一个可以记忆的。能够记录播放到哪个位置了。
     */
    const initVideoProgressData = () => {
        duration.value = 0
        currentTime.value = parseInt(String(player.value?.currentTime()))
        progressWidth.value = 0
        getVideoProgressTotalWidth()
    }
    //得到视频当前播放到哪里的信息
    const getCurrentInfo = () => {
        // controlRef 或者他的父级，可能是一个条件渲染。即使是nextTick，也可能获取不到，所以，需要手动调用
        if (!progressTotalWidth) {
            getVideoProgressTotalWidth()
        }
        if (duration.value === 0) {
            let sourceTime = 1

            try {
                const sourceVideoInfo = localData?.videoInfo?.sourceVideoInfo ? JSON.parse(localData.videoInfo.sourceVideoInfo) : null
                if (sourceVideoInfo?.time) {
                    sourceTime = Number(sourceVideoInfo.time)
                }
            } catch (err) {
                console.error('获取视频信息失败', err)
            }

            duration.value = getDuration(player) || sourceTime
        }
        // 视频已经播放时长
        currentTime.value = parseInt(String(player.value?.currentTime()))

        const ratio = currentTime.value / duration.value
        progressWidth.value = progressTotalWidth * ratio
    }
    //设置进度条的长度
    const setProgress = (e: MouseEvent) => {
        e.preventDefault()

        const { left, width } = controlRef.value!.getBoundingClientRect()
        progressWidth.value = e.clientX - left
        updateCurrentTime(progressWidth.value, width)
        player.value?.play()
    }

    //设置视频播放到指定的长度
    const updateCurrentTime = (progressWidth: number, width: number) => {
        duration.value = getDuration(player)
        const dest = (progressWidth / width) * duration.value
        setCurrentTime(player, Math.floor(dest))
    }

    //当开始触摸开始在圆点按下时
    const sliderStart = (e: TouchEvent | MouseEvent) => {
        e.preventDefault()
        moveOffsetX.value = 'clientX' in e ? e.clientX : e.touches[0].clientX
        moveType.value = 'slider'
        curWidth.value = progressWidth.value
    }

    //当触摸在controls上移动时
    const controlMove = (e: TouchEvent | MouseEvent) => {
        if (moveType.value !== 'slider') {
            return false
        }
        e.preventDefault()
        // 滑动距离可视区域左侧的距离
        const X = 'clientX' in e ? e.clientX : e.touches[0].clientX
        //得到本次拖动已经过的距离
        const cl = X - moveOffsetX.value
        //容器的宽度
        const { width } = controlRef.value!.getBoundingClientRect()
        //得到已拖动到宽度
        const ml = curWidth.value + cl
        let proWidth
        if (ml <= 0) {
            //进度条长度最小和最大值的界定
            proWidth = 0
        } else if (ml >= width) {
            proWidth = width
        } else {
            proWidth = ml
        }
        progressWidth.value = proWidth
        // 更新当前时间
        updateCurrentTime(progressWidth.value, width)
    }

    //滑动结束
    const controlEnd = () => {
        moveType.value = ''
        player.value?.play()
    }

    const formatTime = (s: number): string => {
        let t
        if (s > -1) {
            const hour = Math.floor(s / 3600)
            const min = Math.floor(s / 60) % 60
            const sec = s % 60
            if (hour <= 0) {
                t = ''
            } else if (0 < hour && hour < 10) {
                t = '0' + hour + ':'
            } else {
                t = hour + ':'
            }

            if (min < 10) {
                t += '0'
            }
            t += min + ':'
            if (sec < 10) {
                t += '0'
            }
            t += sec
        }
        return t as string
    }
    const isListened = ref(false)
    /**
     * 因为异步的影响，
     * EventBusTypes.VIDEO.MOUNTED 触发时，有可能，dom还没有渲染完成，另外一个,dom渲染完成了，
     * useVideoPlayer 中，在初始化播放器或者更换视频的时候，是采用了延时机制的，可能是为了等待video dom渲染完成
     * 因此，这个函数需要分别在。handleVideoMounted，playerLister，watch中处理。那么，watch中处理的
     */
    const playerLister = myDebounce(() => {
        nextTick(() => {}).then(() => {
            if (player.value && !isListened.value) {
                isListened.value = true
                player.value.on('loadedmetadata', initVideoProgressData)
                player.value.on('timeupdate', getCurrentInfo)
            }
        })
    }, 10)
    const handleVideoMounted = () => {
        playerLister()
    }
    const handleChangeSource = () => {
        // initVideoProgressData()
        playerLister()
    }

    const handleDispose = () => {
        isListened.value = false
    }
    onMounted(() => {
        bus.on(EventBusTypes.VIDEO.MOUNTED, handleVideoMounted)
        bus.on(EventBusTypes.VIDEO.CHANGE_SOURCE, handleChangeSource)
        bus.on(EventBusTypes.VIDEO.DISPOSE, handleDispose)
    })
    onBeforeUnmount(() => {
        if (player.value) {
            player.value.off('timeupdate', getCurrentInfo)
            player.value.off('loadedmetadata', initVideoProgressData)
        }
        bus.off(EventBusTypes.VIDEO.MOUNTED, handleChangeSource)
        bus.off(EventBusTypes.VIDEO.DISPOSE, handleDispose)
    })

    return {
        moveType,
        duration,
        currentTime,
        progressWidth,
        controlRef,
        getCurrentInfo,
        setProgress,
        sliderStart,
        controlMove,
        controlEnd,
        formatTime,
        initVideoProgressData,
    }
}
