import request from '@/utils/request.ts'
import { Pagination, Result } from '@/api'
import {
    BlackParam,
    ChatDeleteParam,
    ChatListParam,
    ChatListResponse,
    ChatMessageParam,
    DoTopParam,
    ListChannelMessagesType,
    NoticeListItem,
    typeRecommendUserResp,
    NoticeListParam,
    SendMessageParam,
    SendMessageResponse,
    NewChatUserParam,
    RecommendUserItem,
    SearchChannelItem,
    AtVideoListParam,
} from '@/api/message/type'
import { myFensListItem } from '@/api/user/type'
import { IndexResponseItemData } from '@/api/home/type'

/**
 * 获取聊天列表
 */
export const reqChatList = (data: ChatListParam) => {
    return request.post<any, Result<ChatListResponse>>('/api/message/chatList', data)
}

/**
 * 标记消息已读, 离开的时候调用
 * @param data
 */
export const reqReadChannel = (data: { userId: number }) => {
    return request.post<any, Result<{ userId: number }>>('/api/message/readChannel', data)
}
// 标记系统消息状态
export const reqReadNotice = (data: { channelType: 'notice' | 'fens' }) => {
    return request.post<any, Result<any>>('/api/message/setChannelRead', data)
}

/**
 * channelType 类型 notice=系统通知 fens=新增粉丝
 * status 状态 0=未读
 */
export const reqGetNoticeStatus = () => {
    return request.post<any, Result<{ channelData: { channelType: string; status: number }[] }>>('/api/message/chanelStatus')
}
/**
 * 获取聊天置顶列表
 */
export const reqTopList = () => {
    return request.post<any, Result<ChatListResponse>>('/api/message/topList')
}
/**
 *  置顶和取消置顶
 * @param data
 */
export const reqDoTop = (data: DoTopParam) => {
    return request.post<any, any>('/api/message/doTop', data, {
        headers: {
            isShowErrorTip: true,
        },
    })
}
/**
 *  删除聊天
 * @param data
 */
export const reqChatDelete = (data: ChatDeleteParam) => {
    return request.post<any, any>('/api/message/chatDelete', data, {
        headers: {
            isShowErrorTip: true,
        },
    })
}

/**
 * 获取聊天记录
 * @param data
 */
export const reqChatMessage = (data: ChatMessageParam) => {
    return request.post<any, Result<ListChannelMessagesType>>('/api/message/chatMessage', data)
}

/**
 * 创建聊天频道
 * @param data
 */
export const reqCreateChannel = (data: { userId: number }) => {
    return request.post<any, Result<ListChannelMessagesType>>('/api/message/createChannel', data)
}

/**
 * 第一次发起聊天
 * @param data
 */
export const reqSendMessage = (data: SendMessageParam) => {
    const ReportLogExtra = {
        codeMatchType: 'exclusion',
        codeValues: [80000, 90000],
    }
    return request.post<any, Result<SendMessageResponse>>('/api/message/send', data, {
        headers: {
            isShowErrorTip: true,
            isShowLoading: true,
            reportLogType: `MessageSend,extra=${JSON.stringify(ReportLogExtra)}`,
        },
    })
}

/**
 * 消息通知记录
 * @param data
 */
export const reqNoticeList = (data: NoticeListParam) => {
    return request.post<any, Result<Pagination<NoticeListItem[]>>>('/api/message/noticeList', data)
}
/**
 * 拉黑用户
 * @param data
 */
export const reqBlockUser = (data: BlackParam) => {
    return request.post<any, Result<Pagination<myFensListItem[]>>>('/api/message/blockUser', data, {
        headers: {
            isShowErrorTip: true,
        },
    })
}
/**
 * 推荐用户
 */
export const reqRecommendUser = () => {
    return request.post<any, Result<typeRecommendUserResp>>('/api/message/getRecommendUser', {})
}
/**
 * 搜索新的用户
 * @param data
 */
export const reqNewChatUser = (data: NewChatUserParam) => {
    return request.post<any, Result<Pagination<RecommendUserItem[]>>>('/api/message/newChatUser', data)
}
/**
 * 搜索对应的聊天人
 * @param data
 */
export const reqSearchChannel = (data: NewChatUserParam) => {
    return request.post<any, Result<Pagination<SearchChannelItem[]>>>('/api/message/searchChannel', data)
}

export const reqAtVideoList = (data: AtVideoListParam) => {
    return request.post<any, Result<Pagination<IndexResponseItemData[]>>>('/api/message/atVideoList', data)
}
