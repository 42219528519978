import { Ref } from 'vue'
import { IndexResponseItemData } from '@/api/home/type'

/**
 * 进入全屏
 * @param ele 视频对象
 * @returns
 */
export const fullScreen = (ele: Ref<any>) => {
    if (ele.value.requestFullscreen) {
        ele.value.requestFullscreen()
    } else if (ele.value.mozRequestFullScreen) {
        ele.value.mozRequestFullScreen()
    } else if (ele.value.webkitRequestFullscreen) {
        ele.value.webkitRequestFullscreen()
    } else if (ele.value.msRequestFullscreen) {
        ele.value.msRequestFullscreen()
    }
    return true
}

/**
 * 跳转当前播放时间
 * @param ele
 * @param time
 */
export const setCurrentTime = (ele: Ref<any>, time: number) => {
    ele.value.currentTime(time)
}

/**
 * 获取视频长度
 * @param ele
 */
export const getDuration = (ele: Ref<any>) => {
    return Math.floor(ele.value.duration())
}

/**
 * 获取视频的宽度、高度及其是否为横屏格式。
 *
 * @param {IndexResponseItemData | { videoWidth: number; height: number }} data - 包含视频信息的对象。
 * @param appWidth
 * @param videoDomHeight
 * @returns {{ videoWidth: number; videoHeight: number; isLandscape: boolean }} 返回一个对象，包含视频的宽度、高度和是否为横屏。
 */
export const getVideoWHL = (data: IndexResponseItemData | { videoWidth: number; videoHeight: number }, appWidth?: number, videoDomHeight?: number): { videoWidth: number; videoHeight: number; isLandscape: boolean; objectFitCls?: string } => {
    const retObj = {} as { videoWidth: number; videoHeight: number; isLandscape: boolean; objectFitCls?: string }
    if ('videoWidth' in data && 'videoHeight' in data) {
        const obj = {
            videoWidth: data.videoWidth,
            videoHeight: data.videoHeight,
            isLandscape: data.videoWidth > data.videoHeight,
        }
        Object.assign(retObj, obj)
    } else {
        const { w, h } = JSON.parse(data.sourceVideoInfo)
        Object.assign(retObj, {
            videoWidth: Number(w),
            videoHeight: Number(h),
            isLandscape: Number(w) > Number(h),
        })
    }
    if (appWidth && videoDomHeight) {
        Object.assign(retObj, {
            objectFitCls: (videoDomHeight * retObj.videoWidth) / retObj.videoHeight > (appWidth * retObj.videoHeight) / retObj.videoWidth ? 'contain' : 'cover',
        })
    }
    return retObj
}

const pendingRequests = new Map<string, { promise: Promise<any>; timestamp: number }>()
const maxTime = 1000 * 60 * 30 // 30分钟
const cleanExpiredRequests = () => {
    const currentTime = Date.now()
    for (const [videoUrl, cacheEntry] of pendingRequests) {
        if (currentTime - cacheEntry.timestamp >= maxTime) {
            // 删除超时缓存
            pendingRequests.delete(videoUrl)
        }
    }
}
export const fetchVideoHeadData = (videoUrl: string) => {
    const currentTime = Date.now()
    cleanExpiredRequests()
    const cacheEntry = pendingRequests.get(videoUrl)

    // 如果请求已经在进行中且请求未超时（30分钟内），返回相同的 Promise
    if (cacheEntry && currentTime - cacheEntry.timestamp < maxTime) {
        return cacheEntry.promise
    }

    // 创建一个新的请求 Promise
    const requestPromise = new Promise<any>((resolve, reject) => {
        fetch(videoUrl, { method: 'HEAD' })
            .then((response) => {
                if (response.status !== 200) {
                    pendingRequests.delete(videoUrl)
                }
                resolve(response) // 请求失败时，拒绝 Promise
            })
            .catch((error) => {
                // 失败了要立即删除，因为可能是因为网络问题，导致失败。
                pendingRequests.delete(videoUrl)
                reject(error) // 请求失败时，拒绝 Promise
            })
    }).finally(() => {
        // 请求完成后，无论成功或失败，更新缓存
        // 请求完成后，无论成功或失败，检查是否超时
        const cacheEntry = pendingRequests.get(videoUrl)
        if (cacheEntry && currentTime - cacheEntry.timestamp >= maxTime) {
            // 如果请求超时，则清除缓存
            pendingRequests.delete(videoUrl)
        }
    })

    // 将新的请求 Promise 和时间戳添加到缓存中
    pendingRequests.set(videoUrl, { promise: requestPromise, timestamp: currentTime })

    return requestPromise
}
