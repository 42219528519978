import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { IndexMenuItem, IndexResponseItemData } from '@/api/home/type'
import { reqIndexList, reqTopMenuList } from '@/api/home'
// @todo，这里用了 user 的 store，所以，不能再在 user 的 store 里用 home 的 store，不然会报错。
// @todo 另外一种方式是每次 调用的时候，传一个 useUserStore 进来，但是，这样会很麻烦。
// @todo 退出时，调用 一下 $reset，重置一下 store。
// 退出退出时，要调用退出的方法。
import { useUserStore } from '@/store/modules/user'
import { reqSingleList } from '@/api/search'

import { simulationHomeVList } from '@/store/modules/homeMock.ts'

export const useHomeStore = defineStore(
    'homePage',
    () => {
        const { MY_USER_INFO } = storeToRefs(useUserStore())
        // 导航菜单的索引值。
        const NAV_ACTIVE = ref(1)
        const SET_NAV_ACTIVE = (index: number) => {
            NAV_ACTIVE.value = index
        }
        // 导航菜单列表
        const NAV_MENU_LIST = ref<IndexMenuItem[]>([])

        // @todo 设置导航菜单列表 暂时未用到
        const SET_NAV_MENU_LIST_ITEM = (list: any[]) => {
            NAV_MENU_LIST.value[NAV_ACTIVE.value].vList = list
        }
        /**
         * 异步设置 导航菜单中的 videoList 及其分页等数据，
         * 每次请求完后，他的 page 是不是要加1，他把这个逻辑放到了加载更多的步骤里了。！！！
         * 这里有个设置，如果是用户分享出去的链接，链接上会带一个videoId, 一个 menuIndex
         * @constructor
         */
        const REQ_GET_NAV_MENU_LIST_ITEM = async (query?: { menuIndex?: string; videoId?: string }) => {
            // 请求成功后，要取请求时的 NAV_ACTIVE.value, 要不然，会有一个问题，当网络请求慢的时候，他会操作当前 tab 下的数据
            const requestNavActive = NAV_ACTIVE.value
            if (query && query.videoId) {
                const signRes = await reqSingleList({
                    userId: MY_USER_INFO.value.id,
                    videoId: [Number(query.videoId)],
                })
                if (Object.prototype.hasOwnProperty.call(query, 'menuIndex')) {
                    SET_NAV_ACTIVE(Number(query.menuIndex))
                }

                if (signRes.code === 0) {
                    const { vList } = NAV_MENU_LIST.value[requestNavActive]
                    NAV_MENU_LIST.value[requestNavActive].vList = <IndexResponseItemData[]>vList.concat(signRes.data)
                }
            }
            const { page, size, nextToken, menuId, userId, vList } = NAV_MENU_LIST.value[requestNavActive]
            try {
                const res = await reqIndexList({ page, size, menuId, userId, nextToken })
                if (res.code === 0) {
                    NAV_MENU_LIST.value[requestNavActive].totalPage = res.data.pageSize
                    NAV_MENU_LIST.value[requestNavActive].vList = vList.concat(res.data.data).map((item) => {
                        return {
                            ...item,
                            videoPath: item.videoPath ? item.videoPath : item.urlHeight || '',
                        }
                    })
                    NAV_MENU_LIST.value[requestNavActive].nextToken = res.data.nextToken
                    // if (!res.data.nextToken) {
                    //     NAV_MENU_LIST.value[NAV_ACTIVE.value].m
                    // }
                }
                if (process.env.NODE_ENV === 'development' && import.meta.env.VITE_APP_HOME_SIMULATION === 'true') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    NAV_MENU_LIST.value[requestNavActive].vList = simulationHomeVList()
                }
                return res.data
            } catch (err) {
                // TODO: 处理异常
                return {}
            }
        }
        /**
         * 更新导航菜单列表中的 videoList，
         * @param _data 单独某一个video 数据或者多个video数据, 仅操作videoList数据
         * @constructor
         */
        const UPDATE_NAV_MENU_LIST_LIST = (_data: IndexResponseItemData | IndexResponseItemData[]) => {
            const { vList } = NAV_MENU_LIST.value[NAV_ACTIVE.value]
            const data = _data && Array.isArray(_data) ? _data : [_data]
            NAV_MENU_LIST.value[NAV_ACTIVE.value].vList = vList.map((item: IndexResponseItemData) => {
                const active = data.find((dataItem: IndexResponseItemData) => dataItem.id === item.id)
                if (active) {
                    return {
                        ...item,
                        ...active,
                    }
                }
                return item
            })
        }
        /**
         * 导航栏切换之后的一个数据响应
         * 这里有个问题。他的请求状态没有。
         * @constructor
         */
        const NAV_ACTIVE_ON_CHANGE = async () => {
            console.log('NAV_ACTIVE 2', NAV_ACTIVE.value)
            if (NAV_MENU_LIST.value[NAV_ACTIVE.value].vList.length === 0) {
                await REQ_GET_NAV_MENU_LIST_ITEM()
            }
        }

        /**
         * 刷新导航菜单列表中的某个栏目，
         * @todo 暂时未用上。
         * @constructor
         */
        const REFRESH_NAV_MENU_LIST_ITEM = async () => {
            NAV_MENU_LIST.value[NAV_ACTIVE.value].vList = []
            NAV_MENU_LIST.value[NAV_ACTIVE.value].page = 1
            NAV_MENU_LIST.value[NAV_ACTIVE.value].totalPage = 0
            NAV_MENU_LIST.value[NAV_ACTIVE.value].playVideoIndex = 0
            NAV_MENU_LIST.value[NAV_ACTIVE.value].nextToken = ''
            await REQ_GET_NAV_MENU_LIST_ITEM()
        }
        /**
         * 加载更多导航菜单列表中的某个栏目，他实际上是由 videoList里上下翻页时 发送出来的。只要翻页，必定触发。
         * @constructor
         * @param activeIndex 正在播放的视频在数据中的一个索引值，其实相当于 NAV_MENU_LIST.value[NAV_ACTIVE.value].playVideoIndex
         */
        const LOAD_MORE_NAV_MENU_LIST_LIST = async (activeIndex: number) => {
            const { nextToken } = NAV_MENU_LIST.value[NAV_ACTIVE.value]
            if (!nextToken) return
            console.log('activeIndex', activeIndex, NAV_MENU_LIST.value[NAV_ACTIVE.value].vList.length)
            if (activeIndex >= NAV_MENU_LIST.value[NAV_ACTIVE.value].vList.length - 3) {
                NAV_MENU_LIST.value[NAV_ACTIVE.value].page++
                await REQ_GET_NAV_MENU_LIST_ITEM()
            }
        }

        const NEED_DEL_PLAY_INDEX = ref<number | undefined>()
        const NEED_DEL_PLAY_NAV_INDEX = ref<number | undefined>()
        /**
         * 设置需要删除的视频索引值。
         * @constructor
         */
        const SET_NEED_DEL_PLAY_INDEX = (playIndex: number, navIndex: number) => {
            NEED_DEL_PLAY_NAV_INDEX.value = navIndex
            NEED_DEL_PLAY_INDEX.value = playIndex
        }
        /**
         *  设置视频播放的索引值。
         * @param index 这个值是从 videoList 组件中传出来的，videoList 组件中有一个 v-for 循环，循环的索引值就是 index。每次上下翻页时，他都会把这个值返回出来。
         * @param navActive
         * @constructor
         */
        const SET_VIDEO_PLAY_INDEX = (index: number, navActive?: number) => {
            const tabIndex = navActive ? navActive : NAV_ACTIVE.value

            NAV_MENU_LIST.value[tabIndex].playVideoIndex = index
            // return
            // 表示的是同一个。
            // if (NEED_DEL_PLAY_NAV_INDEX.value !== undefined && NEED_DEL_PLAY_INDEX.value !== undefined && tabIndex === NEED_DEL_PLAY_NAV_INDEX.value) {
            //     // 首先是删除，然后playVideoIndex - 1
            //     REMOVE_NAV_MENU_LIST(NEED_DEL_PLAY_INDEX.value, tabIndex)
            //     // 如果 当前视频的id 大于之前的索引。说明用户是滑到了下一个视频，此时，因为我们删除了前一个视频，这里的索引值，要改变。
            //     if (index > NEED_DEL_PLAY_INDEX.value) {
            //         NAV_MENU_LIST.value[tabIndex].playVideoIndex = NEED_DEL_PLAY_INDEX.value
            //     }
            //     NEED_DEL_PLAY_INDEX.value = undefined
            //     NEED_DEL_PLAY_NAV_INDEX.value = undefined
            //     return
            // }
            // NAV_MENU_LIST.value[tabIndex].playVideoIndex = index
        }

        const INIT_HOME_STORE = () => {
            NAV_MENU_LIST.value = []
            NAV_ACTIVE.value = 0
        }
        /**
         *  异步 更新菜单中所有栏目的videoList 数据，或者指定 视频id的数据
         *  应用场景举例：1.游客身份 重新登录，2。对某个人关注或者取关。
         * @constructor
         */
        const REQ_ALL_NAV_MENU_LIST_LIST = async (videoIds?: number | number[]) => {
            // 确保 videoIds 是一个数组
            const newVideoIds = Array.isArray(videoIds) ? videoIds : videoIds ? [videoIds] : []

            // 生成需要更新的视频 ID 列表，如果没有传入 videoIds，则使用当前菜单中的所有视频 ID
            const videoListIds = newVideoIds.length ? newVideoIds : NAV_MENU_LIST.value.flatMap((item) => item.vList.map((video) => video.id))

            if (!videoListIds.length) {
                INIT_HOME_STORE()
                return []
            }
            try {
                const res = await reqSingleList({
                    userId: MY_USER_INFO.value.id,
                    videoId: videoListIds,
                })
                if (res.code === 0 && res.data && Array.isArray(res.data)) {
                    NAV_MENU_LIST.value.forEach((item) => {
                        item.vList = item.vList.map((v) => {
                            const data = res.data.find((d) => d.id === v.id)
                            return data ? data : v
                        })
                    })
                    return res.data
                } else {
                    return []
                }
            } catch (error) {
                return []
            }
        }
        /**
         * 异步请求 导航菜单列表。最外围的数据
         * 这里有个设置，如果是用户分享出去的链接，链接上会带一个videoId, 一个 menuIndex
         * @constructor
         */
        const REQ_SET_NAV_MENU_LIST = async (query?: { menuIndex?: string; videoId?: string }) => {
            // TODO: 调用接口获取数据

            const res = await reqTopMenuList()
            if (res.code === 0) {
                NAV_MENU_LIST.value = res.data.menuItem.map((item) => {
                    return {
                        ...item,
                        vList: <IndexResponseItemData[]>[],
                        playVideoIndex: 0,
                        page: 1,
                        size: 5,
                        totalPage: 0,
                        nextToken: '', // TODO: 获取下一页的 token
                        menuId: item.id,
                        userId: MY_USER_INFO.value.id,
                        tplRef: null,
                    }
                })
                if (query && query.menuIndex) {
                    SET_NAV_ACTIVE(Number(query.menuIndex))
                } else if (NAV_MENU_LIST.value.length > 0) {
                    SET_NAV_ACTIVE(NAV_MENU_LIST.value.length - 1)
                }
            }
        }

        /**
         * 当视频播放不了的时候，例如403，这里需要对这些视频做出替换，
         * 直接删除会涉及到一个索引的问题
         * 采用替换的方案，页面中会存在相同的视频id
         * 首先要找到 videoIds 中 在数据中的最后一个索引。
         * @constructor
         * @param videoIndex
         * @param tabIndex
         */
        const REPLACE_NAV_MENU_LIST = (videoIndex: number, tabIndex: number) => {
            const activeItem = NAV_MENU_LIST.value[tabIndex].vList[videoIndex]
            const newActiveItem = Object.assign({}, activeItem, {
                customRetryCount: 2,
            })
            NAV_MENU_LIST.value[tabIndex].vList.splice(videoIndex, 1, newActiveItem)
        }

        /**
         * 如果通过删除对应视频的话，涉及到一个索引问题，需要删除的视频在当前视频后面还是前面，如果是删除之前的视频，还需要重新设置 playVideoIndex
         * 太复杂
         * @constructor
         * @param videoIndex
         * @param tabIndex
         */
        const REMOVE_NAV_MENU_LIST = (videoIndex: number, tabIndex: number) => {
            const activeList = NAV_MENU_LIST.value[tabIndex].vList
            const lastIndex = activeList.length - 1
            if (lastIndex > videoIndex) {
                NAV_MENU_LIST.value[tabIndex].vList.splice(videoIndex, 1)
            }
        }
        return {
            NAV_ACTIVE,
            SET_NAV_ACTIVE,
            NAV_MENU_LIST,
            UPDATE_NAV_MENU_LIST_LIST, // 更新导航菜单列表中某个栏目的 videoList，
            NAV_ACTIVE_ON_CHANGE, // 对菜单切换做出响应。处理相关的数据。有数据不处理，没有数据，调用 REQ_GET_NAV_MENU_LIST_ITEM
            SET_NAV_MENU_LIST_ITEM,
            REQ_GET_NAV_MENU_LIST_ITEM, // 异步设置 导航菜单中的 videoList 及其分页等数据，
            REQ_SET_NAV_MENU_LIST, // 异步请求 导航菜单列表。最外围的数据
            SET_VIDEO_PLAY_INDEX, // 设置视频播放的索引值。
            REFRESH_NAV_MENU_LIST_ITEM, //@todo 刷新导航菜单列表中的某个栏目，暂时未用到
            LOAD_MORE_NAV_MENU_LIST_LIST, // 加载更多导航菜单列表中的某个栏目,
            REQ_ALL_NAV_MENU_LIST_LIST, // 更新菜单中所有栏目的videoList 数据，
            REPLACE_NAV_MENU_LIST,
            REMOVE_NAV_MENU_LIST,
            SET_NEED_DEL_PLAY_INDEX,
            NEED_DEL_PLAY_NAV_INDEX,
            NEED_DEL_PLAY_INDEX,
        }
    },
    {
        persist: {
            paths: [],
            storage: localStorage,
        },
    },
)
