const _toString = Object.prototype.toString

export const toRawType = (value: any) => _toString.call(value).slice(8, -1).toLowerCase()
// 提取一个函数来检查字段是否存在
export const hasRequiredFields = (data: object, fields: string[]): boolean => {
    return fields.every((field) => Object.prototype.hasOwnProperty.call(data, field))
}

/**
 * 确保输入值具有有效的CSS单位(rem或px)。
 * 如果值已经包含` rem `或` px `，则原样返回。
 * 如果值是一个没有单位的数字或字符串，则追加` rem `。
 * 如果输入为空或false，则返回空字符串。
 * @param  value —要规范化的值(可以是字符串或数字)。
 * @returns  CSS单元或空字符串的规范化值。
 */
export const ensureCSSUnit = (value: string | number): string => {
    // Convert the value to a string if it's a number
    const valueStr = String(value).trim()
    // Regular expression to check if the value already contains a valid unit
    const unitPattern = /^(?:\d+|\d*\.\d+)(rem|px)$/i
    // Return the value as-is if it contains a valid unit
    if (unitPattern.test(valueStr)) {
        return valueStr
    }
    // Append 'rem' if the value is not empty or just whitespace
    if (valueStr) {
        return `${valueStr}rem`
    }
    // Return an empty string if the value is empty or falsy
    return ''
}

export const goldFormat = (val: any): any => {
    if (typeof val !== 'number') {
        return val
    }
    // 后台的拿到的值需要除以 100
    const unit = 100
    return val / unit
}

/**
 * 格式化时长。显示多少分多少秒。目前没有超过一个小时的。
 * @param duration
 */
export const durationFormat = (duration: number) => {
    const hour = Math.floor(duration / 3600)
    const minute = Math.floor(duration / 60)
    const second = Math.floor(duration % 60)
    if (hour > 0) {
        return `${hour}:${minute}:${second}`
    }
    return `${minute}:${second}`
}

/**
 * 节流，一定时间内，多次调用，只执行一次。
 * 例如：onMounted 与 onActivated 都会触发，但是只执行一次。
 * lodash的 throttle 实际上并不是一种节流，而是把回调函数放到一个队列里，然后按照一定的时间间隔执行。
 * @todo 加过来再想想，发现，应该是同一页面中，有多个组件，多个组件内部调用了 throttle，相当于是多个实例。
 * @param callback
 * @param wait
 */
export const myThrottle = <T extends (...args: any[]) => any>(callback: T, wait: number = 0): ((...args: Parameters<T>) => void) => {
    let timer: ReturnType<typeof setTimeout> | null = null

    return function (...args: Parameters<T>) {
        if (timer === null) {
            callback(...args)
            timer = setTimeout(() => {
                timer = null
            }, wait)
        }
    }
}

export const myDebounce = <T extends (...args: any[]) => void>(fn: T, delay: number = 0): ((this: any, ...args: Parameters<T>) => void) => {
    let timeout: NodeJS.Timeout
    return function (this: any, ...args: Parameters<T>) {
        clearTimeout(timeout)
        timeout = setTimeout(() => fn.apply(this, args), delay)
    }
}

/**
 * https://d3juk0l4mepbxb.cloudfront.net 如果有跨域，要换成 /video
 * @param videoPath
 */
export const formatterVideoPath = (videoPath: string) => {
    return videoPath
    // const mode = import.meta.env.VITE_ENV
    // if (mode === 'development') {
    //     return videoPath.replace('https://v.slive.top', '/video')
    // }
    // return videoPath
    //
    // return videoPath.replace('https://d3juk0l4mepbxb.cloudfront.net', '/video')
}
/**
 * 通过视频链接的后缀，获取他的type类型，
 * 在我们项目中，一般是指定。application/x-mpegURL，这段代码，主要是为了在开发环境中，使用本链接的时候。
 * @param url
 */
export const getMimeTypeFromVideoUrl = (url: string): string => {
    const extension = url.split('.').pop()?.toLowerCase() // 获取后缀并转为小写

    switch (extension) {
        case 'mp4':
            return 'video/mp4'
        case 'webm':
            return 'video/webm'
        case 'ogg':
            return 'video/ogg'
        case 'm3u8':
            return 'application/x-mpegURL'
        case 'mov':
            return 'video/quicktime'
        case 'avi':
            return 'video/x-msvideo'
        case 'wmv':
            return 'video/x-ms-wmv'
        default:
            return '' // 返回空字符串或其他默认值，表示未知类型
    }
}
// 默认值映射
const defaultValues = {
    string: '',
    number: 0,
    boolean: false,
    object: {},
    array: [] as any[],
} as const // 使用 as const 确保类型为字面量类型

// 导出生成默认值的函数
export const generateDefaults = <T>(): T => {
    const defaultObject: Partial<T> = {} as Partial<T>

    for (const key in defaultValues) {
        const valueType = defaultValues[key as keyof typeof defaultValues]

        // 使用类型守卫确保正确分配类型
        if (Array.isArray(valueType)) {
            defaultObject[key as keyof T] = defaultValues.array as any // 明确指定类型
        } else if (typeof valueType === 'string') {
            defaultObject[key as keyof T] = '' as any // 使用具体类型
        } else if (typeof valueType === 'number') {
            defaultObject[key as keyof T] = 0 as any // 使用具体类型
        } else if (typeof valueType === 'boolean') {
            defaultObject[key as keyof T] = false as any // 使用具体类型
        } else {
            defaultObject[key as keyof T] = {} as any // 使用具体类型
        }
    }

    return defaultObject as T
}

/**
 * 将光标位置设置到指定元素的末尾
 * @param element - 需要设置光标的 HTMLElement
 */
export function setCursorToEnd(element: HTMLElement) {
    const range = document.createRange()
    const selection = window.getSelection()
    if (selection) {
        range.selectNodeContents(element)
        range.collapse(false) // false表示光标在末尾
        selection.removeAllRanges()
        selection.addRange(range)
    }
}
