import { defineStore } from 'pinia'
import { nextTick, onMounted, ref } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import IScroll from 'iscroll/build/iscroll-probe'
import { ChatListItem } from '@/api/message/type'
import { IAwsPayload, IAwsPayloadMessage } from '@/aws/type'
import { reqReadNotice, reqGetNoticeStatus } from '@/api/message'
import { useUserStore } from '@/store'
const useMessageStore = defineStore('message', () => {
    const userStore = useUserStore()
    // 是否有新的系统通知消息
    const HAS_NEW_NOTICE_MSG = ref(false)
    const SET_NEW_NOTICE_MSG = (val: boolean) => {
        HAS_NEW_NOTICE_MSG.value = val
        reqReadNotice({
            channelType: 'notice',
        }).then((r) => {
            console.log('通知已读', r)
        })
    }
    // 是否有新的关注消息
    const HAS_NEW_FOLLOW_MSG = ref(false)
    const SET_NEW_FOLLOW_MSG = (val: boolean) => {
        HAS_NEW_FOLLOW_MSG.value = val
        reqReadNotice({
            channelType: 'fens',
        }).then((r) => {
            console.log('通知已读', r)
        })
    }
    const allMessages = ref<IAwsPayloadMessage>({})
    // 当前在和哪个用户聊天userArn
    const currentChatRoomUserArn = ref('')
    //todo 当前聊天室的通道id, 只与聊天室相关，和用户无关。
    // 这里先注释，改造成功后再放出来。
    const CHAT_CHANNEL_ARN = ref('')
    const CLEAR_CHAT_CHANNEL_ARN = () => {
        CHAT_CHANNEL_ARN.value = ''
    }
    const SET_CHAT_CHANNEL_ARN = (arn: string) => {
        CHAT_CHANNEL_ARN.value = arn
    }
    const bs = ref<IScroll>({} as IScroll)
    // 当前聊天室的处理。
    const currentChatRoomHandler = (payload: IAwsPayload) => {
        // 表示当前正在聊天的房间和接收信息的房间是一致的。
        nextTick(() => {
            const lastId = payload?.MessageId || ''
            //todo 这个没看懂，refresh是表示什么意思？scrollToElement看上去感觉是滚动到最底，但是实践中发现不是这么一回事
            if (lastId) {
                bs.value.refresh && typeof bs.value.refresh === 'function' && bs.value.refresh()
                bs.value.scrollToElement && typeof bs.value.scrollToElement === 'function' && bs.value.scrollToElement('#chat' + lastId, 0, true, true)
            }
        })
    }

    const SET_AWS_MESSAGES = (payload: IAwsPayload) => {
        if (payload?.Sender?.Arn && allMessages.value[payload.Sender.Arn]) {
            allMessages.value[payload.Sender.Arn].ChannelArn = payload.ChannelArn
            // allMessages.value[payload.Sender.Arn].NextToken = null // 这个是做什么的。
            allMessages.value[payload.Sender.Arn].ChannelMessages.push(payload)
        } else {
            if (payload?.Sender?.Arn) {
                allMessages.value[payload.Sender.Arn] = {
                    ChannelMessages: [payload],
                    ChannelArn: payload.ChannelArn,
                    NextToken: null,
                }
            } else {
                // 表示当前通道，是第一次接受到对方消息。暂时不处理，
                // 这种情况下，在 /chat 页面，基本上不存在。只要 /chat页面不存在。就没关系，可以不处理 allMessages. 但是需要对 FIRST_RECEIVED_ROOM_LIST 做处理
                // FIRST_RECEIVED_ROOM_LIST 的处理，不在这里。
                //   在 /message 页面刷新后就会出现。
            }
        }

        // 表示当前正在聊天的房间和接收信息的房间是一致的。
        if (currentChatRoomUserArn.value === payload?.Sender?.Arn) {
            currentChatRoomHandler(payload)
        }
        SET_FIRST_RECEIVED_ROOM_LIST(payload)
        // 对 ROOM_LIST 做处理
    }
    // 首次接收消息的房间。
    const FIRST_RECEIVED_ROOM_LIST = ref<ChatListItem[]>([])
    // 聊天室列表
    const ROOM_LIST = ref<ChatListItem[]>([])
    const SET_FIRST_RECEIVED_ROOM_LIST = (payload: IAwsPayload) => {
        let metadata = null
        if (payload.Metadata) {
            try {
                metadata = JSON.parse(payload.Metadata)
            } catch (err) {
                metadata = null
            }
        }
        // Metadata 存在，说明是第一次进来。他可能会是一个系统消息。
        console.log('Metadata', metadata)
        if (metadata && typeof metadata === 'object' && metadata.channelType === 'system') {
            return
        }
        // 如果是系统通知类的，需要根据 messageType 做不同的处理
        // "at"      //  @人通知
        // "collect" //  收藏通知
        // "like"    //  点赞通知
        // "follow"  //  关注通知
        // "comment" //  评论通知
        // "reply"   //  回复通知
        const noticeTypes = ['at', 'like', 'comment', 'reply']
        if (metadata && typeof metadata === 'object' && metadata.channelType === 'notice') {
            const messageType = metadata.messageType
            if (messageType === 'follow') {
                HAS_NEW_FOLLOW_MSG.value = true
            }
            if (noticeTypes.includes(messageType)) {
                HAS_NEW_NOTICE_MSG.value = true
            }
            return
        }

        const index = ROOM_LIST.value.findIndex((item) => item.userArn === payload?.Sender?.Arn)
        // 当数据不存在时，是很难造一个数据出来的。缺少 headSrc， nickname
        console.log('index', index)
        if (index !== -1) {
            ROOM_LIST.value[index].lastMessageTimestamp = payload.LastUpdatedTimestamp
        } else {
            // 表示这个人从未联系过。需要通知相对就的页面做出更新。方式1：通过 bus刷新，方式2：建立 一个 empty_room_list, 当页面检测到有空房间时，主动调用接口请求。
            // 方式1的一个不好的地方，当我不在 /message页面时，无法监听，这个时候，在 tabbar 上页面无法得到未读消息。
            const item: ChatListItem = {
                channelArn: payload.ChannelArn,
                headSrc: '',
                lastMessageTimestamp: payload.LastUpdatedTimestamp,
                readMarkerTimestamp: '',
                nickname: '',
                userArn: payload?.Sender?.Arn || '',
                userId: Number(payload?.Sender?.Name),
                isTop: 0,
            }
            console.log('FIRST_RECEIVED_ROOM_LIST', FIRST_RECEIVED_ROOM_LIST.value)
            FIRST_RECEIVED_ROOM_LIST.value.push(item)
        }
    }
    /**
     * 只会在 /message 中调用 ，请求到 通道列表后，代表 通道列表是最新的，所以，不存在首次接收消息的通道
     * @param list
     * @constructor
     */
    const SET_CHAT_ROOM_LIST = (list: ChatListItem[]) => {
        ROOM_LIST.value = list
        FIRST_RECEIVED_ROOM_LIST.value = []
    }
    onMounted(() => {
        if (!userStore.token || !userStore.MY_USER_INFO.id) {
            return
        }
        reqGetNoticeStatus().then((res) => {
            const channelData = res.data.channelData || []
            channelData.forEach((item) => {
                if (res.code !== 0) {
                    return
                }

                if (item.channelType === 'notice') {
                    // 状态 0=未读 1=已读 未读，表示有，就是true， 所以要取反
                    HAS_NEW_NOTICE_MSG.value = !item.status
                }
                if (item.channelType === 'fens') {
                    // 状态 0=未读 1=已读 未读，表示有，就是true，所以要取反
                    HAS_NEW_FOLLOW_MSG.value = !item.status
                }
            })
        })
    })
    /**
     * 重新发送文本，应用场景，发送消息失败。例如：网络不好的情况，或者其他情况。
     * 发送失败后，允许重新发送，这时，需要将对应的消息，从原本的位置删除，然后再发一次。
     * 删除的动作放在这里，避免到处都是
     * @param payload
     * @constructor
     */
    // const RESEND_TEXT = (payload: any) => {}
    return {
        allMessages,
        bs,
        currentChatRoomUserArn, // 当前聊天用户userArn
        SET_AWS_MESSAGES,
        ROOM_LIST,
        FIRST_RECEIVED_ROOM_LIST,
        SET_CHAT_ROOM_LIST,
        SET_FIRST_RECEIVED_ROOM_LIST,
        CHAT_CHANNEL_ARN,
        CLEAR_CHAT_CHANNEL_ARN,
        SET_CHAT_CHANNEL_ARN,
        HAS_NEW_NOTICE_MSG,
        HAS_NEW_FOLLOW_MSG,
        SET_NEW_NOTICE_MSG,
        SET_NEW_FOLLOW_MSG,
    }
})

export default useMessageStore
