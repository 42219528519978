import { ChannelMessagePersistenceType, ChannelMessageType, ChimeSDKMessagingClient, GetMessagingSessionEndpointCommand, ListChannelMessagesCommand, SendChannelMessageCommand } from '@aws-sdk/client-chime-sdk-messaging'
import prepareWebSocketUrl from '@/aws/defaultMessagingSession.ts'
import { useMessageStore } from '@/store'
import { nextTick } from 'vue'
import { CONTENT_TYPE } from '@/config/constant.ts'
import { IAwsPayload } from '@/aws/type'
class Aws {
    // private userArn: string = 'arn:aws:chime:eu-central-1:637423487706:app-instance/4834e339-a4b0-4fed-9642-8703121dc818/user/2'
    // private channelArn: string = 'arn:aws:chime:eu-central-1:637423487706:app-instance/4834e339-a4b0-4fed-9642-8703121dc818/channel/c7226fd5-74c9-4290-983e-08b0f689aaa5'
    static chimeClient: any = {
        region: 'eu-central-1', // 替换为你的区域
        credentials: {
            // accessKeyId: 'AKIAZI2LGRLNKXMN3WLV',
            // secretAccessKey: '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',
            accessKeyId: 'AKIAZI2LGRLNORHA7J3N',
            secretAccessKey: '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',
        },
    }
    static clients: any = new ChimeSDKMessagingClient(Aws.chimeClient)
    static socket: WebSocket | null = null

    /**
     * 清除通道。需要把 userInfo.value.channelArn 给重置为空，这一步，目前是在chat.vue中做的。
     * @todo 个人认为，这个channelArn应该放在 useMessageStore 中，而不是放在userInfo中。
     */
    static clearChannel() {
        useMessageStore().CLEAR_CHAT_CHANNEL_ARN()
    }
    static SET_FIRST_RECEIVED_ROOM_LIST(payload: IAwsPayload) {
        const messageStore = useMessageStore()
        messageStore.SET_FIRST_RECEIVED_ROOM_LIST(payload)
    }
    static chatMessageHandler(payload: IAwsPayload) {
        const messageStore = useMessageStore()
        if (messageStore.currentChatRoomUserArn === payload?.Sender?.Arn) {
            if (messageStore.currentChatRoomUserArn in messageStore.allMessages) {
                messageStore.allMessages[messageStore.currentChatRoomUserArn].ChannelMessages.push(payload)
                nextTick(() => {
                    const lastId = payload.MessageId
                    if (lastId) {
                        messageStore.bs?.refresh && messageStore.bs.refresh()
                        messageStore.bs?.scrollToElement && messageStore.bs.scrollToElement('#chat' + lastId, 0, true, true)
                    }
                })
            } else {
                messageStore.allMessages[messageStore.currentChatRoomUserArn].ChannelMessages = []
                messageStore.allMessages[messageStore.currentChatRoomUserArn].ChannelArn = payload.ChannelArn
                messageStore.allMessages[messageStore.currentChatRoomUserArn].ChannelMessages.push(payload)
                messageStore.allMessages[messageStore.currentChatRoomUserArn].NextToken = null
            }
        }
    }

    /**
     * 处理消息，接收到消息后，分多种情况做出处理。
     * 1. 删除的系统通知。例如：他人在聊天列表中把对应的聊天通道删除之后，这里需要同步把聊天通道Id给删除，再次发送消息时，应该重新走流程。
     * 2. 新瓣消息要有特殊的处理。
     * @param payload
     * @constructor
     */
    static MESSAGE_HANDLER(payload: IAwsPayload) {
        let metadata = null
        if (payload.Metadata) {
            try {
                metadata = JSON.parse(payload.Metadata)
            } catch (err) {
                metadata = null
            }
        }
        if (metadata && typeof metadata === 'object' && metadata.channelType === 'system') {
            const { messageType } = metadata
            if (messageType && messageType === 'clearChannel') {
                //     执行清除通道的逻辑。'
                Aws.clearChannel()
            }
        } else {
            Aws.SET_FIRST_RECEIVED_ROOM_LIST(payload)
        }
        Aws.chatMessageHandler(payload)
    }

    // constructor(userArn: string, channelArn: string) {
    //     this.userArn = userArn
    //     this.channelArn = channelArn
    //     // this.initClient()
    // }
    // initClient() {
    //     this.clients = new ChimeSDKMessagingClient(this.chimeClient)
    // }
    static async sendMessage(message: string, ChannelArn: string, userArn: string, ContentType: string = CONTENT_TYPE.TEXT) {
        const command = new SendChannelMessageCommand({
            ChannelArn: ChannelArn,
            ChimeBearer: userArn,
            Content: message,
            Persistence: ChannelMessagePersistenceType.PERSISTENT,
            Type: ChannelMessageType.STANDARD,
            ContentType: ContentType,
        })

        return await Aws.clients.send(command)
    }

    static async getListChannelMessages(ChannelArn: string, UserArn: string, NextToken: string | null = null, MaxResults: number = 50) {
        const params = {
            MaxResults: MaxResults,
            NextToken: NextToken,
            ChannelArn: ChannelArn,
            ChimeBearer: UserArn,
        }

        const command1 = new ListChannelMessagesCommand(params)

        try {
            const data = await Aws.clients.send(command1)
            console.log('listChannelMessages DATA ->', data)
            return data
        } catch (error) {
            console.error('listChannelMessages ERROR ->', error)
            return null
        }
    }

    static async fetchWebSocketEndpoint() {
        try {
            const command = new GetMessagingSessionEndpointCommand({})
            const response = await Aws.clients.send(command)
            return response.Endpoint?.Url as string
        } catch (error) {
            console.error('Error fetching WebSocket endpoint:', error)
            throw error
        }
    }

    static async connectWebSocket(userArn: string) {
        try {
            const websocketEndpoint = await Aws.fetchWebSocketEndpoint()
            // const websocketEndpoint = 'node001.eu-central-1.ws-messaging.chime.aws'
            const urls = await prepareWebSocketUrl(websocketEndpoint, userArn, Aws.chimeClient)
            Aws.socket = new WebSocket(urls)
            Aws.socket.onopen = () => {
                console.log('WebSocket connected')
            }
            Aws.socket.onmessage = (event) => {
                const data = JSON.parse(event.data)
                if ('Payload' in data) {
                    const payload = JSON.parse(data.Payload)
                    Aws.MESSAGE_HANDLER(payload)
                }
            }
            Aws.socket.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event.code} - ${event.reason}`)
            }
            Aws.socket.onerror = (error) => {
                console.error('WebSocket error:', error)
            }
            return Aws.socket
        } catch (error) {
            console.error('Error connecting to WebSocket:', error)
            throw error
        }
    }
}
export default Aws
