import { ExtendedPlayer } from '@/composables/types/videoTypes.ts'
import bus, { EventBusTypes } from '@/utils/bus.ts'
import { nextTick, onBeforeUnmount, onMounted, Ref, ref, toRefs, UnwrapRef } from 'vue'
import { useSystemStore } from '@/store'

/**
 * 处理视频全屏后的自定义控制功能。
 * 1. 在安卓手机上，全屏后无法监听点击事件，导致播放/暂停切换失效。
 * 2. 添加触摸事件来模拟点击，确保点击切换播放/暂停。
 * @param player - Video.js 播放器实例
 */
export const useVideoFullscreenControls = (player: Ref<UnwrapRef<ExtendedPlayer> | null>) => {
    const { IS_ANDROID } = toRefs(useSystemStore()) // 判断是否为安卓设备
    const videoVjsTechClickCount = ref(0) // 点击计数，用于控制点击次数

    const threshold = 100 // 滑动距离阈值，用于判断是否为点击事件

    // 播放与暂停切换
    const handleTogglePlayPause = () => {
        if (player.value) {
            if (player.value.paused()) {
                handlePlay()
            } else {
                handlePause()
            }
        }
    }

    const handlePlay = () => {
        try {
            player.value?.play()
        } catch (err) {
            console.error('播放失败，可能是视频未加载完成', err)
        }
    }

    const handlePause = () => {
        try {
            player.value?.pause()
        } catch (err) {
            console.error('暂停失败，可能是视频未加载完成', err)
        }
    }

    // 触摸事件信息
    const touchEventInfo = {
        startX: 0, // 触摸开始的坐标
        startY: 0,
        timer: null as ReturnType<typeof setTimeout> | null, // 用于定时器
    }

    /**
     * 触摸开始事件，记录触摸开始时的坐标
     * @param e - 触摸事件
     */
    const videoElTouchStart = (e: TouchEvent | MouseEvent) => {
        touchEventInfo.startX = 'clientX' in e ? e.clientX : e.touches[0].clientX
        touchEventInfo.startY = 'clientY' in e ? e.clientY : e.touches[0].clientY
    }

    /**
     * 触摸结束事件，判断是否为点击事件并处理播放/暂停切换
     * 1. 如果触摸事件在视频元素（<video>）上发生，并且触摸的起始位置与结束位置的差距小于设定的阈值，
     *    则认为是一次点击事件。
     * 2. 如果是点击事件，则切换播放/暂停。
     * 3. 为了防止误操作，使用计时器确保点击操作不被重复触发（每3秒后清空点击计数）。
     * 4. 通过点击计数来限制点击逻辑的处理，以避免唤醒控制条后的无效点击。
     * @param event - 触摸结束事件
     */
    const videoElTouchEnd = (event: TouchEvent) => {
        const endTouches = event.changedTouches // 获取触摸结束时的触摸点信息
        const target = event.target as HTMLElement // 事件的目标元素，即触摸的元素

        // 如果没有触摸结束事件，或者触摸的不是视频元素，直接返回
        if (!endTouches.length || target.tagName !== 'VIDEO') return

        const endX = endTouches[0].clientX // 获取触摸结束时的 X 坐标
        const endY = endTouches[0].clientY // 获取触摸结束时的 Y 坐标
        const diffX = Math.abs(touchEventInfo.startX - endX) // 计算触摸起始和结束的 X 坐标差值
        const diffY = Math.abs(touchEventInfo.startY - endY) // 计算触摸起始和结束的 Y 坐标差值

        // 如果触摸的滑动距离大于阈值，则认为是滑动操作，而不是点击事件
        if (diffX > threshold || diffY > threshold) return

        // 通过点击计数来控制播放/暂停的切换
        if (videoVjsTechClickCount.value) {
            handleTogglePlayPause()
        }

        videoVjsTechClickCount.value++ // 增加点击计数

        // 如果已经有定时器存在，先清除旧的定时器，避免重复定时
        if (touchEventInfo.timer) {
            clearTimeout(touchEventInfo.timer)
        }

        // 设置一个定时器，在 3 秒后清空点击计数，避免误触发后续的播放/暂停切换
        touchEventInfo.timer = setTimeout(() => {
            if (!player.value?.paused()) {
                videoVjsTechClickCount.value = 0
            }
        }, 3000)
    }

    /**
     * 根据全屏状态添加或移除触摸事件监听
     * @param isFullscreen - 是否进入全屏
     */
    const toggleTouchListeners = (isFullscreen: boolean) => {
        // 只在安卓设备上处理，全屏状态下才添加触摸事件监听
        if (!player.value || !IS_ANDROID.value) return

        if (isFullscreen) {
            player.value.on('touchstart', videoElTouchStart)
            player.value.on('touchend', videoElTouchEnd)
        } else {
            player.value.off('touchstart', videoElTouchStart)
            player.value.off('touchend', videoElTouchEnd)
        }
    }

    const isInitListened = ref(false)
    /**
     * 初始化全屏时的触摸事件监听器。
     * 监听视频播放器的全屏变化，进入全屏时添加触摸事件监听，退出全屏时移除。
     */
    const setupFullscreenListeners = async () => {
        await nextTick()
        if (isInitListened.value || !player.value) return
        isInitListened.value = true
        player.value.on('fullscreenchange', () => {
            const isFullscreen = !!player.value?.isFullscreen()
            toggleTouchListeners(isFullscreen)
        })
    }
    const handleDispose = () => {
        isInitListened.value = false
    }

    // 事件总线处理函数
    const videoBusEventHandlers = {
        [EventBusTypes.VIDEO.REQUEST_FULLSCREEN]: setupFullscreenListeners,
        [EventBusTypes.VIDEO.MOUNTED]: setupFullscreenListeners,
        [EventBusTypes.VIDEO.CHANGE_SOURCE]: setupFullscreenListeners,
        [EventBusTypes.VIDEO.QUALITY_CHANGE]: setupFullscreenListeners,
        [EventBusTypes.VIDEO.DISPOSE]: handleDispose,
    }

    onMounted(() => {
        // 监听视频相关事件

        Object.entries(videoBusEventHandlers).forEach(([eventType, handler]) => {
            bus.on(eventType, handler)
        })
    })

    onBeforeUnmount(() => {
        // 卸载事件监听
        isInitListened.value = false
        Object.entries(videoBusEventHandlers).forEach(([eventType, handler]) => {
            bus.off(eventType, handler)
        })
    })

    return {}
}

//
// export const aaa = (player: Ref<UnwrapRef<ExtendedPlayer> | null>, localData: any) => {
//     const videoMimeType = computed(() => {
//         if (import.meta.env.MODE !== 'development' || !localData.src) {
//             return 'application/x-mpegURL'
//         }
//         return getMimeTypeFromVideoUrl(localData.src) || 'application/x-mpegURL'
//     })
//     const qualitySelector = ref<any>(null)
//
//     const refs = () => {
//         // 画质选择功能，如果存在，就去更新。理论上，如果有需要，在创建的阶段就存在了，不会等到换源的时候再去创建。所以，这里只处理存在的情况
//         if (qualitySelector.value) {
//             qualitySelector.value?.updateQuality({
//                 videoPath: localData.videoInfo?.videoPath,
//                 urlHeight: localData.videoInfo?.urlHeight,
//                 currentQualityField: 'videoPath',
//                 type: videoMimeType.value,
//             })
//         }
//     }
//     const setQualitySelectorBtn = () => {
//         if (!player.value) {
//             return
//         }
//         qualitySelector.value = player.value?.getChild('controlBar')?.addChild(
//             'QualitySelectorButton',
//             {
//                 videoPath: localData.videoInfo?.videoPath,
//                 urlHeight: localData.videoInfo?.urlHeight,
//                 currentQualityField: 'videoPath',
//                 type: videoMimeType.value,
//             },
//             10,
//         )
//     }
//
//     const setQualitySelector3 = () => {
//         if (!player.value) {
//             return
//         }
//         // player.getChild('overlay')
//         console.log("player.getChild('overlay')", player.value.getChild('overlay'))
//         qualitySelector.value = player.value?.getChild('controlBar')?.addChild(
//             'QualitySelectorButtonTwo',
//             {
//                 videoPath: localData.videoInfo?.videoPath,
//                 urlHeight: localData.videoInfo?.urlHeight,
//                 currentQualityField: 'videoPath',
//                 type: videoMimeType.value,
//             },
//             10,
//         )
//     }
// }
