import { ref } from 'vue'
type ToggleValue = boolean | string | number
const useToggle = (firstVal: ToggleValue = false) => {
    const state = ref<ToggleValue>(firstVal)

    const toggle = (flag: ToggleValue) => {
        state.value = flag
    }

    return [state, toggle] as const
}
export default useToggle
