import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { ref } from 'vue'
import { allowMultipleToast, showLoadingToast, showToast, ToastWrapperInstance } from 'vant'
import { useUserStore } from '@/store'
import router from '@/router'
import i18n from '@/i18n/index.ts'
import { globReportLog } from '@/utils/log'
import { TLogCategory } from '@/api/user/type'

const request = axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_API,
    timeout: 10000,
})

const t = i18n.global.t
const reqLoading = ref<ToastWrapperInstance | null>(null)
const reqLoadingStartTime = ref(0)
const reqLoadingTimer = ref<NodeJS.Timeout | null>(null)
const reqLoadingUrls = ref<string[]>([])
allowMultipleToast()
const clearReqLoadingTimer = () => {
    if (reqLoadingTimer.value) {
        clearTimeout(reqLoadingTimer.value)
        reqLoadingTimer.value = null
    }
}
const startLoading = (configUrl?: string) => {
    if (reqLoading.value) {
        reqLoading.value?.close()
    }
    clearReqLoadingTimer()
    if (configUrl) {
        reqLoadingUrls.value.push(configUrl)
        reqLoadingUrls.value = [...new Set(reqLoadingUrls.value)]
    }
    reqLoadingStartTime.value = Date.now()
    reqLoading.value = showLoadingToast({
        message: t('common.loading_text'),
        forbidClick: true,
        duration: 0,
        className: 'global-op-loading',
    })
}
/**
 * 请求结束
 * @param isImmediate 是否立即执行
 */
const endLoading = (isImmediate?: boolean) => {
    clearReqLoadingTimer()
    if (!reqLoading.value) {
        return
    }
    const time = 500
    const timeDiff = Date.now() - reqLoadingStartTime.value
    if (timeDiff > time || isImmediate) {
        reqLoading.value?.close()
        reqLoadingUrls.value = []
    } else {
        reqLoadingTimer.value = setTimeout(() => {
            reqLoading.value?.close()
            reqLoadingUrls.value = []
        }, time - timeDiff)
    }
}
const handleErrorToast = (msg: string) => {
    endLoading(true)
    showToast({
        type: 'fail',
        message: msg,
        duration: 1500,
        className: 'global-op-toast',
    })
}
// 请求拦截
request.interceptors.request.use((config) => {
    const userStore = useUserStore()
    // 设置token
    const token = userStore.token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    if (Object.prototype.hasOwnProperty.call(config.headers, 'isShowLoading') && config.headers.isShowLoading) {
        startLoading(config.url)
    }
    return config
})

const getConfigData = (config: any) => {
    try {
        let configData = config.data
        if (!configData) {
            return {}
        }
        if (typeof configData === 'string' && configData.includes('{')) {
            // 如果是字符串，需要转成对象
            configData = JSON.parse(configData)
            return configData
        }
        return {}
    } catch (err) {
        return {}
    }
}
const getReportLogSubjectId = (headers: AxiosRequestHeaders) => {
    if (Object.prototype.hasOwnProperty.call(headers, 'reportLogSubjectId') && headers.reportLogSubjectId) {
        return headers.reportLogSubjectId
    } else {
        return ''
    }
}

interface IHeadersReportLogExtra {
    codeMatchType?: 'exclusion' | 'inclusion' // 表示 codeValues 里的内容是否需要上报
    codeValues?: number[] | string[]
    // @todo additional 暂未实现，目前这种使用场景不是很多，直接在api那处理。
    additional?: {
        // 检测请求参数。请求参数，倒是可以在 api 那就处理掉，
        request?: {
            [key: string]: any
        }
        // 检测响应数据，但是响应数据，返回的格式。不一致。{'data.data': 0}, 太复杂
        response?: {
            [key: string]: any
        }
    } // 其他额外的请求条件，只有满足这些条件时才会触发上报操作。
}

interface IMsgData {
    type: 'code' | 'status' // code 表示 内部的 code 码错误 ，status 表示的是 网络错误，接口没有走到后台去。
    code?: number
    msg: string
    [key: string]: any
}

const getReportLogInfo = (str: string): { type: TLogCategory; extra?: IHeadersReportLogExtra | null } => {
    try {
        if (str.includes(',extra=')) {
            const arr = str.split(',extra=', 2) // 按第一个下划线分割
            return {
                type: arr[0].trim() as TLogCategory, // type 是分割后的第一个部分
                extra: JSON.parse(arr[1] || '{}') as IHeadersReportLogExtra, // extra 是第二部分，JSON 解析，默认为空对象
            }
        } else {
            return {
                type: str as TLogCategory, // 如果没有下划线，则 type 为原始字符串
                extra: null, // extra 默认为空
            }
        }
    } catch (err) {
        console.error('获取异常类型失败', str, err)
        return {
            type: str as TLogCategory, // 如果没有下划线，则 type 为原始字符串
            extra: null, // extra 默认为空
        }
    }
}

/**
 * 判断完 codeMatchType 后，还需要判断 additional 中的条件，
 * 如果 additional 存在，并且 additional 中的条件完全满足 requestRes 中的条件，则表示需要上报。
 * 应用场景，首页无数据，只有page=1时，menuId=2, data.length === 0 才需要上报。
 * @todo 需求太复杂，page是请求参数里的，data是返回 的数据。如果要实现这一功能，需要做区分
 * @param additional
 * @param requestRes
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-expect-error
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkedReportLogAdditional = (additional: { [key: string]: any } = {}, requestRes: IMsgData) => {
    let additionalIsChecked = true

    // 判断是否需要上报，检查 additional 中的条件
    if (additional && typeof additional === 'object' && !Array.isArray(additional) && Object.keys(additional).length > 0) {
        for (const key in additional) {
            if (Object.prototype.hasOwnProperty.call(additional, key)) {
                const expectedValue = additional[key]

                // 判断 requestRes 中是否有对应的字段
                if (key in requestRes) {
                    const actualValue = requestRes[key]

                    // 如果 expectedValue 是一个数组或对象
                    if (Array.isArray(expectedValue) || typeof expectedValue === 'object') {
                        // 如果 expectedValue 是一个数字，表示要检查 requestRes[key] 的长度
                        // 这里的值可能是一个 ‘0’
                        if (typeof expectedValue === 'number') {
                            // 表示当前字段是个数组，如果这个字段缺失，不会进到这里，
                            // 所以这里直接判断数组长度是否小于 expectedValue， 如果小于，则表示需要上报。
                            // 比如说，length=0，表示数组为空，需要上报。那这里直接用 不等于，不等于就表示有数据，不需要上报。
                            // 那如果需要小于某个值呢？这种场景不存在，如果存在，那就特殊处理。
                            if (Array.isArray(actualValue) && actualValue.length !== expectedValue) {
                                additionalIsChecked = false
                                break // 如果数组长度不满足要求，直接结束
                            }
                            if (typeof actualValue === 'object' && Object.keys(actualValue).length !== expectedValue) {
                                additionalIsChecked = false
                                break // 如果对象的键数不满足要求，直接结束
                            }
                        }

                        // 如果 expectedValue 是一个布尔值，表示要检查字段是否存在
                        // 如果存在就报，不存在就不报。不存在不会进到这里来。
                        if (typeof expectedValue === 'boolean') {
                            if (expectedValue && !(key in requestRes)) {
                                additionalIsChecked = false
                                break // 如果字段不存在且 expectedValue 是 true，直接结束
                            }
                        }
                    } else {
                        // 如果值不是数组或对象，则直接比较实际值与期望值
                        if (actualValue !== expectedValue) {
                            additionalIsChecked = false
                            break // 如果不匹配，直接结束
                        }
                    }
                } else {
                    // 如果 requestRes 中没有对应的字段，表示当前字段允许上报，继续检查其他条件
                    // 如果存在就报，不存在就不报。
                    // 能够进到这里的字段，本身就不存在，所以，这里只需要判断 expectedValue 是否为 true，如果为 true，则表示需要上报。这里缺失，
                    // 如果 expectedValue 为ture， 表示这个字段必须存在，一般而言，但是进到这一步，后台的这个字段，不会存在。
                    // 后台的这个字段，都会是一个对象或者数组。如果后台这个同名字段
                    // @todo 目前还没有这种情景
                    if (typeof expectedValue === 'boolean' && expectedValue) {
                        additionalIsChecked = false
                        break // 如果字段不存在且 expectedValue 是 true，直接结束
                    }
                }
            }
        }
    }

    // 最终返回 additionalIsChecked，表示是否所有条件都满足
    return additionalIsChecked
}
// 判断是否需要上报。
const reportLogChecker = (reportLogMsg: string, requestRes: IMsgData) => {
    if (!requestRes.type || requestRes.type !== 'code') {
        return true
    }
    const requestResCode = requestRes.code ? Number(requestRes.code) : 0
    const reportLogInfo = getReportLogInfo(reportLogMsg)
    const reportLogInfoExtra = reportLogInfo.extra || {}
    const { codeMatchType, codeValues = [] } = reportLogInfoExtra
    const newCodeValues = codeValues.map((item) => Number(item))
    // 表示code码检测通过，如果 codeMatchType 不存在，则默认为 true, 表示需要上报。默认不为0表示上报。
    let isCheckedCode = true
    // 当 codeMatchType 为 exclusion 时，表示，要排除的code码，如果后台返回的code码，被包含，就不需要上报了，这是一个取反的操作。
    if (codeMatchType === 'exclusion') {
        isCheckedCode = !newCodeValues.includes(requestResCode)
    }
    // 当 codeMatchType 为 inclusion 时，表示，要包含的code码，如果后台返回的code码，被包含，就需要上报了，否则就不需要上报了。
    if (codeMatchType === 'inclusion') {
        isCheckedCode = newCodeValues.includes(requestResCode)
    }
    // 如果根据code码不需要上报，就直接返回不需要上报，如果需要上报，再去看 additional 中的条件。
    return isCheckedCode
}
/**
 *
 * @param config
 * @param requestRes 错误消息
 * @param content 错误的额外参数
 */
const sendReportLog = (config: AxiosRequestConfig, requestRes: IMsgData, content?: object) => {
    // reportLogType 有两种形式，
    // 异常类型，是一个字符串，
    // 异常类型，是一个对象字符串 他会以 'MessageSend,IHeadersReportLogExtra' 的形式体现，注意，IHeadersReportLogExtra是一个 JSON 字符串。

    if (Object.prototype.hasOwnProperty.call(config.headers, 'reportLogType') && config.headers?.reportLogType) {
        const isNeedReportLog = reportLogChecker(config.headers.reportLogType, requestRes)
        if (!isNeedReportLog) {
            return
        }
        const subjectId = getReportLogSubjectId(config.headers as AxiosRequestHeaders)
        const data = {}
        if (subjectId) {
            Object.assign(data, { subjectId })
        }
        if (content) {
            Object.assign(data, content)
        }

        const reportLogInfo = getReportLogInfo(config.headers.reportLogType)

        globReportLog(
            {
                message: requestRes.msg,
                ...data,
            },
            reportLogInfo.type,
            getConfigData(config),
        )
    }
}
// 请求响应
request.interceptors.response.use(
    (response) => {
        const userStore = useUserStore()
        const { data, config } = response
        if (Object.prototype.hasOwnProperty.call(config.headers, 'isShowLoading') && config.headers.isShowLoading) {
            endLoading()
        } else if (reqLoading.value) {
            console.log('存在未关闭的请求loading，1秒后再来看。因为有延时', reqLoadingUrls.value)
        }

        if (data.code === 0) {
            return data
        }

        // TOKEN过期, 不需要有提示。
        // token 过期之后，用户可能不会登录，也有可能会换个账号登录，也有可能会重新登录
        // 所以这里不仅仅是清除token，还应该在登录的时候，
        if (data.code === 40000) {
            try {
                endLoading(true)
                userStore.CLEAR_TOKEN()
                const history = window.history
                const historyLen = history.length
                const historyState = historyLen > 1 ? history.state : {}
                const loginRedirect = Object.keys(historyState).length > 0 && historyState.current ? historyState.current : '/home'
                // 理论上来说，这里的 loginRedirect 不会是 '/login' 等, 如果出现，说明login中的接口，有问题。需要后台改
                router
                    .replace({
                        path: '/login',
                        query: { redirect: loginRedirect },
                    })
                    .then(() => userStore.CLEAR_TOKEN())
            } catch (err) {
                console.error('处理token过期的逻辑出错', err)
            }
            return data
        }
        sendReportLog(
            config,
            {
                type: 'code',
                ...data,
            },
            { code: data.code },
        )

        const isShowErrorTip = Object.prototype.hasOwnProperty.call(response.config.headers, 'isShowErrorTip') && response.config.headers.isShowErrorTip
        if (isShowErrorTip) {
            handleErrorToast(data.msg)
        }
        return data
    },
    (error) => {
        const { config, message, stack } = error
        sendReportLog(
            config,
            {
                type: 'status',
                msg: message,
            },
            { stack: stack, code: error.code },
        )

        if (error.code === 'ECONNABORTED') {
            // showFailToast(i18n.global.t('request.e401'))
            handleErrorToast(i18n.global.t('request.timeout'))
            return Promise.reject(error)
        }
        let msg = ''
        let status = 504
        try {
            // 请求超时，可能没有 response
            status = error.response.status
        } catch (err) {
            status = 504
        }

        switch (status) {
            case 401:
                msg = i18n.global.t('request.e401')
                break
            case 403:
                msg = i18n.global.t('request.e403')
                break
            case 404:
                msg = i18n.global.t('request.e404')
                break
            case 500:
                msg = i18n.global.t('request.e500')
                break
            default:
                msg = i18n.global.t('request.error')
        }
        handleErrorToast(msg)
        return Promise.reject(error)
    },
)

export default request
