// start 视频上的属性。
import { ILocalData, IndexResponseItemData } from '@/composables/types/videoTypes.ts'
export const getVideoProperty = () => {
    return {
        autoplay: true,
        preload: 'auto',
        controls: false,
        errorDisplay: false,
        loop: true,
        loadingSpinner: false,
        fluid: true,
        x5VideoPlayerType: 'h5-page',
        x5VideoPlayerFullscreen: false,
        webkitPlaysinline: true,
        x5Playsinline: true,
        playsinline: true,
        aspectRatio: '16:9',
        responsive: true,
        fullscreen: {
            muted: false, // 全屏后是否静音
            enterOnRotate: true, // Enter fullscreen mode on rotating the device in landscape
            exitOnRotate: true, // Exit fullscreen mode on rotating the device in portrait
            alwaysInLandscapeMode: true, // Always enter fullscreen in landscape mode even when device is in portrait mode (works on chromium, firefox, and ie >= 11)
            iOS: true, //Whether to use fake fullscreen on iOS (needed for displaying player controls instead of system controls)
        },
        controlBar: {
            playToggle: false,
            FullscreenToggle: false,
        },
    }
}

export const getVideoUseData = (): ILocalData => {
    return {
        videoTargetId: '#app',
        showVideo: false,
        posterImgVisible: false,
        videoDomId: 'videoId' + Date.now(),
        src: '',
        poster: '',
        isMuted: true, // 是否静音,
        videoWidth: 0, // 视频宽
        videoHeight: 0, // 视频高
        videoDomHeight: 0,
        isPlaying: true, // 是否播放中
        isLandscape: false, // 横屏
        objectFitCls: 'fill',
        isFullscreen: false, // 全屏
        currentTime: 0,
        isError: false,
        errorCode: 0,
        errorMessage: '',
        isLoading: false,
        isBuffering: false,
        qualityField: 'videoPath',
        videoInfo: null as IndexResponseItemData | null,
    }
}

/**
 * 状态码映射
 * 这里目前只是用来做日志上报，所以，暂时不需要多语言处理。
 * @todo 在 useVideoPlayer 中用到了这里的值，但是，并没有在 ui 体现出来，后续如果需要，再做多语言处理
 */
export const videoFetchHeadStatusMessages: { [key: number]: string } = {
    200: '', // 请求成功，不需要文案。
    403: '服务器拒绝访问，可能资源已过期或跨域问题',
    404: '请求的资源未找到',
    500: '服务器内部错误，可能是视频文件问题',
    502: '网关错误，无法访问资源',
    503: '服务不可用，服务器过载或维护中',
}

// 是否自动切换到下一个视频
// 最终的方案确定后，这里需要删除。
export const autoSwiperNextVideo = false
