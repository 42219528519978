import FingerprintJS from '@fingerprintjs/fingerprintjs'

const USER_ID_KEY = 'Slive-device-id'

function storeUserIdInCookie(uniqueId: string): void {
    document.cookie = `${USER_ID_KEY}=${uniqueId}; path=/; max-age=31536000`
}

const getFingerprintId = async (): Promise<string> => {
    const fpPromise = FingerprintJS.load()
    const fp = await fpPromise
    const result = await fp.get()
    return result.visitorId
}

export async function fetchOrCreateDeviceId(): Promise<string | null> {
    const localStorageUserId = localStorage.getItem(USER_ID_KEY)
    if (localStorageUserId) {
        return localStorageUserId
    }

    const uniqueId = await getFingerprintId()
    localStorage.setItem(USER_ID_KEY, uniqueId) // Store in localStorage

    if (!window.indexedDB) {
        return uniqueId
    }

    return handleIndexedDB(uniqueId)
}

const handleIndexedDB = async (uniqueId: string): Promise<string | null> => {
    const dbRequest = indexedDB.open('sliveDataBase', 1)

    return new Promise<string | null>((resolve) => {
        dbRequest.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result
            db.createObjectStore('sliveUsers', { keyPath: 'id' })
        }

        dbRequest.onsuccess = async (event) => {
            const db = (event.target as IDBOpenDBRequest).result
            const transaction = db.transaction('sliveUsers', 'readwrite')
            const store = transaction.objectStore('sliveUsers')

            const userIdRecord: any = await getUserIdRecord(store)

            if (!userIdRecord) {
                await store.put({ id: uniqueId })
                storeUserIdInCookie(uniqueId)
                resolve(uniqueId)
            } else {
                resolve(userIdRecord.id)
            }
        }

        dbRequest.onerror = (event) => {
            console.error('数据库打开失败:', event)
            resolve(null)
        }
    })
}

const getUserIdRecord = async (store: IDBObjectStore): Promise<IDBValidKey | undefined> => {
    return new Promise<IDBValidKey | undefined>((resolve) => {
        const request = store.get(USER_ID_KEY)
        request.onsuccess = () => resolve(request.result)
        request.onerror = () => resolve(undefined)
    })
}
