import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { IndexResponseItemData } from '@/api/home/type'

export const useGoldPurchaseVideo = defineStore(
    'gold_purchase_video',
    () => {
        // 当前正在看的视频
        const ACTIVE_VIEW_VIDEO = ref<IndexResponseItemData>({} as IndexResponseItemData)
        const SET_ACTIVE_VIEW_VIDEO = (video: IndexResponseItemData) => {
            ACTIVE_VIEW_VIDEO.value = video
        }
        // const REQ_GET_ACTIVE_VIEW_VIDEO_LIST = () => {}
        return {
            ACTIVE_VIEW_VIDEO,
            SET_ACTIVE_VIEW_VIDEO,
        }
    },
    {
        persist: {
            storage: localStorage,
        },
    },
)
