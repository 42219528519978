import { defineStore } from 'pinia'
import { ref } from 'vue'
export const useVideoMountedStore = defineStore('videoMounted', () => {
    const storeVideoContainerDomId = ref<string>('')
    let timer: ReturnType<typeof setTimeout> | null = null
    const SAVE_VIDEO_CONTAINER_DOM_ID = (id: string) => {
        const domId = id.replace('#', '')
        storeVideoContainerDomId.value = ''
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
        if (!domId) {
            return
        }
        timer = setTimeout(() => {
            storeVideoContainerDomId.value = domId
        }, 500)
    }
    const GET_VIDEO_CONTAINER_DOM_ID = () => {
        return storeVideoContainerDomId.value.replace('#', '')
    }

    return {
        storeVideoContainerDomId,
        SAVE_VIDEO_CONTAINER_DOM_ID,
        GET_VIDEO_CONTAINER_DOM_ID,
    }
})
