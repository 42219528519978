import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { RouteMeta } from 'vue-router'

export const useSystemStore = defineStore(
    'system',
    () => {
        // 定义状态
        const isMobile = ref(true)
        const IS_IOS = ref(false)
        const IS_ANDROID = ref(false)
        const IS_MIUI = ref(false)
        const IS_OPPO = ref(false)
        const appWidth = ref(window.innerWidth)
        const appHeight = ref(0)

        const storeHtmlFontSize = computed(() => {
            return appWidth.value / 375
        })
        /**
         * rem 单位转换成 px
         * @param val
         */
        const storeRemToPx = (val: string | number) => {
            const newVal = String(val)
            const newStr = newVal.replace('rem', '').replace('px', '')
            return Number(newStr) * storeHtmlFontSize.value
        }
        const excludeNames = ref<string[]>([]) // 需要排除的页面名称
        const INCLUDE_ROUTE_NAMES = ref<any[]>([
            'home', // 首页
        ]) // 需要包含的页面名称

        const SET_INCLUDE_ROUTE_NAMES = (route: { meta: RouteMeta; name: string | symbol | undefined }) => {
            const routeMeta = route.meta || {}
            if (!routeMeta.keepAlive || !route?.name) {
                return
            }
            if (!INCLUDE_ROUTE_NAMES.value.includes(route.name)) {
                INCLUDE_ROUTE_NAMES.value.push(route.name)
            }
        }

        // 定义方法
        const judgmentMobile = () => {
            const userAgent = window.navigator.userAgent
            isMobile.value = !!userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            IS_IOS.value = /iPad|iPhone|iPod/.test(userAgent)
            IS_ANDROID.value = /android/i.test(userAgent)
            IS_OPPO.value = /OPPO/i.test(userAgent)
            IS_MIUI.value = userAgent.includes('mi ') || userAgent.includes('xiaomi') || userAgent.includes('redmi') || userAgent.includes('XiaoMi')
            appWidth.value = isMobile.value ? window.innerWidth : (window.innerHeight * 414) / 844
            return isMobile.value
        }

        type ExcludeNamesType = {
            type: string
            value: string
        }
        const updateExcludeNames = (val: ExcludeNamesType) => {
            if (val.type === 'add') {
                if (!excludeNames.value.find((v: string) => v === val.value)) {
                    excludeNames.value.push(val.value)
                }
            } else {
                const resIndex = excludeNames.value.findIndex((v) => v === val.value)
                if (resIndex !== -1) {
                    excludeNames.value.splice(resIndex, 1)
                }
            }
        }

        return {
            isMobile,
            IS_IOS,
            IS_ANDROID,
            IS_MIUI,
            IS_OPPO,
            appWidth,
            appHeight,
            storeHtmlFontSize,
            storeRemToPx,
            excludeNames,
            judgmentMobile,
            updateExcludeNames,
            INCLUDE_ROUTE_NAMES,
            SET_INCLUDE_ROUTE_NAMES,
        }
    },
    {
        persist: {
            paths: ['isMobile', 'IS_IOS', 'IS_ANDROID', 'IS_MIUI'],
            storage: localStorage,
        },
    },
)
