import { createApp } from 'vue'
import App from '@/App.vue'
import 'virtual:svg-icons-register'
import globalComponent from '@/components'
import '@/styles/index.scss'
import 'swiper/css'
import '@/assets/font/iconfont.css'
import 'vant/es/toast/style'
// import 'vant/es/notify/style'
import 'vant/es/dialog/style'
import router from './router'
// import '@/directive/auth.ts'

import '@vant/touch-emulator'
import { Lazyload } from 'vant'
import pinia, { useUserStore } from '@/store'
import i18n from '@/i18n/index.ts'
import { register } from 'swiper/element/bundle'

// register Swiper custom elements
register()
// import Vconsole from 'vconsole'
// let isVconsoleEnabled = false
// if (process.env.NODE_ENV === 'development' && import.meta.env.VITE_APP_HAS_CONSOLE_LOG === 'true') {
//     new Vconsole()
//     isVconsoleEnabled = true
// }

const app = createApp(App)
app.config.globalProperties.$t = i18n.global.t
// app.config.globalProperties.VITE_APP_HAS_CONSOLE_LOG = isVconsoleEnabled
// 记录从页面加载到首屏出现的时长，这里首先标记第一次进来的时长。
// 记录完后给他清掉。
app.config.globalProperties.$startTime = Date.now()
const customClick = (binding: any) => {
    const userStore = useUserStore()
    return (event: any) => {
        event.stopPropagation()
        if (userStore.token) {
            // 判断条件
            // 正常触发点击事件
            binding.value()
        } else {
            // 已经成功拦截点击事件
            router.push('/login')
        }
    }
}
app.directive('auth', {
    created(el, binding) {
        el.addEventListener('click', customClick(binding))
    },
    // 离开一定要销毁卸载
    unmounted(el) {
        el.removeEventListener('click', customClick)
    },
})
app.use(pinia)
app.use(router)
app.use(globalComponent)
app.use(Lazyload)
app.use(i18n)

// app.use(vConsole)

app.mount('#app')
const preventZoom = (e: WheelEvent | TouchEvent) => {
    if (e instanceof WheelEvent && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
    }
    if (e instanceof TouchEvent) {
        e.preventDefault()
    }
}
const addEventListeners = () => {
    window.addEventListener('wheel', preventZoom, { passive: false })
    window.addEventListener('touchmove', preventZoom, { passive: false })
}

const removeEventListeners = () => {
    window.removeEventListener('wheel', preventZoom)
    window.removeEventListener('touchmove', preventZoom)
}
addEventListeners()
// 确保在页面卸载时移除监听器
window.addEventListener('beforeunload', removeEventListeners)
