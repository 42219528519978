import { onMounted } from 'vue'
import { useSystemStore } from '@/store'

export function useReset() {
    const resetVhAndPx = () => {
        const isMobile = useSystemStore().judgmentMobile()

        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        const designWidh: number = 375
        const clientWidth = isMobile ? window.innerWidth : (window.innerHeight * 414) / 844
        const app = document.body
        if (!isMobile) app.style.width = clientWidth + 'px'
        // designWidh设计图的宽度，把屏幕宽度分成375份，在设计图上1rem等于1px
        document.documentElement.style.fontSize = clientWidth / designWidh + 'px'
    }
    onMounted(() => {
        resetVhAndPx()
        const systemStore = useSystemStore()
        if (!systemStore.appHeight) systemStore.appHeight = window.innerHeight
        // 监听resize事件 视图大小发生变化就重新计算1vh的值
        window.addEventListener('resize', () => {
            resetVhAndPx()
        })
    })
}
