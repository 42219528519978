import request from '@/utils/request.ts'
import type { Pagination, Result } from '@/api'
import { HotListData, SearchParams, SearchTagListItem, SearchTopListData, SingleVideoParams, UserHotItem } from '@/api/search/type'
import type { IndexResponseItemData } from '@/api/home/type'

/**
 * 获取热搜列表
 * @param data
 */
export const reqHotList = (data: { userId: number }) => {
    return request.post<any, Result<HotListData>>('/api/home/hotList', data)
}
/**
 * 综合关键词搜索
 * @param data
 */
export const reqSearchTopList = (data: SearchParams) => {
    return request.post<any, Result<SearchTopListData>>('/api/home/searchTopList', data)
}
/**
 * 搜索视频列表
 * @param data
 */
export const reqSearchVideoList = (data: SearchParams) => {
    return request.post<any, Result<Pagination<IndexResponseItemData[]>>>('/api/home/searchVideoList', data)
}

/**
 * 搜索用户列表
 * @param data
 */
export const reqSearchUserList = (data: SearchParams) => {
    return request.post<any, Result<Pagination<UserHotItem[]>>>('/api/home/searchUserList', data)
}

export const reqSearchTagList = (data: SearchParams) => {
    return request.post<any, Result<Pagination<SearchTagListItem[]>>>('/api/home/searchTagList', data)
}

/**
 * 获取单个视频
 * @param data
 */
export const reqSingleList = (data: SingleVideoParams) => {
    const newData = Object.assign(data, {
        videoId: Array.isArray(data.videoId) ? data.videoId : [data.videoId],
    })
    return request.post<any, Result<IndexResponseItemData[]>>('/api/home/singleVideo', newData)
}
