export const SIZE_NUM = 10
export const PAGE_NUM = 1
export const SEARCH_CACHE_COUNT = 100

export const NAV_KEY = {
    FOLLOWING: 'following',
    FOR_YOU: 'forYou',
}

export const BUS_TYPE = {
    ATTENTION: 'ATTENTION',
    LIKE: 'LIKE',
    COMMENT: 'COMMENT',
    COLLECT: 'COLLECT',
    MUTED: 'MUTED',
}

// 回复层级
export const REPLY_LEVEL = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
}
// 评论点赞状态0=未点赞，未踩 1=已点赞 2=已踩
export const COMMIT_LIKE_STATUS = {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
}

// 视频点赞状态0=未点赞 1=已点赞
export const VIDEO_LIKE_STATUS = {
    ZERO: 0,
    ONE: 1,
}
// 视频点赞状态0=未点赞 1=已点赞
export const VIDEO_FOLLOW_STATUS = {
    ZERO: 0,
    ONE: 1,
}
// 视频收藏状态0=未收藏 1=已收藏
export const VIDEO_COLLECT_STATUS = {
    ZERO: 0,
    ONE: 1,
}

export const SEARCH_ACTIVE = {
    ZERO: 0, // Top
    ONE: 1, // Videos
    TWO: 2, // Users
    THREE: 3, // HashTags
}

export const CONTENT_TYPE = {
    TEXT: 'text/plain',
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    GIF: 'image/gif',
}
