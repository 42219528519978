import request from '@/utils/request'
import type { PageAndSize, Pagination, Result } from '@/api'
import {
    CommentListForm,
    CommentListResponseData,
    DoCollectForm,
    DoCommentForm,
    DoCommentLikeForm,
    DoFollowForm,
    DoFollowResp,
    DoLikeForm,
    IndexFormData,
    IndexMenu,
    IndexResponseData,
    UserAtListItemData,
    ILookVideo,
    IUserLookBuyResp,
    IBuyVideoParam,
    IndexResponseItemData,
    ILookVideoRes,
    TAreaCodeItem,
} from '@/api/home/type'
import { globReportLog } from '@/utils/log.ts'
/**
 * 获取首页顶部菜单
 */
const startTime = 1000
let topMenuListReqTimer: ReturnType<typeof setTimeout> | null = null
const clearTopMenuListReqTimer = () => {
    if (topMenuListReqTimer) {
        clearTimeout(topMenuListReqTimer)
        topMenuListReqTimer = null
    }
}

// 模拟数据
const simulatedTopMenuData = {
    code: 0,
    msg: 'ok',
    data: {
        menuItem: [
            { id: 1, menuName: 'Following' },
            { id: 2, menuName: 'For You' },
        ],
    },
}
let reqTopMenuData: Result<IndexMenu> | null = null
export const reqTopMenuList = (): Promise<Result<IndexMenu>> => {
    clearTopMenuListReqTimer()
    return new Promise((resolve, reject) => {
        // 服务器是国外的，有个时候出现联不上服务器的情况，topMenuList又是首页的入口接口，这个接口如果没有数据，整个首页都是空白的。
        // 为了防止连不服务器的情况，这里做个延时器的设置，如果在规定时间没有响应，直接返回静态数据。
        // 如果当天已经请求过，并且成功了，那就直接返回之前成功的数据。
        topMenuListReqTimer = setTimeout(() => {
            resolve(reqTopMenuData || (simulatedTopMenuData as Result<IndexMenu>))
        }, startTime)
        request
            .post<any, Result<IndexMenu>>('/api/home/topMenuList')
            .then((res) => {
                reqTopMenuData = res
                resolve(res)
            })
            .then((err) => {
                reject(err)
            })
            .finally(() => {
                clearTopMenuListReqTimer()
            })
    })
}

/**
 * 首页视频列表接口
 * 这里有个设置，正常来说，这个接口不会失败，如果失败，就表示异常，需要上报。
 * 另外，请求 推荐 视频列表时，如果他的返回数据，是个空的，也需要报错。但是，这里会有个问题，只有第一页的请求才需要。
 * @param data
 */
export const reqIndexList = (data: IndexFormData) => {
    const { menuId, page: requestPage } = data
    return new Promise<Result<IndexResponseData>>((resolve, reject) => {
        request
            .post<any, Result<IndexResponseData>>('/api/home/index', data, {
                headers: {
                    reportLogType: 'IndexNoData',
                },
            })
            .then((res) => {
                resolve(res)
                const resData = res.data
                if (res.code === 0) {
                    if (menuId === 2 && requestPage === 1 && resData.data.length === 0) {
                        globReportLog(
                            {
                                message: '首页无数据',
                            },
                            'IndexNoData',
                        )
                    }
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

/**
 * 关注、取消关注接口
 * @param data
 */
export const reqDoFollow = (data: DoFollowForm) => {
    return request.post<any, Result<DoFollowResp>>('/api/home/doFollow', data, {
        headers: {
            isShowErrorTip: true,
            reportLogType: 'Follow',
            reportLogSubjectId: data.userId,
        },
    })
}
/**
 * 点赞取消点赞接口
 * @param data
 */
export const reqDoLike = (data: DoLikeForm) => {
    return request.post<any, Result<any>>('/api/home/doLike', data, {
        headers: {
            isShowErrorTip: true,
            reportLogType: 'DoLike',
            reportLogSubjectId: data.videoId,
        },
    })
}
/**
 * 收藏取消收藏接口
 * @param data
 */
export const reqDoCollect = (data: DoCollectForm) => {
    return request.post<any, Result<any>>('/api/home/doCollect', data, {
        headers: {
            isShowErrorTip: true,
            reportLogType: 'Collect',
            reportLogSubjectId: data.videoId,
        },
    })
}

/**
 * 获取评论列表接口
 * @param data
 */
export const reqCommentList = (data: CommentListForm) => {
    return request.post<any, Result<CommentListResponseData>>('/api/home/commentList', data)
}

/**
 * 发布评论接口
 * @param data
 */
export const reqDoComment = (data: DoCommentForm) => {
    return request.post<any, Result<any>>('/api/home/doComment', data, {
        headers: {
            isShowErrorTip: true,
            reportLogType: 'Comment',
            reportLogSubjectId: data.videoId,
        },
    })
}

/**
 * 点赞评论接口
 * @param data
 */
export const reqDoCommentLike = (data: DoCommentLikeForm) => {
    return request.post<any, Result<any>>('/api/home/doCommentLike', data, {
        headers: {
            isShowErrorTip: true,
            reportLogType: 'CommentLike',
            reportLogSubjectId: data.commentId,
        },
    })
}

// /api/home/delComment
export const reqDelComment = (data: { commentId: number }) => {
    return request.post<any, Result<any>>('/api/home/delComment', data, {
        headers: {
            isShowErrorTip: true,
            reportLogType: 'DelComment',
            reportLogSubjectId: data.commentId,
        },
    })
}
/**
 * 获取at朋友列表
 * @param data
 */
export const reqUserAtList = (data: PageAndSize) => {
    return request.post<any, Result<Pagination<UserAtListItemData[]>>>('/api/home/userAtList', data)
}
/**
 * 获取电话区号选择
 * @param data
 */
export const reqAssemblySelect = (data: any) => {
    return request.post<any, Result<{ assembly: { GetAreaCodeSelect: TAreaCodeItem[] } }>>('/api/home/assemblySelect', { assembly: data })
}

export const reqLookVideo = (data: ILookVideo) => {
    // 签名(参数请求进行加密) 将以上参数进行AE4(ECB、pkcs7padding、128位、输出base64)方式加密,
    // 密钥格式为(BeginTime+Long(10位时间戳)_Long(5位,不足5位前面补0)
    // Date.now().toString().substr(0, 10).len
    // 例如：beginTime 1723011782  开始时间戳
    // long 10,
    // 密钥为：beginTime + 10 + '_00010'
    return request.post<any, Result<ILookVideoRes>>('/api/home/lookVideo', data, {
        headers: {
            reportLogType: 'HomeRendTime',
        },
    })
}
/**
 * 消费金币购买视频
 * @param data
 */
export const reqUserLookBuy = (data: { videoId: number }) => {
    return request.post<any, Result<IUserLookBuyResp>>('/api/home/userLookBuy', data, {
        headers: {
            isShowErrorTip: true,
            reportLogType: 'VideoBuy',
            reportLogSubjectId: data.videoId,
        },
    })
}

export const reqBuyVideoList = (data: IBuyVideoParam) => {
    return request.post<any, Result<Pagination<IndexResponseItemData[]>>>('/api/home/buyVideoList', data)
}
