import { ref } from 'vue'
export const useState = (value: any) => {
    const state = ref(value ? value : '')

    /**
     *
     * @param flag state 并不一定是一个布尔值，可能是一个字符串，也有可能是一个数字, 从代码上来看，也有可能是一个对象。
     */
    const toggle = (flag: any) => {
        state.value = flag
    }

    return [state, toggle]
}
