import { useUserConfig } from '@/store'
import { TLogCategory } from '@/api/user/type'
import { globReportLog } from '@/utils/log.ts'
import { Ref, ref, watch, UnwrapRef, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { ExtendedPlayer, ILocalData, IndexResponseItemData } from '@/composables/types/videoTypes.ts'
import bus, { EventBusTypes } from '@/utils/bus.ts'
import { myDebounce } from '@/utils'
import { fetchVideoHeadData } from '@/utils/video.ts'
import { videoFetchHeadStatusMessages } from '@/composables/videoHooks/const.ts'

/**
 * 视频加载日志记录器
 * @param player
 * @param localData 视频播放器中关于视频及播放的一些本地数据
 */
export const useVideoLoadLogger2 = (player: Ref<UnwrapRef<ExtendedPlayer> | null>, localData: ILocalData) => {
    const { USER_CONFIG } = useUserConfig()
    const maxLoadTime = 1000 // 默认1秒超时时间
    const maxFailureCooldown = 5000 // 设置失败报告的冷却时间（例如 5秒内不能重复发送失败报告）
    const videoStartTimes: Map<number, number> = new Map() // 存储每个视频的加载开始时间
    const videoLoadStatuses: Map<number, 'loading' | 'failed' | 'completed' | 'skipped'> = new Map() // 存储每个视频的加载状态
    const videoFailureTimes: Map<number, number> = new Map() // 存储每个视频失败报告的最后发送时间
    const videoInfoMap: Map<
        number,
        {
            id: number
            videoDesc: string
            videoPath: string
            urlHeight: string
            completeDuration?: number
        }
    > = new Map() // 存储精简版视频信息

    let currentVideoId: number | null = null // 当前视频 ID

    // 使用 ref 来响应式管理 minLoadingTime
    const minLoadingTime = ref(USER_CONFIG.minLoadingTime || maxLoadTime)

    const reportLogTypeMap: Record<string, string> = {
        failed: 'VideoView',
        loading: 'VideoLoad', // 假设 'loading' 是需要记录的状态 VideoSkipped
        completed: 'VideoLoad', // 假设 'completed' 也是需要记录的状态
    }

    /**
     * 发送视频加载报告
     * @param videoId
     * @param status
     * @param error
     */
    const sendVideoReportLog = (videoId: number, status: 'loading' | 'failed' | 'completed' | 'skipped', error?: string | { [key: string]: any }) => {
        if (videoId === null) return

        const startTime = videoStartTimes.get(videoId)
        if (!startTime) return // 如果没有加载开始时间，退出

        const elapsedTime = Date.now() - startTime // 计算加载消耗的时间，单位为ms
        const reportLogType = <TLogCategory>reportLogTypeMap[status] || 'VideoLoad' // 默认使用 'VideoLoad' 作为备选

        // 获取视频信息
        const videoInfo = videoInfoMap.get(videoId)

        // 生成日志消息
        // 生成日志消息
        let message = ''
        switch (status) {
            case 'completed':
                message = `完成，耗时 ${elapsedTime}ms`
                break
            case 'skipped':
                message = `跳过，耗时 ${elapsedTime}ms`
                break
            case 'failed':
                message = `失败，耗时 ${elapsedTime}ms`
                break
            default:
        }
        const reportLogContent = {
            timeout: elapsedTime,
            status: status,
            videoId: videoId,
        }
        if (error && typeof error === 'string') {
            Object.assign(reportLogContent, {
                error: error,
            })
        }
        if (error && typeof error === 'object') {
            Object.assign(reportLogContent, {
                ...error,
            })
        }

        // 如果视频信息存在，添加到日志内容中
        if (videoInfo) {
            Object.assign(reportLogContent, {
                qualityField: localData.qualityField === 'videoPath' ? '低清' : '高清',
                duration: videoInfo?.completeDuration,
                videoDesc: videoInfo?.videoDesc,
            })
        }

        // 发送日志报告
        clearStatus(videoId)
        globReportLog(
            {
                message,
                subjectId: videoId,
                reportAt: startTime,
                content: JSON.stringify(reportLogContent),
            },
            reportLogType,
        )
    }

    const clearStatus = (videoId: number) => {
        videoStartTimes.delete(videoId)
        videoLoadStatuses.delete(videoId)
        videoInfoMap.delete(videoId) // 删除视频信息
    }

    /**
     * 开始报告视频加载的时间
     * @param videoInfo
     */
    const videoReportLogStart = (videoInfo: IndexResponseItemData | null) => {
        // 如果有上一个视频正在加载，需要处理其状态
        if (currentVideoId !== null && videoLoadStatuses.get(currentVideoId) === 'loading') {
            handleSkipOrTimeout(currentVideoId)
        }
        const videoId = videoInfo?.id || 0

        // 更新当前视频 ID
        if (videoId !== currentVideoId) {
            currentVideoId = videoId // 设置当前视频 ID
        }
        // 保存当前视频信息（只保存所需字段）
        if (videoInfo) {
            videoInfoMap.set(videoId, {
                id: videoInfo.id,
                videoDesc: videoInfo.videoDesc,
                videoPath: videoInfo.videoPath,
                urlHeight: videoInfo.urlHeight,
                completeDuration: videoInfo?.completeDuration,
            }) // 保存精简版视频信息
        }

        // 记录视频开始加载的时间，并设置状态为 "loading"
        videoStartTimes.set(videoId, Date.now())
        videoLoadStatuses.set(videoId, 'loading')

        // 清除之前的失败报告时间
        videoFailureTimes.delete(videoId)
        fetchUrlHandler(videoId)
    }

    // 处理视频加载完成
    const handleVideoLoadSuccess = (videoId: number) => {
        const startTime = videoStartTimes.get(videoId)
        if (!startTime) return

        // 计算视频加载时长
        const loadTime = Date.now() - startTime

        // 如果加载超过了预设时间，发送报告
        if (loadTime >= minLoadingTime.value) {
            videoLoadStatuses.set(videoId, 'completed')
            sendVideoReportLog(videoId, 'completed')
        } else {
            clearStatus(videoId)
        }
    }

    // 处理视频加载失败
    const handleVideoLoadFailure = (videoId: number, error?: string | { [key: string]: any }) => {
        const lastFailureTime = videoFailureTimes.get(videoId)
        const currentTime = Date.now()

        // 如果上次失败报告时间距离当前时间不到冷却时间（例如5秒），则不发送
        if (lastFailureTime && currentTime - lastFailureTime < maxFailureCooldown) {
            return
        }

        // 设置视频加载失败状态，并记录失败报告发送时间
        videoLoadStatuses.set(videoId, 'failed')
        videoFailureTimes.set(videoId, currentTime)
        sendVideoReportLog(videoId, 'failed', error)
    }

    /**
     * 处理用户跳过或者视频加载超时的情况
     * - 如果视频加载时间超过最大预设加载时间（即超时）
     * - 如果用户跳过了视频（即视频未加载完成）
     * 这个方法，只在内部调用，不导出
     * @param videoId 这个id实际上是指上一个视频的id
     */
    const handleSkipOrTimeout = (videoId: number) => {
        const startTime = videoStartTimes.get(videoId)
        if (!startTime) return

        // 计算视频加载时长
        const elapsedTime = Date.now() - startTime

        // 如果视频加载时间超过最大预设时间，或者视频未加载完成（用户跳过）
        if (elapsedTime > minLoadingTime.value && videoLoadStatuses.get(videoId) === 'loading') {
            videoLoadStatuses.set(videoId, 'skipped') // 设置状态为 "skipped"
            sendVideoReportLog(videoId, 'skipped') // 发送跳过报告
        }
    }

    /**
     * 发送视频加载错误报告日志 例如：401
     * @todo 检测他的url,虽然能够成功，但是，我的需求是 视频资源切片的一个检测。
     */
    const fetchUrlHandler = (videoId: number) => {
        if (!videoId) {
            return
        }
        const videoInfo = videoInfoMap.get(videoId)
        if (!videoInfo) {
            console.error(`Video info not found for videoId: ${videoId}`)
            return
        }
        // fetch(videoInfo.videoPath, { method: 'HEAD' })
        // fetchVideoHeadData(videoInfo.videoPath)
        // 在这里可以根据 videoInfo 发起请求，或进行其他操作
        // 示例：发起视频加载请求
        fetchVideoHeadData(videoInfo.videoPath)
            .then((res) => {
                const { status: resStatus, type: resType } = res
                // 表示这个url跨域了，可能是有效期快到了，也有可能确实是没有。
                if (resStatus === 200) {
                    return
                }
                const errorMsg = videoFetchHeadStatusMessages[resStatus] || `未知错误：${resStatus}`
                handleVideoLoadFailure(videoId, {
                    url: res.url,
                    resStatus,
                    resType,
                    msg: errorMsg,
                })
            })
            .catch((error) => {
                // 捕获网络请求失败或其他异常
                handleVideoLoadFailure(videoId, {
                    msg: `请求失败：${error.message}`,
                    url: videoInfo.videoPath,
                })
            })
    }

    const isListened = ref(false)
    const playerListerInit = () => {
        nextTick(() => {}).then(() => {
            if (!player.value || isListened.value) {
                return
            }
            isListened.value = true
            player.value.off('loadstart', playerLoadStartHandler)
            player.value.on('loadstart', playerLoadStartHandler)
            player.value.on('playing', () => {
                handleVideoLoadSuccess(localData.videoInfo!.id)
            })
            /**
             * fetchUrlHandler 中也会捕获异常，但是。这里会先进入，只要这里能够触发，日志上报，会采用这里的。
             */
            player.value.on('error', () => {
                try {
                    const playerError = player.value?.error()
                    if (playerError) {
                        handleVideoLoadFailure(localData?.videoInfo!.id, {
                            errorCode: playerError.code,
                            errorMessage: playerError.message,
                        })
                    }
                } catch (err) {
                    console.error('捕获视频异常失败', err)
                }
            })
        })
    }
    const playerLoadStartHandler = () => {
        videoReportLogStart(localData.videoInfo)
    }
    watch(
        () => player.value,
        () => {
            playerListerInit()
        },
    )

    const busEventListerHandler = myDebounce(() => {
        playerListerInit()
    })
    const handleDispose = () => {
        isListened.value = false
    }
    const videoBusEventHandlers = {
        [EventBusTypes.VIDEO.MOUNTED]: busEventListerHandler,
        [EventBusTypes.VIDEO.CHANGE_SOURCE]: busEventListerHandler,
        [EventBusTypes.VIDEO.QUALITY_CHANGE]: busEventListerHandler,
        [EventBusTypes.VIDEO.DISPOSE]: handleDispose,
    }
    onMounted(() => {
        Object.entries(videoBusEventHandlers).forEach(([eventType, handler]) => {
            bus.on(eventType, handler)
        })
    })
    onBeforeUnmount(() => {
        Object.entries(videoBusEventHandlers).forEach(([eventType, handler]) => {
            bus.off(eventType, handler)
        })
    })

    return {
        videoReportLogStart: videoReportLogStart, // 启动视频加载开始的方法
        handleVideoLoadSuccess: handleVideoLoadSuccess, // 处理视频加载成功的方法
        handleVideoLoadFailure: handleVideoLoadFailure, // 处理视频加载失败的方法
    }
}
