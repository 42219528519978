<script lang="ts" setup>
import { useReset } from '@/config/reset'
import { useSystemStore, useUserStore } from '@/store'
import { watch, ref, onMounted, onBeforeUnmount, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import routes from '@/router/routes.ts'
import VideoPlayer from '@/components/video/VideoPlayer.vue'
const systemStore = useSystemStore()
const userStore = useUserStore()
const transitionName = ref('go')
const route = useRoute()
import bus from '@/utils/bus'
useReset()
const pathMatches = (path1: string, path2: string) => {
    const normalize = (path: string) => path.replace(/:\w+/g, ':param')
    return normalize(path1) === normalize(path2)
}

watch(
    () => route.path,
    (to, from) => {
        console.log(`Route changed from ${from} to ${to}`)
        let noAnimation = ['/', '/home', '/me', '/message', '/discover']
        if (noAnimation.includes(from) && noAnimation.includes(to)) {
            transitionName.value = ''
            return
        }
        const toDepth = routes.findIndex((route) => pathMatches(route.path, to))
        const fromDepth = routes.findIndex((route) => pathMatches(route.path, from))
        transitionName.value = toDepth > fromDepth ? 'go' : 'back'
    },
)

// const shouldExclude = (Component) => {
//     if (Component) {
//         return systemStore.excludeNames.includes(Component.type.name)
//     }
// }
const generateKey = (Component: any) => {
    // console.log('app.vue Component', Component)
    if (Component) {
        // console.log('app.vue Component', Component)
        return Component.type.name + (Component.key ? '-' + Component.key : '')
    }
    return Date.now()
}
const isMounted = ref(false)
onMounted(() => {
    isMounted.value = true
    // document.getElementById('app').style.background = '#fff'
})
onBeforeUnmount(() => {
    bus.clear()
})

import Vconsole from 'vconsole'
const isVconsoleEnabled = ref(false)
if (process.env.NODE_ENV === 'development' && import.meta.env.VITE_APP_HAS_CONSOLE_LOG === 'true') {
    new Vconsole()
    isVconsoleEnabled.value = true
}
watchEffect(() => {
    if (isVconsoleEnabled.value) {
        return
    }
    const viteUserWhitelist = import.meta.env.VITE_APP_USER_WHITE_LIST
    const userWhitelist = viteUserWhitelist?.split(',').map(Number).filter(Boolean) || []
    if (userWhitelist.length && userWhitelist.includes(userStore.MY_USER_INFO.id)) {
        new Vconsole()
        isVconsoleEnabled.value = true
    }
})
// const userWhitelist = [1000484, 1000479, 1000478, 1000476, 1000457, 1000445, 1000432, 1000418, 1000413, 1000385, 1000322]
// if (process.env.NODE_ENV === 'production') {
//     if (userWhitelist) {
//     }
// }
</script>

<template>
    <router-view v-slot="{ Component }">
        <transition :name="transitionName">
            <keep-alive :include="systemStore.INCLUDE_ROUTE_NAMES" :exclude="systemStore.excludeNames">
                <component :is="Component" :key="generateKey(Component)" />
            </keep-alive>
        </transition>
    </router-view>
    <VideoPlayer v-if="isMounted"></VideoPlayer>
    <!--    <router-view v-slot="{ Component }">-->
    <!--        <div v-if="Component">-->
    <!--            <transition :name="transitionName">-->
    <!--                <keep-alive :include="systemStore.INCLUDE_ROUTE_NAMES">-->
    <!--                    <component :is="Component" :key="generateKey(Component)" />-->
    <!--                </keep-alive>-->
    <!--            </transition>-->
    <!--        </div>-->
    <!--        <div v-else>-->
    <!--            <p>No component to render</p>-->
    <!--        </div>-->
    <!--    </router-view>-->
</template>

<style lang="scss">
* {
    user-select: none;
}
#app {
    height: 100%;
    width: 100%;
    position: relative;
    font-size: 14rem;
    color: var(--Ga-1);
    background: #000;
    margin: 0 auto;
    clip-path: inset(0);
}

.go-enter-from {
    transform: translate3d(100%, 0, 0);
}

.back-enter-to,
.back-enter-from,
.go-enter-to,
.go-leave-from {
    transform: translate3d(0, 0, 0);
}

.go-leave-to {
    transform: translate3d(-100%, 0, 0);
}

.go-enter-active,
.go-leave-active,
.back-enter-active,
.back-leave-active {
    transition: all 0.3s;
}

.back-enter-from {
    transform: translate3d(-100%, 0, 0);
}

.back-leave-to {
    transform: translate3d(100%, 0, 0);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
