<script setup lang="ts">
import { toRefs, ref, onMounted } from 'vue'
// import SvgIcon from '@/components/SvgIcon/index.vue'
import { useSystemStore, useToggleStore } from '@/store'
const toggleStore = useToggleStore()
const { isMobile, IS_IOS, IS_ANDROID, IS_MIUI, appWidth } = toRefs(useSystemStore())
import { useVideoPlayer } from '@/composables/videoHooks/useVideoPlayer.ts'
const { player, videoEl, localData, videoProperty } = useVideoPlayer()
import { useVideoProgress } from '@/composables/videoHooks/useVideoProgress.ts'
import { useVideoLoadLogger2 } from '@/composables/videoHooks/useNewVideoLoadLogger.ts'
import { useVideoQuality } from '@/composables/videoHooks/useVideoQuality'
import { useVideoFullscreenControls } from '@/composables/videoHooks/useVideoFullscreenControls.ts'
import { useFetchUrlHandler } from '@/composables/videoHooks/useVideoRetry_01.ts'
const { moveType, duration, currentTime, progressWidth, controlRef, setProgress, sliderStart, controlMove, controlEnd, formatTime } = useVideoProgress(player, localData)
useVideoLoadLogger2(player, localData)
useVideoQuality(player, localData, videoEl)
useVideoFullscreenControls(player)
if (process.env.NODE_ENV === 'development') {
    useFetchUrlHandler()
}

const isDevDebuggerMode = ref(false)
// 开发模式下，敲击屏幕12下，加载出来。

const showVideoDebuggerInfo = () => {
    if (process.env.NODE_ENV !== 'development') return
    let count = 0
    let countStartTime = 0
    document.addEventListener('click', () => {
        count++
        if (!countStartTime) {
            countStartTime = Date.now()
        }
        const timeElapsed = Date.now() - countStartTime
        if (timeElapsed < 7000) {
            // 12 seconds in milliseconds
            if (count === 7) {
                isDevDebuggerMode.value = true
            } else if (count > 11) {
                count = 0
                countStartTime = 0
                isDevDebuggerMode.value = false
            }
        } else {
            count = 0
            countStartTime = 0
            isDevDebuggerMode.value = false
        }
    })
}

onMounted(() => {
    showVideoDebuggerInfo()
})
</script>

<template>
    <Teleport :to="localData.videoTargetId">
        <div
            v-if="localData.showVideo"
            class="video-container"
            :class="[
                localData.isFullscreen ? '' : localData.objectFitCls,
                {
                    'object-fit': !toggleStore.commitShow && !localData.isLandscape && !localData.isFullscreen,
                    'is-miui': IS_MIUI,
                    'is-land-scape': localData.isLandscape || IS_MIUI,
                    'video-wrap-is-mobile': isMobile,
                    'video-wrap-is-pc': !isMobile,
                    'video-wrap-is-ios': IS_IOS,
                    'video-wrap-is-android': IS_ANDROID,
                    'video-not-fullscreen': !localData.isFullscreen,
                    'video-is-fullscreen': localData.isFullscreen,
                    'video-is-commit': toggleStore.commitShow,
                },
            ]"
            style="position: relative; width: 100%; height: 100%"
            :style="{
                'max-width': appWidth + 'px',
            }"
        >
            <div v-if="isDevDebuggerMode" style="position: absolute; z-index: 9999; left: 0; top: 100px; padding: 20px; color: #fff; background: rgba(0, 0, 0, 0.8)">
                <div>object-fit: {{ localData.isFullscreen ? '' : localData.isLandscape ? 'cover' : 'contain' }}直接判断宽是不是大于高，</div>
                <div style="margin-top: 10px; color: red">object-fit2: {{ localData.objectFitCls }}; 当前采用的方案</div>
                <div style="margin-top: 5px; color: #bc4e7d">
                    object-fit2 的计算方法是这样的。
                    <div>(元素高 * 视频宽) / 视频高 > (设备宽 * 视频高) / 视频宽 ? 'contain' : 'cover',</div>
                </div>

                <div style="margin-top: 10px">宽：{{ localData.videoWidth }}</div>
                <div>高：{{ localData.videoHeight }}</div>
                <div>横屏状态： {{ localData.isLandscape }}</div>
                <div>宽是否大于高：{{ localData.videoWidth > localData.videoHeight }}</div>
                <div style="margin-top: 10px; color: #999">cover: 保持原有尺寸比例。保证替换内容尺寸一定大于容器尺寸.，宽度和高度至少有一个和容器一致</div>
                <div style="margin-top: 10px; color: #999">contain:保持原有尺寸比例。保证替换内容尺寸一定可以在容器里面放得下</div>
            </div>
            <video
                :id="localData.videoDomId"
                ref="videoEl"
                :poster="localData.poster"
                class="w-full video-js relative"
                :class="{
                    'is-ios': IS_IOS,
                    'is-android': IS_ANDROID,
                }"
                :muted="localData.isMuted"
                v-bind="videoProperty"
                :width="localData.videoWidth"
                :height="localData.videoHeight"
                :style="{
                    width: '100%',
                    height: '100%',
                    paddingTop: '0',
                    background: '#000',
                }"
            >
                <source type="application/vnd.apple.mpegurl" :src="localData.src" />
                <source type="application/x-mpegURL" :src="localData.src" />
                <source type="video/mp4" :src="localData.src" />
                <source type="video/webm" :src="localData.src" />
                <source type="video/ogg" :src="localData.src" />
            </video>
            <!--            <van-image-->
            <!--                :fit="!toggleStore.commitShow && !localData.isLandscape && !localData.isFullscreen ? 'cover' : 'contain'"-->
            <!--                v-show="localData.posterImgVisible"-->
            <!--                :src="localData.poster"-->
            <!--                style="position: absolute; left: 0; top: 0; width: 100%; height: 100%"-->
            <!--                alt=""-->
            <!--            />-->
            <!--            <SvgIcon v-show="!localData.isPlaying" name="play" color="rgba(255, 255, 255, 0.3)" width="48rem" height="48rem" class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-10"></SvgIcon>-->
        </div>
        <!--        进度条-->
        <div
            v-if="localData.showVideo"
            class="slider-process cursor-pointer absolute left-0 w-[375rem] z-30"
            :class="{
                'is-slider-drag': moveType == 'slider',
            }"
        >
            <div class="text-center text-white mb-20rem mt-20rem text-20rem" v-show="moveType == 'slider'">{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</div>
            <!-- 进度条容器 -->
            <div
                id="control"
                ref="controlRef"
                :class="['control', { 'control-move': moveType == 'slider' }, { 'control-stop': !localData.isPlaying }]"
                @touchstart.stop="sliderStart"
                @mousedown.stop="sliderStart"
                @touchmove.stop="controlMove"
                @mousemove.stop="controlMove"
                @touchend.stop="controlEnd"
                @mouseup.stop="controlEnd"
                @click.stop="setProgress"
                @mousemove.prevent.stop="() => {}"
            >
                <!-- 进度条本条 -->
                <div class="progress" :style="{ width: progressWidth + 'px' }" />
                <!-- 滑块 -->
                <div
                    v-if="true"
                    class="slider_circle"
                    :style="{ left: progressWidth + 'px', opacity: localData.isLoading ? 0 : 1 }"
                    @touchstart.stop="sliderStart"
                    @mousedown.stop="sliderStart"
                    @touchmove.stop="controlMove"
                    @mousemove.stop="controlMove"
                    @touchend.stop="controlEnd"
                    @mouseup.stop="controlEnd"
                    ref="barRef"
                />
                <div class="progress-wait-bar" v-if="localData.isLoading"></div>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.slider-process {
    flex: 1;
    bottom: 1px;
    z-index: 20;
    .control {
        width: 100%;
        background-color: #4f4f4f;
        position: relative;
        background-clip: content-box;
        z-index: 999;
        border-radius: 6px;
        .slider_circle {
            position: absolute;
            left: 0;
            top: 50%;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            border: 1px;
            background: white;
            cursor: pointer;
            transform: translateY(-50%);
        }
        .progress {
            background-color: white;
            height: 2px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            overflow: hidden;
        }
    }
    .vjs-progress-control {
        display: none !important;
    }
    .control-stop {
        .slider_circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }
        .progress {
            height: 4px;
            background-color: var(--second-text-color);
        }
    }

    .control-move {
        .slider_circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }
        .progress {
            height: 10px;
            background-color: var(--second-text-color);
        }
    }
}
.is-slider-drag {
    background: rgba(0, 0, 0, 0.7);
}
.video-js {
    background-color: transparent;
}

.video__portrait-full-screen {
    :deep(.vjs-tech) {
        object-fit: cover;
        width: 375rem !important;
        left: calc((100% - 375rem) / 2);
    }
}
.video-js {
    :deep(.vjs-touch-overlay) {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        left: 0;
        top: 0;
    }
    :deep(&.vjs-has-started .vjs-touch-overlay) {
        pointer-events: auto;
    }
    :deep(.vjs-touch-seek-note) {
        display: none;
        margin: auto;
        padding: 10px 15px;
        border-radius: 2px;
        background: rgba(#000, 0.3);
        color: #fff;
        font-size: 16px;
    }
    :deep(.vjs-touch-overlay.vjs-touch-active.vjs-touch-moving .vjs-touch-seek-note) {
        display: block;
    }
}
</style>

<style lang="scss">
.video-not-fullscreen .video-js,
.is-ios.video-js {
    .vjs-control-bar {
        display: none !important;
    }
}

.vjs-fullscreen {
    .no__rem_vjs-fanhui {
        position: fixed;
        top: env(safe-area-inset-top);
        left: env(safe-area-inset-left);
        font-size: 16px;
        height: 40px;
        width: 40px;
    }
    .vjs-quality-selector-one {
        background: transparent;
        outline: none;
        option {
            background: #000;
        }
        .selected {
            background: blue;
        }
    }
    .vjs-quality-selector-two {
        position: fixed;
        top: env(safe-area-inset-top);
        right: env(safe-area-inset-right);
        font-size: 16px;
        height: 40px;
    }
}

.is-land-scape {
    .vjs-fullscreen {
        .no__rem_vjs-fanhui {
            top: 10px;
            left: 10px;
        }
    }
    .vjs-quality-selector-two {
        top: 10px;
        right: 10px;
    }
}

.vjs-big-play-button {
    display: none !important;
}
.vjs-picture-in-picture-control {
    display: none !important;
}
</style>

<style lang="scss" scoped>
.video-container {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #000;
    :deep(.vjs-modal-dialog),
    :deep(.vjs-hidden) {
        display: none;
    }
}
video,
.video-js {
    transition: all 0.3s !important;
}
.video-is-commit {
    video {
        //height: 200px !important;
        transition: height 0.3s;
    }
}
// 此时，宽度长一些。高度短一些。高度小于元素高度，宽度和高度中长的那条边跟容器大小一致，宽大于高，以宽度为准
.cover {
    //background: blue;
    :deep(video) {
        //object-fit: contain;

        //object-position: left top !important;
        object-fit: cover;
    }
}
// 此时，宽度短一些。高度长一些，高度大于元素宽度，长度和高度中短的那条边跟容器大小一。
.contain {
    //background: yellow;
    :deep(video) {
        object-fit: contain;
    }
}
.vjs-modal-dialog {
    display: none; /* 初始隐藏 */
}
</style>

<style>
/* 进度条的样式 */
.progress-wait-bar {
    position: absolute;
    top: 0;
    left: 50%; /* 初始位置在中心 */
    width: 0;
    height: 100%;
    background: linear-gradient(90deg, #fff, #fff, #fff); /* 从两端到中心的渐变效果 4f4f4f */
    animation: expandFromCenter 2s infinite; /* 动画效果 */
}

/* 动画效果 */
@keyframes expandFromCenter {
    0% {
        left: 50%; /* 起始位置 */
        width: 0;
    }
    50% {
        left: 0; /* 动画到达两端 */
        width: 100%;
    }
    100% {
        left: 0; /* 度条继续扩展，保持两端展开  如果需要让他从两端再往中间收，则设置为 50%，宽度为0%*/
        width: 100%;
        opacity: 0;
    }
}
/* 动画效果 从左到右 */
@keyframes expandLeftToRight {
    0% {
        left: 0; /* 起始位置 */
        width: 0;
    }
    50% {
        left: 0; /* 动画到达右侧 */
        width: 100%;
    }
    100% {
        left: 0; /* 动画到达右侧 */
        width: 100%;
    }
}
</style>
