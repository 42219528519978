import { ILogData, TLogCategory } from '@/api/user/type'
import axios from 'axios'
import { ref } from 'vue'
import { toRawType } from '@/utils/index.ts'
import { UAParser } from 'ua-parser-js'
// 定义日志类别映射，label 与 field 都会与 key 保持一致
export const logCategoryMapping: Record<
    TLogCategory,
    {
        label: string // 用于前端显示的标签
        field: TLogCategory // 传递给后台的字段，和 key 保持一致
    }
> = {
    SmsSend: { label: '短信发送', field: 'SmsSend' },
    VideoView: { label: '视频观看', field: 'VideoView' },
    VideoLoad: { label: '视频加载', field: 'VideoLoad' },
    VideoSkipped: { label: '视频跳过', field: 'VideoSkipped' },
    VideoRelease: { label: '视频发布', field: 'VideoRelease' },
    VideoBuy: { label: '视频购买', field: 'VideoBuy' },
    BuyVip: { label: '购买VIP', field: 'BuyVip' },
    Comment: { label: '评论', field: 'Comment' },
    DoLike: { label: '点赞', field: 'DoLike' },
    Collect: { label: '收藏', field: 'Collect' },
    CommentLike: { label: '评论点赞', field: 'CommentLike' },
    SystemNotice: { label: '系统通知', field: 'SystemNotice' },
    MessageSend: { label: '消息发送', field: 'MessageSend' },
    Follow: { label: '关注', field: 'Follow' },
    UpdateInfo: { label: '更新信息', field: 'UpdateInfo' },
    Recharge: { label: '充值', field: 'Recharge' },
    IndexNoData: { label: '首页无数据', field: 'IndexNoData' },
}

// 使用映射字段获取 label 和 field
export const getLogInfo = (category: TLogCategory) => {
    const logInfo = logCategoryMapping[category]
    if (logInfo) {
        return {
            label: logInfo.label, // 前端显示标签
            field: logInfo.field, // 传递给后台的字段（与key保持一致）
        }
    }
    return { label: '未知类别', field: category } // 默认值
}
const baseUrl = ref('')

const setLocalStorageLogsUrl = () => {
    try {
        const userConfigStr = localStorage.getItem('userConfig')
        const UserConfig = JSON.parse(userConfigStr || '{}')
        if (UserConfig?.USER_CONFIG?.logsUrl) {
            baseUrl.value = UserConfig.USER_CONFIG.logsUrl
        } else {
            console.warn('未配置日志上报地址')
        }
    } catch (err) {
        console.error('日志上报地址获取失败', err)
    }
}
const logUserId = ref('')
const setLogUserId = () => {
    try {
        const userStr = localStorage.getItem('user')
        const userInfo = JSON.parse(userStr || '{}')
        if (userInfo?.MY_USER_INFO?.id) {
            logUserId.value = userInfo.MY_USER_INFO.id
        }
    } catch (err) {
        console.error('上传日志时，获取用户id发生错误', err)
    }
}
interface IOS {
    name: string
    version: string
}

interface IBrowser {
    name: string
    version: string
}
// 在pc端这个是没有的
interface IDevice {
    model: string | undefined
    type: string | undefined
    vendor: string | undefined
}
function getClientInfo() {
    const parser = new UAParser()
    const result = parser.getResult()
    return {
        os: result.os as IOS,
        browser: result.browser as IBrowser,
        device: result.device as IDevice,
    }
}
const projectVersion = ref('')
const clientInfo = getClientInfo()
//如果日志上报失败，一般可能是网络问题， 当网络良好的时候，重新上报。

export const globReportLog = (data: ILogData | TLogCategory, type?: TLogCategory, reqConfig?: any) => {
    if (!baseUrl.value) {
        setLocalStorageLogsUrl()
    }
    if (!baseUrl.value) return

    setLogUserId()

    try {
        import.meta.env.VITE_APP_VERSION && (projectVersion.value = import.meta.env.VITE_APP_VERSION)
    } catch (error) {
        console.error('获取项目版本号失败', error)
    }

    const url = baseUrl.value + '/syslog/front/reportException'
    const { os, browser, device } = clientInfo

    // 一些桌面设备，device里的数据都是缺失的，
    //{ type: '', vendor: '', model: '' } （类型、供应商、型号)
    const cDeviceType = device.type || ''
    const cDeviceVendor = device.vendor || ''
    const cDeviceModel = device.model || ''
    const rDeviceModel = `${cDeviceVendor} ${cDeviceType} ${cDeviceModel}`
    const sysVersion = `${os.name} ${os.version} ${browser.name} ${browser.version}`
    const newData = {
        category: 'SmsSend',
        userId: Number(logUserId.value),
        platform: 'h5',
        version: projectVersion.value,
        sysVersion: sysVersion, //客户端系统版本号
        deviceModel: rDeviceModel, //客户端设备型号
        reportAt: Date.now(),
    }
    if (data && typeof data === 'string') {
        Object.assign(newData, { category: data })
    }
    if (data && toRawType(data) === 'object') {
        Object.assign(newData, data)
    }
    if (type && toRawType(type) === 'string') {
        Object.assign(newData, { category: type })
    }
    //@todo 这里拿到是请求的一些参数。还不确定要不要把请求参数上报。
    const newReqConfig = {}
    if (reqConfig) {
        Object.assign(newReqConfig, reqConfig)
    }

    const requestConfig = {
        ...newData,
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error。
    sendGlobReportLog(url, requestConfig)
}
// 本地存储键
const STORAGE_KEY = 'failedLogs'

type IRLogData = ILogData & {
    reportAt: number // 异常发生时间
}
// 函数：发送日志
const sendGlobReportLog = (url: string, data: ILogData) => {
    axios
        .post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(() => {
            // 成功上报后，从本地存储中移除该条记录
            removeFailedLog(data?.reportAt || Date.now())
            retryFailedLogsIntervalCount = 0
        })
        .catch((error) => {
            console.error('Error sending log:', error)
            // 如果发送失败，将 url 和 data 一起记录在本地存储
            saveFailedLog(url, data)
            retryFailedLogsHandler()
        })
}

// 将失败的日志数据存储到 localStorage
const saveFailedLog = (url: string, data: any) => {
    const failedLogs = getFailedLogs()

    const isExist = failedLogs.some((log: { url: string; data: ILogData }) => log.url === url && log.data.reportAt === data.reportAt)

    if (isExist) {
        return
    }
    // 将新的失败日志加入到数组中
    failedLogs.push({ url, data })

    // 更新本地存储
    updateLocalStorage(failedLogs)
}

// 更新 localStorage 的辅助函数
const updateLocalStorage = (logs: { url: string; data: ILogData }[]) => {
    try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(logs))
    } catch (e) {
        console.error('Failed to update localStorage:', e)
    }
}

// 从 localStorage 获取失败的日志
const getFailedLogs = () => {
    const logs = localStorage.getItem(STORAGE_KEY)
    return logs ? JSON.parse(logs) : []
}

// 从 localStorage 中移除指定的失败日志
const removeFailedLog = (reportAt: number) => {
    const failedLogs = getFailedLogs()
    // 过滤掉已成功上报的日志
    const updatedLogs = failedLogs.filter((log: { url: string; data: ILogData }) => log.data.reportAt !== reportAt)
    // 更新本地存储
    updateLocalStorage(updatedLogs)
}

let retryFailedLogsInterval: NodeJS.Timeout | null = null
//  每5分钟检查一次网络状态并尝试重新上报，如果多次失败，那就先不上报了。等待下次重新刷新再来上报。
const retryFailedLogsIntervalTime = 1000 * 60 * 5
let retryFailedLogsIntervalCount = 0
// 定时器，定期检查网络状况并重试发送失败的日志
const retryFailedLogsHandler = () => {
    clearRetryFailedLogsInterval()
    if (retryFailedLogsIntervalCount > 3) {
        return
    }
    retryFailedLogsInterval = setInterval(() => {
        if (navigator.onLine) {
            // 检查是否有网络连接
            // 仅当网络良好时才重新尝试上报失败的日志
            const failedLogs = getFailedLogs()
            if (!failedLogs.length) {
                clearRetryFailedLogsInterval()
            }
            retryFailedLogsIntervalCount++
            failedLogs.forEach((logs: { url: string; data: IRLogData }) => {
                sendGlobReportLog(logs.url, logs.data) // 使用存储的 url 和 data 进行重试
            })
        } else {
            console.error('Network is offline, retrying later...')
        }
    }, retryFailedLogsIntervalTime)
}
const clearRetryFailedLogsInterval = () => {
    if (retryFailedLogsInterval) {
        clearInterval(retryFailedLogsInterval)
        retryFailedLogsInterval = null
    }
}
retryFailedLogsHandler()
// window.addEventListener('online', () => {
//     retryFailedLogsHandler()
// })
// window.addEventListener('offline', () => {
//     clearRetryFailedLogsInterval()
// })
