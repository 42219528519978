/**
 *
 */

import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useSystemStore, useUserStore, useUserConfig } from '@/store'
import Aws from '@/aws/Aws.ts'

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
    scrollBehavior(_to, _from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})
function requiresAuthFun(path: string) {
    const authPass = ['/login', '/home', '/search', '/auth-code', '/user/:id', '/discover', '/privacy', '/agreement', '/search-video', '/work']
    // 检查静态路径是否在 authPass 中
    if (authPass.includes(path)) {
        return false // 不需要登录验证
    }

    // 对于动态路径，使用正则表达式进行匹配
    const dynamicPathRegex = /^\/user\/\d+$/ // 匹配形如 user/10 的路径
    if (dynamicPathRegex.test(path)) {
        return false // 不需要登录验证
    }

    return true // 默认需要登录验证
}
router.beforeEach((to, from) => {
    const systemStore = useSystemStore()
    systemStore.SET_INCLUDE_ROUTE_NAMES({ meta: to.meta, name: to.name })

    //if
    //footer下面的5个按钮缓存 g再加一个work页面
    // @todo 不应该这样设置，应该在路由的meta上设置 keepAlive: true
    const keepAlive = ['/', '/home', '/me', '/message', '/discover']
    const handControlKeepAlive = ['search-video', 'tag-video']
    if (keepAlive.indexOf(to.path) !== -1) {
        systemStore.excludeNames = [...new Set(systemStore.excludeNames.concat(handControlKeepAlive))]
    } else {
        systemStore.excludeNames = systemStore.excludeNames.filter((item) => !handControlKeepAlive.includes(item))
    }
    if (keepAlive.indexOf(from.path) !== -1 && keepAlive.indexOf(to.path) !== -1) {
        return true
    }

    //@Todo 这里竟然是利用路由在路由数组中的位置 来进行判断是不是需要做处理
    // to path 在路由中的位置 。
    const toDepth = routes.findIndex((v) => {
        // 判断是否有/user/:id这种路由
        const pos = v.path.indexOf(':')
        if (pos !== -1) {
            return v.path.substring(0, pos) === to.path.substring(0, pos)
        }

        return v.path === to.path
    })
    // form path 在路由中的位置
    const fromDepth = routes.findIndex((v) => {
        const pos = v.path.indexOf(':')
        if (pos !== -1) {
            return v.path.substring(0, pos) === from.path.substring(0, pos)
        }

        return v.path === from.path
    })

    // @todo 这是一段神仙逻辑。！！！！
    // 即将进入的页面路由index 更大，也就是即将进入的页面的路由index更靠后。相当于去到了详情页面。
    // 这里做了个什么事，好像是即将进入的页面的路由从 不缓存数据中 删除。也就是需要缓存。
    if (toDepth > fromDepth) {
        if (to.matched && to.matched.length) {
            const toComponentName = to.matched[0].components?.default.name
            if (toComponentName) {
                systemStore.updateExcludeNames({
                    type: 'remove',
                    value: toComponentName,
                })
            }
        }
    } else {
        // else 即将进入的页面路由index更小，也就是即将进入的页面路由index更靠前。相当于从详情页面返回 到了前一个页面。
        // 返回的时候，把form页面添加到不缓存的数据中。
        if (from.matched && from.matched.length) {
            const fromComponentName = from.matched[0].components?.default.name

            if (fromComponentName) {
                if (handControlKeepAlive.includes(fromComponentName)) {
                    return
                }
                systemStore.updateExcludeNames({
                    type: 'add',
                    value: fromComponentName,
                })
            }
        }
    }
    return true
})

router.beforeEach((to, _from, next) => {
    const userStore = useUserStore()
    const { GET_USER_CONFIG } = useUserConfig()
    // 检查用户是否登录
    const token = userStore.token

    if (!userStore.MY_USER_INFO.id && !requiresAuthFun(to.path)) {
        userStore.touristLogin().then((res) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            GET_USER_CONFIG({ userId: res.id }).then((_) => {})
            next()
        })
        return
    }

    // 要访问的路由是否需要登录
    if (requiresAuthFun(to.path) && !token) {
        // 如果需要登录而用户未登录，则重定向到登录页面
        next('/login')
    } else {
        if (userStore.MY_USER_INFO.userArn && !Aws.socket) {
            Aws.connectWebSocket(userStore.MY_USER_INFO.userArn)
        }
        // 其他情况允许访问
        next()
    }
})

// export const resetRouter = () => {
//     const newRouter = createRouter({
//         history: createWebHistory(),
//         routes,
//         scrollBehavior(_to, _from, savedPosition) {
//             if (savedPosition) {
//                 return savedPosition
//             } else {
//                 return { top: 0 }
//             }
//         },
//     })
//     router.mathcer = newRouter.mathcer
// }
router.onError((error) => {
    console.error('error.message: ', error.message)
})
export default router
