export default {
    'en-US': {
        // 通用
        common: {
            del: 'Del',
            confirm: 'Confirm',
            cancel: 'Cancel',
            back: 'Back',
            message: 'Message',
            upload: 'Upload',
            share: 'Share',
            share_desc: '',
            save: 'Save',
            more: 'More',
            noMore: 'No More',
            loading: 'Loading',
            loadFail: 'Load fail',
            loadFailTip: 'Click to reload',
            loadSuccess: 'Load success',
            loadSuccessTip: 'Loaded {num} items',
            down_refresh: 'Pull down to refresh',
            release_refresh: 'Release to refresh',
            search_placeholder: 'Please enter keyword',
            search: 'Search',
            CNY_symbol: '¥',
            USD_symbol: '$',
            unmute: 'Unmute', // 取消静音
            pulling_text: 'Drop down to refresh', //下拉过程提示文案
            loosing_text: 'Release to refresh', //释放过程提示文案
            loading_text: 'loading…', //加载过程提示文案
            success_text: 'Refresh success', //刷新成功提示文
            expand_text: 'Expand', // 展开操作的文案
            collapse_text: 'Collapse', // 收起操作的文案
        },
        request: {
            error: 'Request error',
            timeout: 'Request timeout',
            e401: 'token expiration ',
            e403: 'No permission',
            e404: 'Request address error',
            e500: 'Server error',
        },
        share: {
            name: 'Share',
            title: 'Share with friends now',
            copy_link: 'Copy link',
            copy_link_success: 'Copy link success',
            copy_link_fail: 'Copy link fail',
        },
        follow: {
            follow: 'Follow',
            followed: 'Followed',
            mutual: 'Mutual',
            following: 'Following',
            followers: 'Followers',
            likes: 'Likes',
            suggested: 'Suggested',
            unfollow_tip_title: 'tips',
            unfollow_tip_content: 'Are you sure you want to unfollow this user?',
            follow_success_toast_tip: 'follow on success',
            unfollow_success_toast_tip: 'unfollow on success',
            share_videos_tip: 'Share your latest funny videos',
        },
        comment: {
            reply: 'Reply', // 回复
            expand_text: 'Expand', // 展开
            more: 'more', // 更多
            comment_success_tip: 'Comment successful.',
            collapse_text: 'Collapse', // 收起
            expand_more: 'Expand More',
            expand_count_reply: 'Expand {count} replies', // 展开 4 条回复
            del_comment_tip_title: 'tips',
            del_comment_tip_content: 'Are you sure you want to delete this comment?',
        },

        upload: {
            title: 'Upload',
            desc: 'Share your latest funny videos',
            upload_success_toast_tip: 'upload on success',
            upload_fail_toast_tip: 'upload on fail',
        },
        tip: {
            agreement: 'Please read and agree to the User Agreement and User Privacy.',
        },
        // 视频相关的，不方便分类的页面，或者多个页面共用了一个视频组件
        video: {
            loading_desc: 'Video loading……',
            buffering_desc: 'Video Buffering...',
            error_desc: 'The video failed to load. Please try again later',
            transcoding: 'Transcoding...',
            transcoding_pop_desc: 'The video is being transcoded. \n Please wait a moment',
            transcoding_error_desc: 'Video transcoding failed',
            // 视频购买引导 vip 视频或者金币视频，不能完整播放时，会有个弹窗。
            video_purchase_guide: {
                title: '',
                welcome: 'Thank you for subscribing.',
                vip_desc1: 'Free viewing with monthly',
                vip_desc2: 'coin subscription',
                gold_desc1: 'No need to subscribe, Pay',
                gold_desc2: 'coins to unlock videos',
                free_preview: 'FreePreview {time} sec',
                try_now: 'Try Now',
                vip_btn_text: 'Subscribe Now',
                gold_btn_text: 'Unlock Now',
            },
            gold_purchase_video: {
                btn_text: '{gold} Purchase',
                confirm_title: 'be short of gold coins',
                confirm_content: 'Whether to go to top up',
                confirm_success_toast_tip: 'del on success',
                gold_pop_title: 'Login Unlock',
                gold_pop_content: 'Viewed {count} items, login to unlock more',
                gold_pop_btn_text: 'Recharge Now',
            },
        },
        login: {
            name: 'Log in to Slive',
            desc: 'Manage your account, chenck notifications, comment on videos, and more.',
            phone_placeholder: 'phone number',
            phone_err_message: 'Enter a valid phone rumber',
            profile: 'Please read and agree to the',
            profile1: '《User Agreement》',
            profile2: 'and',
            profile3: '《User Privacy》',
            submit_text: 'Send code',
        },
        code: {
            title: 'Enter 4-digit code',
            desc: 'your code was sent to ',
            resend_code: 'Resend code',
        },
        home: {
            comment_placeholder: 'Add comment...',
            go_to_login: 'Login',
            top_msg: 'Viewed {time} items, register to unlock more!',
            top_msg1: 'Watch videos to earn coins.',
        },
        search: {
            navTabs: ['Top', 'Videos', 'Users', 'Hashtags'],
            video_title: 'Video',
            user_title: 'User List',
            hashtags_posts: 'posts',
        },
        discover: {
            title: 'Discover',
            popular_models: 'Popular Models',
            more: 'More',
            hashtags: 'Hashtags',
            shooting_games: 'Shooting games',
        },
        profile: {
            title: 'Profile',
            edit_profile: 'Edit Profile',
            edit_profile_desc: 'Edit your profile',
            edit_profile_desc1: 'Edit your profile',
            edit_profile_desc2: 'Edit your profile',
            edit_profile_desc3: 'Edit your profile',
            edit_profile_desc4: 'Edit your profile',
            edit_profile_desc5: 'Edit your profile',
        },
        creation: {
            desc_placeholder: 'Add description...',
            edit_cover: 'Edit Cover',
            edit_cover_error: 'error',
            submit: 'Post',
            submit_success_toast_tip: 'post on success',
            label_pop: {
                title: 'Label',
                submit: 'Finish',
            },
            videoTypePop: {
                title: 'Video Type',
                public: 'public',
                public_desc: 'Everyone (Default)',
                gold: 'coin',
                gold_desc: 'Only gold can see',
                vip: 'vip',
                vip_desc: 'Only VIP can see',
                private: 'private',
                private_desc: 'Only you can see',
            },
            gold_pop: {
                title: 'coin selection',
                submit: 'saving',
            },
        },
        wallet: {
            title: `{name}'s balance`,
            wallet_desc1: 'Coins', // 用于钱包页面(/wallet) 实在不知道用什么词来描述
            wallet_desc2: 'Get Coins', // 用于钱包页面(/wallet) 实在不知道用什么词来描述
            RechargeCoins: 'Recharge Coins', // 用于钱包页面(/wallet)及充值记录页面(/wallet/recharge-record)
            Giveaway: 'Giveaway', // 用于钱包页面(/wallet)及充值赠送页面(/wallet/giveaway)
            Services: 'Services', // 用于钱包页面(/wallet)
            TransactionHistory: 'Transaction History', // 用于钱包页面(/wallet)
            HelpAndFeedback: 'Help & feedback', // 用于钱包页面(/wallet) 及帮助页面(/wallet/help)
            // start 充值记录
            recharge_coins_item_desc: 'Estimated total rewards', // 充值记录中用到
            current_payout_desc: 'Current payout',
            next_payout_desc: 'Next payout',
            // end 充值记录
            // start 交易记录
            empty_transaction_history: 'No transactions yet',
            empty_transaction_history_desc: 'Keep track of your purchases, refunds, and \n exchanges here',
            // end 交易记录
            // start 充值
            buyGolds: {
                title: 'Buy Gold',
                gold_total_title: 'coin balance',
                submit: 'Get',
                desc: 'By continuing, you agree to the Virtual ltems Policy. You will also lose the extra Coins if you withdraw from this purchase.',
            },
        },
        message: {
            title: 'Inbox',
            on_top: 'Top', // 置顶
            un_top: 'unTop',
            new_followers: 'New followers',
            new_followers_empty: 'When someone new follows you, you’ll see it her.',
            followers_notifications_title: 'New followers',
            followers_notifications_desc: 'Check your new followers here..',
            sending: 'Sending',
            delivered: 'Delivered',
            sending_failed: 'Sending failed',
            allActivity: {
                title: 'All activity',
                desc: 'View all activity here…',
                menu: ['All activity', 'Likes and Favorites', 'Comments', 'Mentions and tags'],
            },
            chatSetting: {
                title: 'Chat Settings',
                masking: 'Block',
                put_top: 'Pin to top',
                masking_pop_title: 'Block',
                masking_pop_message: 'Are you sure you want to block this user?',
                block_confirm: 'Are you sure you want to block this user?',
                block_success: 'Block success',
                unblock: 'Unblock',
                unblock_confirm: 'Are you sure you want to unblock this user?',
                unblock_success: 'Unblock success',
                delete: 'Delete',
                delete_confirm: 'Are you sure you want to delete this chat?',
                delete_success: 'Delete success',
                report: 'Report',
                report_confirm: 'Are you sure you want to report this user?',
                report_success: 'Report',
            },
        },
        me: {
            work_vip_filter: {
                vip: 'VIP',
                gold: 'Gold',
            },
            work_public_filter: {
                latest: 'Latest',
                popular: 'Popular',
            },
            upload: 'Upload',
            desc: 'Share your latest funny videos',
            likesDialogMsg: `"{name}"Received a total of {num} likes"`,
            user_settings: {
                title: 'Settings and privacy',
                account: 'Account',
                privacy: 'Privacy',
                security_and_permissions: 'Security & permissions',
                login: 'Login',
                logout: 'Log out',
            },
            user_panel: {
                edit_label: 'Edit',
                del_label: 'Del',
                confirm_title: 'del',
                confirm_content: 'Whether to delete the work',
                confirm_success_toast_tip: 'del on success',
            },
            edit_profile: {
                title: 'Edit Profile',
                avatar_desc: 'Change your avatar',
                about_you: 'About you',
                phone: 'Phone',
                phone_placeholder: 'Enter your phone number',
                name: 'Name',
                edit_nickname: 'Edit Nickname',
                edit_nickname_success: 'Edit Nickname \n Success',
                name_placeholder: 'Enter your name',
                introduction: 'Introduction',
                edit_introduction: 'Edit Introduction',
                edit_introduction_success: 'Edit Introduction \n Success',
                introduction_placeholder: 'Enter your introduction',
            },
            vip: {
                title: 'SUBSCRIBE VIP',
                desc: 'VIP users can get more coins',
                level_item_title: `{duration} Month`,
                submit_text: 'Activate',
                submit_success: 'Activated',
                submit_success_toast_tip: 'Activate \n Success',
            },
            circle: {
                title: 'runlry',
                desc: 'Share your latest funny videos',
            },
            balance: {
                title: 'Balance',
                balance: 'Balance',
            },
            settings_and_privacy: {
                title: 'Settings & Privacy',
                account: 'Account',
                account_desc: 'Change your account settings',
                notification: 'Notification',
                notification_desc: 'Change your notification settings',
                privacy: 'Privacy',
                privacy_desc: 'Change your privacy settings',
                help: 'Help',
                help_desc: 'Get help with your account',
                about: 'About',
                about_desc: 'About us',
                logout: 'Logout',
                logout_desc: 'Logout from your account',
                logout_confirm: 'Are you sure you want to logout?',
                logout_confirm_text: 'Logout',
            },
        },
    },
}
