import DefaultSigV4 from './sigv4/DefaultSigV4.ts'

const prepareWebSocketUrl = async (endpointUrl: string, userArn: string, chimeClient: any) => {
    const sigV4 = new DefaultSigV4({
        config: chimeClient,
    })
    const queryParams = new Map<string, string[]>()
    queryParams.set('userArn', [userArn])
    return await sigV4.signURL('GET', 'wss', 'chime', endpointUrl, '/connect', '', queryParams)
}
export default prepareWebSocketUrl
