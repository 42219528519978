import { createI18n } from 'vue-i18n'
import enUS from './en-US.ts'
// import zhCN from './zh-CN.ts'
import hiIN from './hi-IN.ts'
// ISO 639-1
const messages = {
    ...enUS,
    // ...zhCN,
    ...hiIN,
}

// 选择默认语言
const defaultLocale = 'en-US'

// 获取用户的语言设置
const userLocale = navigator.language || defaultLocale
// 确定支持的语言
const supportedLocales = ['en-US', 'hi-IN']

const finalLocale = supportedLocales.includes(userLocale) ? userLocale : defaultLocale

const i18n = createI18n({
    legacy: false,
    locale: finalLocale, // 默认使用英文 实际上，这里可以直接用 userLocale， 但是控制老是会打印警告日志，怎么设置都没用，所以，只能设置为 finalLocale
    fallbackLocale: defaultLocale, // 如果法语中没有对应的翻译，则使用英语
    messages,
    silent: true, // 全局静默警告
    silentTranslationWarn: true, // 忽略翻译警告
    warnHtmlMessage: false, // 关闭 HTML 消息警告
    warnDuplicatedMessage: false, // 关闭重复消息警告
    warnHandler: () => {},
})

export default i18n
